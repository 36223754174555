import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineAudio, AiOutlineSend } from 'react-icons/ai';
import { FaUser, FaRobot, FaInfoCircle } from 'react-icons/fa'; 

import axios from 'axios';
import Message from '../HelperComponents/Message';


const AskAIModal = ({ isOpen, onClose, currentNote, noteType }) => {
      const [userInput, setUserInput] = useState("");
      const modalRef = useRef();
      const [isClosing, setIsClosing] = useState(false);

      const handleClose = () => {
        setIsClosing(true);
        onClose(); // Call the onClose function passed as a prop
        setTimeout(()=>{
          setIsClosing(false);
        }, 300)
      };
      
      const [conversationData, setConversationData] = useState({
        subjective: [
          { content: "Hello, how can I assist you with your subjective notes?", role: "assistant" }
        ],
        objective: [
          { content: "Hello, how can I help you with your objective notes?", role: "assistant" }
        ],
        assessment_and_plan: [
          { content: "Hi there, how can I assist you with your assessment and plan?", role: "assistant" }
        ],
        encounter: [
          { content: "Hello, how can I help you with your encounter notes?", role: "assistant" }
        ],
        medications: [
          { content: "Hi, how can I assist you with your medication notes?", role: "assistant" }
        ],
        labs: [
          { content: "Hello, how can I help you with your lab notes?", role: "assistant" }
        ],
        referrals: [
          { content: "Hi there, how can I assist you with your referral notes?", role: "assistant" }
        ],
      });

      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          handleClose();
        }
      };


      useEffect(() => {
        if (isOpen[noteType]) {
          // Reset closing state and attach the event listener when the modal is open
          setIsClosing(false);
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          // Remove the event listener when the modal is closed
          document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener on component unmount
        return () => document.removeEventListener('mousedown', handleClickOutside);
      }, [isOpen, noteType]);

      const localMessages = conversationData[noteType];

      const [loadingMessage, setLoadingMessage] = useState(false)
      const messagesEndRef = useRef(null);

      const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      };

      useEffect(() => {
        scrollToBottom();
      }, [localMessages, loadingMessage]); // This will trigger scroll when messages or loadingMessage changes

      if (isOpen && !isOpen[noteType] && !isClosing) return null;

      const askQuestion = async (newMessage) => {
        if (!newMessage) return;
        setLoadingMessage(true);
      
        // Add the new user message in the desired format
        const newUserMessage = { role: "user", content: newMessage };
        setConversationData(prevData => ({
          ...prevData,
          [noteType]: [...prevData[noteType], newUserMessage],
        }));
      
        // Use the newUserMessage directly when preparing the last four messages
        let lastFourMessages = [...conversationData[noteType], newUserMessage].slice(-4);
        const token = sessionStorage.getItem('token'); // Retrieve the token from session storage

        console.log("CURRENT NOTE: ", currentNote)
      
        try {
          const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/ask-ai", {messages: lastFourMessages, currentNote, noteType}, {
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` // Add this line
          }
      });
          // Format the AI response and add it to the conversation
          const aiResponse = { role: "assistant", content: response.data.response };
          setConversationData(prevData => ({
            ...prevData,
            [noteType]: [...prevData[noteType], aiResponse],
          }));
        } catch (error) {
          console.error('Error while fetching AI response:', error);
          // Handle errors or notify the user as necessary
        } finally {
          setLoadingMessage(false);
        }
      };
      
      const handleKeyDown = (event) => {
        // Check if the Enter key is pressed without the Shift key
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault(); // Prevents the default action (inserting a newline)
          handleSendClick();
        }
      };
      
      const handleSendClick = () => {
        askQuestion(userInput);
        setUserInput(""); // Clear the input after sending
      };


  return (
    <div className={`fixed inset-0 z-50 bg-black bg-opacity-30 backdrop-blur-sm flex justify-end ${!isOpen && isClosing ? 'hidden' : 'visible'}`}>
      <div 
        className={`transform transition-transform duration-300 ${isOpen && !isClosing ? 'translate-x-0' : 'translate-x-full'} relative bg-white p-3 rounded-lg w-full sm:w-1/3 shadow-lg`} 
        ref={modalRef} 
        style={{ top: '8vh' }}>
        <div className='w-full flex justify-between items-center'>
              <h3 className='text-blue-950 font-semibold text-lg'>ChartMD Copilot</h3>
              <button className="text-3xl p-3 text-gray-500 hover:text-gray-700" onClick={handleClose}>
                <AiOutlineClose />
              </button> 
        </div>
        <div className='w-full my-2 flex items-center'>
          <FaInfoCircle className='mr-2 text-blue-500' /> <p className='text-gray-500 text-sm'>Talk to your CoPilot about your <span className='text-blue-500'>{noteType === "assessment_and_plan" ? "assessment and plan" : noteType}</span> notes.</p>
        </div>
        <div className="h-[65%] mb-4 bg-gray-100 rounded-xl shadow-sm">
          <div className="overflow-auto h-full p-3">
          {conversationData[noteType] && conversationData[noteType].length > 0 ? (
              conversationData[noteType].map((message, index) => (
                <Message key={index} message={message} />
              ))
            ) : (
              <div>No messages available.</div>
            )}
            {loadingMessage && (
              <>
                <div className='flex items-center justify-start mt-4'>
                  <div className='flex items-center'>
                    <FaRobot className='text-2xl animate-typing' />
                    <span className='ml-2 dots'>...</span>
                  </div>
                </div>
              </>
            )}
            <div ref={messagesEndRef} />  {/* This div is the target to scroll to */}
          </div>
        </div>

        <div className="relative">
          <textarea
            className="border rounded-lg w-full p-3 text-base shadow-inner bg-gray-100 text-gray-700 focus:outline-none focus:border-blue-500 resize-none"
            placeholder="Type your message here..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyDown}
            rows="4"
          ></textarea>
          <button
            className="absolute right-3 bottom-3 flex items-center bg-blue-500 hover:bg-blue-700 text-white px-2 py-2 rounded-lg text-lg"
            onClick={handleSendClick}
          >
            <AiOutlineSend className="text-lg" />
          </button>
        </div>

      </div>
    </div>
  );
};

export default AskAIModal;
