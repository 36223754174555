import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

import LaptopImage from '../Images/hero5.png'; // Replace with your actual image path
import TranscriptionIcon from '../Images/transcription1.svg';
import AnalyzeIcon from '../Images/analyze1.svg';
import Notes from '../Images/Notes1.svg';
import userLaptop from '../Images/homepage.png'
import logo from '../Images/logo2.png';
import { AiOutlineCheck } from 'react-icons/ai';
import { FaBrain, FaCheckCircle, FaEdit, FaFacebook, FaInstagram, FaLaptopMedical, FaLinkedin, FaTwitter } from 'react-icons/fa';

import pattern1 from '../Images/pattern/dot2.svg';
import pattern2 from '../Images/pattern/dot5.svg';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { CheckBox } from '@mui/icons-material';

function HomePage() {
  const navigate = useNavigate()
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);


  return (
    <div className="min-h-screen flex flex-col font-display">

      {/* Hero Section */}
      <div id="home" className="flex-grow flex flex-col justify-start items-center pt-[6rem] sm:pt-[11rem] pb-[9rem] bg-darkerBackground mt-[3vh]">
        <div className="relative w-full container mx-auto flex flex-col lg:flex-row sm:space-x-20 items-center border-red-500">

          {/* Text Container */}
          <div className="lg:w-5/12 text-center lg:text-left p-5 text-primary fade-in-header-quick  border-green-500">
            <h2 className="text-4xl sm:text-5xl md:text-5xl lg:text-6xl leading-tight mb-6" data-aos="fade-up">
              Farewell, <br/> <span className="text-secondary">Manual Charting</span>
            </h2>
            {/* <p className="text-lg sm:text-xl md:text-xl font-display lg:mx-0" data-aos="fade-up" data-aos-delay="100">
              Leverage the power of <span className="text-secondary">AI</span> to streamline your medical documentation. ChartMD utilizes advanced models to accurately capture and transcribe your consultations, generating comprehensive SOAP notes effortlessly.
            </p> */}
            <div className="flex items-center mt-4" data-aos="slide-right" data-aos-delay="200">
              <input
                placeholder="Your Email"
                className="my-4 bg-white border-primary border py-4 px-4 rounded-l-lg w-full"
              />
              <button
                onClick={() => navigate("/entry")}
                className="bg-primary border border-primary text-background font-semibold rounded-r-lg py-4 px-2 shadow-lg hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition-colors duration-300 flex-grow lg:flex-grow-0 min-w-fit lg:w-28"
              >
                Sign Up
              </button>
            </div>
            <div className="flex flex-col space-y-4 justify-center lg:justify-start mt-2" data-aos="fade-up" data-aos-delay="300">
              <div className='w-full flex'>
                <CheckBox className='mr-2' /> Free 14-day demo
              </div>
              <div className='w-full flex'>
                <CheckBox className='mr-2' /> No credit card needed
              </div>
            </div>
          </div>

          {/* Image Container */}
          <div className="relative w-full lg:w-7/12 flex justify-center p-5 lg:justify-end fade-in-header-quick border-yellow-500" data-aos="fade-left">
            <img src={userLaptop} alt="Laptop displaying SOAP notes" className="w-12/12 sm:w-3/3 lg:w-12/12 h-auto rounded-xl drop-shadow-lg z-50" />
            <div data-aos='zoom-out-left' className="absolute w-24 h-24 -bottom-12 -start-12">
              <img src={pattern1} alt="Pattern 1" className="w-full h-full" />
            </div>
            <div data-aos='zoom-out-right' className="absolute w-24 h-24 top-0 end-10 2xl:end-0 rotate-45">
              <img src={pattern2} alt="Pattern 2" className="w-full h-full" />
            </div>
          </div>

        </div>
      </div>

      {/* How ChartMD Works Section */}
      <div id="how-chartmd-works" className="py-16 md:py-24 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div data-aos="fade-up" className="text-center">
            <h3 className="text-3xl md:text-4xl font-bold mb-4">
              How <span className="text-primary">ChartMD</span> Works
            </h3>
            <p className="text-xl md:text-2xl text-secondary mb-12">
              Streamlining medical documentation with AI-powered efficiency
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">

            {/* Step 1: AI Transcription */}
            <div data-aos="fade-right" className="bg-white rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex flex-col items-center text-center">
              <div className="flex items-center justify-center h-24 w-24 rounded-full bg-primary text-white mb-6">
                <img src={TranscriptionIcon} alt="Transcription" className="h-12 w-12" />
              </div>
              <h4 className="text-2xl font-bold mb-4">AI Transcription</h4>
              <p className="text-lg text-gray-600">
                Our AI accurately converts doctor-patient conversations into text in real-time, ensuring no detail is missed.
              </p>
            </div>

            {/* Step 2: AI Analysis */}
            <div data-aos="fade-up" data-aos-delay="200" className="bg-white rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex flex-col items-center text-center">
              <div className="flex items-center justify-center h-24 w-24 rounded-full bg-primary text-white mb-6">
                <img src={AnalyzeIcon} alt="Analysis" className="h-12 w-12" />
              </div>
              <h4 className="text-2xl font-bold mb-4">AI Analysis</h4>
              <p className="text-lg text-gray-600">
                Advanced algorithms interpret the dialogue, identifying key medical information for note summarization.
              </p>
            </div>

            {/* Step 3: SOAP Note Creation */}
            <div data-aos="fade-left" data-aos-delay="400" className="bg-white rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex flex-col items-center text-center">
              <div className="flex items-center justify-center h-24 w-24 rounded-full bg-primary text-white mb-6">
                <img src={Notes} alt="SOAP Notes" className="h-12 w-12" />
              </div>
              <h4 className="text-2xl font-bold mb-4">SOAP Note Creation</h4>
              <p className="text-lg text-gray-600">
                Intelligent systems draft SOAP notes that encapsulate the patient encounter, streamlining documentation.
              </p>
            </div>

          </div>
        </div>
      </div>

      {/* Id to scroll to */}
      <div id="chartmd-features" />

      {/* Features */}
      <div className="py-20 md:py-20 bg-white flex flex-col justify-center items-center px-6 text-center">
        <h2 data-aos="fade-up" className="text-4xl md:text-5xl font-bold text-center text-secondary mb-20">
          Prime Features of <br /><span className="text-primary">ChartMD</span>
        </h2>
        <div className="container grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Feature 1: Customizable Templates */}
          <div data-aos="fade-right" data-aos-delay="200" className="bg-accent rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <div className="flex items-center justify-center h-20 w-20 rounded-full bg-secondary text-white mx-auto mb-6">
              <FaEdit className="h-10 w-10" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">Customizable Templates</h3>
            <p className="text-lg text-gray-600 mb-6">
              Tailored SOAP note templates for various medical specialties, fully customizable to meet individual doctor's needs.
            </p>
            <ul className="text-left space-y-4">
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Specialty-specific layouts for targeted documentation</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Editable sections to suit personal workflow</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Automated inclusion of frequently used terms and phrases</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Easy sharing and collaboration with colleagues</span>
              </li>
            </ul>
          </div>
          {/* Feature 2: Intelligent Summarization */}
          <div data-aos="fade-up" data-aos-delay="200" className="bg-accent rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <div className="flex items-center justify-center h-20 w-20 rounded-full bg-secondary text-white mx-auto mb-6">
              <FaBrain className="h-10 w-10" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">Intelligent Summarization</h3>
            <p className="text-lg text-gray-600 mb-6">
              AI-driven summarization highlighting key points of patient conversations, aiding in efficient diagnosis and treatment.
            </p>
            <ul className="text-left space-y-4">
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Context-aware AI for accurate key point extraction</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Summaries tailored to the individual patient's narrative</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Real-time summary generation during patient visits</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Editable summaries for doctor's final review and adjustments</span>
              </li>
            </ul>
          </div>
          {/* Feature 3: EHR Integration */}
          <div data-aos="fade-left" data-aos-delay="400" className="bg-accent rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <div className="flex items-center justify-center h-20 w-20 rounded-full bg-secondary text-white mx-auto mb-6">
              <FaLaptopMedical className="h-10 w-10" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">EHR Integration</h3>
            <p className="text-lg text-gray-600 mb-6">
              Seamless integration with Electronic Health Records for efficient data exchange and synchronization.
            </p>
            <ul className="text-left space-y-4">
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Compatible with major EHR systems</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Secure and compliant data transfer protocols</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Automatic updating of patient records with new notes</span>
              </li>
              <li className="flex items-start">
                <AiOutlineCheck className="text-green-600 h-6 w-6 mr-2 flex-shrink-0" />
                <span>Facilitates easy retrieval of historical patient data</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    
      {/* Pricing Plans */}
      <div id="pricing-plans" className="py-16 md:py-24 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl md:text-5xl font-bold text-center text-blue-600 mb-12 ">
            Pricing Plans
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">

            {/* Basic Plan */}
            <div className="bg-darkerBackground rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
              <h3 className="text-2xl font-semibold mb-4 text-blue-600">Basic</h3>
              <p className="text-4xl font-bold mb-6 text-gray-800">$149<span className="text-xl text-gray-500">/month</span></p>
              <ul className="mb-8 space-y-4">
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  <span>Basic AI Transcription</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  <span>Up to 100 transcriptions per month</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  <span>Email Support</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  <span>Basic Reporting Tools</span>
                </li>
              </ul>
              <button className="bg-white text-blue-600 py-3 px-8 rounded-full font-semibold transition duration-300 ease-in-out hover:bg-blue-700">
                Choose Basic
              </button>
            </div>

            {/* Professional Plan */}
            <div className="bg-primary rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
              <h3 className="text-2xl font-semibold mb-4 text-white">Professional</h3>
              <p className="text-4xl font-bold mb-6 text-white">$299<span className="text-xl text-blue-200">/month</span></p>
              <ul className="mb-8 space-y-4 text-white">
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>Advanced AI Analysis</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>Unlimited Transcriptions</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>Priority Email and Chat Support</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>Customizable Templates</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>Integration with EHR</span>
                </li>
              </ul>
              <button className="bg-white text-blue-600 py-3 px-8 rounded-full font-semibold transition duration-300 ease-in-out hover:bg-blue-100">
                Choose Professional
              </button>
            </div>

            {/* Enterprise Plan */}
            <div className="bg-secondary rounded-lg shadow-lg p-8 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
              <h3 className="text-2xl font-semibold mb-4 text-white">Enterprise</h3>
              <p className="text-4xl font-bold mb-6 text-white">Custom Pricing</p>
              <ul className="mb-8 space-y-4 text-white">
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>All Professional Features</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>On-site Training and Support</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>API Access for Custom Integration</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>Dedicated Account Manager</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-400 mr-2" />
                  <span>Custom Development Options</span>
                </li>
              </ul>
              <button className="bg-white text-gray-900 py-3 px-8 rounded-full font-semibold transition duration-300 ease-in-out hover:bg-gray-100">
                Contact Us
              </button>
            </div>

          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-primary text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="space-y-4">
              <img src={logo} alt="ChartMD Logo" className="h-12" />
              <p className="text-lg">Revolutionizing Medical Documentation</p>
              <div className="flex space-x-4">
                <a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">
                  <FaFacebook className="h-6 w-6" />
                </a>
                <a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">
                  <FaTwitter className="h-6 w-6" />
                </a>
                <a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">
                  <FaLinkedin className="h-6 w-6" />
                </a>
                <a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">
                  <FaInstagram className="h-6 w-6" />
                </a>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Company</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">About Us</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Careers</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Press</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Blog</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Products</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Features</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Pricing</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Integrations</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Security</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Support</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Help Center</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Contact Us</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">Status</a></li>
                <li><a href="#" className="text-white hover:text-blue-200 transition duration-300 ease-in-out">API Docs</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 border-t border-blue-700 pt-8">
            <p className="text-center text-sm">
              &copy; {new Date().getFullYear()} ChartMD. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
