import React, { useState, useEffect, useRef, useDebugValue } from 'react';
import { FaUser, FaRobot, FaPaperPlane, FaMicrophone } from 'react-icons/fa';

import { ImSpinner2 } from 'react-icons/im';

import SimpleMarkDown from '../../../HelperComponents/SimpleMarkDown';
import Message from '../../../HelperComponents/Message';

import axios from 'axios';

export default function ChatModal({ isOpen, onClose, initialMessage, subject, note }) {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const messageContainerRef = useRef(null);

  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Effect to handle initial message
  useEffect(() => {
    if (initialMessage) {
      const initialUserMessage = {
        id: 1,
        text: initialMessage,
        role: 'user',
      };
      setMessages([initialUserMessage]);

      // Immediately send initial message to get AI response
      sendToAI([initialUserMessage]);
    }
  }, [initialMessage]);

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleSendMessageSpeech = async (text) => {
    console.log('Input Message:', text);
    if (text.trim() !== '') {
      const newUserMessage = {
        id: messages.length + 1,
        text: text,
        role: 'user',
      };
      const updatedMessages = [...messages, newUserMessage];
      setMessages(updatedMessages);
      setInputMessage('');
      alert("Clearing input message")
  
      let messagesToSend;
  
      // Check if the total number of messages is greater than 4
      if (updatedMessages.length > 4) {
        // Initialize the messages to send with the new user message
        messagesToSend = [newUserMessage];
  
        // Iterate through the previous messages in reverse order
        for (let i = updatedMessages.length - 2; i >= 0 && messagesToSend.length < 5; i--) {
          const message = updatedMessages[i];
          
          // Add the message to the beginning of the array
          messagesToSend.unshift(message);
  
          // If the desired length of 4 is reached and the last added message is from the user, break the loop
          if (messagesToSend.length === 4 && message.role === 'user') {
            break;
          }
        }
  
        // If the fourth message is from the assistant, attempt to add a fifth message
        if (messagesToSend.length === 4 && messagesToSend[0].role === 'assistant') {
          if (updatedMessages.length - messagesToSend.length > 0) {
            messagesToSend.unshift(updatedMessages[updatedMessages.length - messagesToSend.length - 1]);
          }
        }
      } else {
        // Use all available messages if there are not enough to apply the logic
        messagesToSend = updatedMessages.slice();
      }
  
      // Send the messages to the API
      try {
        setIsLoading(true);
        console.log('isLoading set to true');        

        console.log('isListening:', isListening);
        console.log('isLoading:', isLoading);
        if(isListening) {
          console.log('isLoading is true and isListening is true');
          await handleStopListening();
          console.log('Listening stopped');
          await sendToAI(messagesToSend);
          console.log('Sent to AI');
          await handleStartListening();
          console.log('Listening started');
        }
        
      } catch (error) { 
        console.error('Failed to send message:', error);
      } finally {
        setIsLoading(false);
        console.log('isLoading set to false');
      }
    }
  };
  

  const handleSendMessage = async (previousMessage) => {
    let messageToUse = previousMessage ? previousMessage : inputMessage;
    console.log('Input Message:', messageToUse);
    if (messageToUse.trim() !== '') {
      const newUserMessage = {
        id: messages.length + 1,
        text: messageToUse,
        role: 'user',
      };
      const updatedMessages = [...messages, newUserMessage];
      setMessages(updatedMessages);
      setInputMessage('');
  
      let messagesToSend;
  
      // Check if the total number of messages is greater than 4
      if (updatedMessages.length > 4) {
        // Initialize the messages to send with the new user message
        messagesToSend = [newUserMessage];
  
        // Iterate through the previous messages in reverse order
        for (let i = updatedMessages.length - 2; i >= 0 && messagesToSend.length < 5; i--) {
          const message = updatedMessages[i];
          
          // Add the message to the beginning of the array
          messagesToSend.unshift(message);
  
          // If the desired length of 4 is reached and the last added message is from the user, break the loop
          if (messagesToSend.length === 4 && message.role === 'user') {
            break;
          }
        }
  
        // If the fourth message is from the assistant, attempt to add a fifth message
        if (messagesToSend.length === 4 && messagesToSend[0].role === 'assistant') {
          if (updatedMessages.length - messagesToSend.length > 0) {
            messagesToSend.unshift(updatedMessages[updatedMessages.length - messagesToSend.length - 1]);
          }
        }
      } else {
        // Use all available messages if there are not enough to apply the logic
        messagesToSend = updatedMessages.slice();
      }
  
      // Send the messages to the API
      try {
        setIsLoading(true);
        setInputMessage('');
        console.log('isLoading set to true');        

        console.log('isListening:', isListening);
        console.log('isLoading:', isLoading);
        if(isListening) {
          console.log('isLoading is true and isListening is true');
          await handleStopListening();
          console.log('Listening stopped');
          await sendToAI(messagesToSend);
          console.log('Sent to AI');
          await handleStartListening();
          console.log('Listening started');
        }else{
          await sendToAI(messagesToSend);
        }
        
      } catch (error) { 
        console.error('Failed to send message:', error);
      } finally {
        setIsLoading(false);
        console.log('isLoading set to false');
      }
    }
  };


  const sendToAI = async (messagesToSend) => {
    // Map messages for the API
    const formattedMessages = messagesToSend.map(msg => ({
      role: msg.role,
      content: msg.text
    }));

    try {
      setIsLoading(true);
      const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/ask-ai-basic", {
        messages: formattedMessages,
        subject: subject,
        note: note
      }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });

      console.log("I got a response:", response)

      // Handle the AI response
      if (response.data) {
        const aiMessage = {
          id: messagesToSend.length + 1,
          text: response.data,
          role: 'assistant',
        };
        console.log("I got a message back:", aiMessage)
        setMessages(prevMessages => [...prevMessages, aiMessage]); // Update the messages array to include the AI response
      }
    } catch (error) {
      console.error('Failed to send messages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    const element = messageContainerRef.current;
    if (element) {
      const start = element.scrollTop;
      const end = element.scrollHeight - element.clientHeight;
      const change = end - start;
      const duration = 300; // Duration of the scroll animation in milliseconds
      let startTimestamp = null;
  
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = timestamp - startTimestamp;
        const progressPercentage = Math.min(progress / duration, 1);
        element.scrollTop = start + change * progressPercentage;
  
        if (progress < duration) {
          window.requestAnimationFrame(step);
        }
      };
  
      window.requestAnimationFrame(step);
    }
  };

  const handleStartListening = () => {
    console.log('Listening...');
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognitionInstance = new SpeechRecognition();
    recognitionInstance.continuous = true;
    recognitionInstance.interimResults = true;
    recognitionInstance.lang = 'en-US';
  
    let prevFinalTranscript = '';
  
    recognitionInstance.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';
        let sendingMessage = false;
    
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcriptPart = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcriptPart;
            if (/[.!?]$/.test(finalTranscript)) {
              finalTranscript += ' ';
            }
          } else {
            interimTranscript += transcriptPart;
          }
        }

      if (finalTranscript !== '') {
        prevFinalTranscript += finalTranscript;
        if(sendingMessage) {
          sendingMessage = false;
          return;
        }
        if(prevFinalTranscript.toLocaleLowerCase().includes('submit')) {
          sendingMessage = true;
        }
        setInputMessage(prevFinalTranscript);
      }else{
        let totalTranscript = prevFinalTranscript + interimTranscript;
        if(sendingMessage) {
          sendingMessage = false;
          return;
        }
        if(totalTranscript.toLocaleLowerCase().includes('submit')) {
          sendingMessage = true;
        }

        setInputMessage(totalTranscript);
      }
    }
  
    recognitionInstance.onend = () => {
      console.log('Stop listening called here.')
      setIsListening(false);
    };
  
    recognitionInstance.start();
    setIsListening(true);
    setRecognition(recognitionInstance);
  };

  useEffect(() => {
    if (isListening) {
      console.log('users input message:', inputMessage);
      if(inputMessage.toLowerCase().includes('submit')){
        let previousMessage = inputMessage.replace('submit.', '').replace('Submit.', '').replace('submit', '').replace('Submit', '').trim();
        setInputMessage(previousMessage);
        handleSendMessage(previousMessage);
        setInputMessage('');
      }else{
        console.log("Message does not include submit.")
      }
    }else{
      if(inputMessage.toLowerCase().includes('submit')){
        setInputMessage('');
      }
    }
  }, [inputMessage]);
  

  const handleStopListening = async () => {
    console.log('Stop listening called here.')
    if (recognition) {
      recognition.stop();
      setIsListening(false);
      // Focus on the textarea when stopping listening
      // textareaRef.current.focus();
    }
  };

  
  

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 fade-in-text">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-blue-600 opacity-75 animate-gradient"></div>
          <div className="bg-white flex flex-col items-center justify-between rounded-lg shadow-lg w-full max-w-5xl min-h-[60vh] mx-auto p-8 z-10 animate-slide-up">
            <div className="flex items-center justify-between mb-8 w-full bg-blue-800 rounded-lg shadow-lg px-8 py-6">
              <div>
                <h2 className="text-4xl font-semibold text-white mb-1">
                  <span className="inline-block">Collaborate with Your AI Partner</span>
                </h2>
                <p className="text-base text-blue-200">Unlock insights and elevate patient care through intelligent discussion</p>
              </div>
              <button
                className="text-white hover:text-blue-200 transition-colors duration-300 focus:outline-none"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="h-96 overflow-y-auto custom-scrollbar custom-scroll-behavior-for-tool-chat w-full" ref={messageContainerRef}>
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`flex items-start mb-6 fade-in-header-quick ${
                    message.role === 'user' ? 'justify-end' : 'justify-start'
                  } animate-fade-in`}
                >
                  <div
                    className={`rounded-lg px-5 py-4 shadow-md text-lg ${
                      message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    <Message message={message} />
                  </div>
                </div>
              ))}
              {isLoading && (
                <div className="flex justify-start mb-6">
                  <div className="bg-gray-100 text-gray-800 rounded-lg px-5 py-4 shadow-md text-lg">
                    <div className="flex items-center">
                      <div className="w-2 h-2 mr-2">
                        <div className="animate-pulse rounded-full h-2 w-2 bg-gray-400"></div>
                      </div>
                      <div className="w-2 h-2 mr-2">
                        <div className="animate-pulse rounded-full h-2 w-2 bg-gray-400 animation-delay-200"></div>
                      </div>
                      <div className="w-2 h-2">
                        <div className="animate-pulse rounded-full h-2 w-2 bg-gray-400 animation-delay-400"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-8 w-full">
              <div className="flex">
                <input
                  type="text"
                  disabled={isLoading || isListening}
                  value={inputMessage}
                  onChange={handleInputChange}
                  onKeyDown={handleInputChange}
                  placeholder={isListening ? "Say your message..." : "Type your message..." }
                  className="flex-grow border border-gray-300 rounded-l-lg px-5 py-4 text-lg focus:outline-none focus:ring-1 focus:ring-blue-500 transition-all duration-300"
                />
                {isListening ? (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={handleStopListening}
                      className="bg-red-200 text-white px-8 py-4 text-xl hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                    >
                      <FaMicrophone className="animate-bounce" />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={handleStartListening}
                      className={`bg-${isListening ? 'red' : 'blue'}-200 text-white px-8 py-4 text-xl hover:bg-${isListening ? 'red' : 'blue'}-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300`}
                    >
                      {isLoading ? <ImSpinner2 className="animate-spin text-white" /> : <span><FaMicrophone /></span>}
                    </button>
                  </>
                )}
                <button
                  onClick={handleSendMessage}
                  className="bg-blue-500 text-white px-8 py-4 rounded-r-lg text-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                >
                  <FaPaperPlane className="animate-pulse" />
                </button>
              </div>
              <p className="mt-2 text-xs text-gray-500">
                * Please note that this AI may make mistakes. The healthcare provider is ultimately responsible for the patient's care.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}