import { useState, useEffect, useRef, useMemo} from 'react';
import React from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight, FaArrowLeft, FaArrowRight, FaBrain, FaCalendarDay, FaCheck, FaCheckCircle, FaCheckSquare, FaChevronRight, FaCircle, FaClock, FaCopy, FaEdit, FaEnvelope, FaEnvelopeOpen, FaExclamationCircle, FaExclamationTriangle, FaEye, FaFileAudio, FaFilter, FaGgCircle, FaInfoCircle, FaLock, FaLockOpen, FaLongArrowAltLeft, FaLongArrowAltRight, FaMicrophone, FaNewspaper, FaPause, FaPauseCircle, FaPen, FaPlay, FaPlus, FaPlusCircle, FaPlusSquare, FaQuestionCircle, FaRedo, FaRegCircle, FaRegSadCry, FaRegSquare, FaRegTimesCircle, FaSave, FaSearch, FaSmile, FaSort, FaSpinner, FaStop, FaTag, FaTimes, FaTimesCircle, FaTrash, FaUpload, FaUser, FaUserAlt, FaUserCheck, FaUserCircle, FaUserFriends, FaUserInjured, FaUserPlus, FaUserShield, FaUserTag } from 'react-icons/fa';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'; // Using FontAwesome icons for arrows
import { useNavigate } from 'react-router-dom';

import logo from '../../src/Images/logo2.png'
import logo2 from '../Images/logo1.png';

import axios from 'axios';

import './ScribePage.css';

// Component Imports
import ConversationModal from './ConversationModal/ConversationModal';
import PatientDetails from './PatientDetailsPage/PatientDetails'; // Adjust the path as necessary
import TranscriptDisplay from './TranscriptDisplay/TranscriptDisplay';
import AIEditNoteModal from './askAIModal/askAImodal';
import AskAIModal from './askAIModal/askAImodal';
import ProfileModal from './ProfileModal/ProfileModal';
import { AiFillCheckCircle, AiFillClockCircle, AiFillEdit, AiFillExperiment, AiFillFilter, AiOutlineCheck, AiOutlinePause, AiOutlinePullRequest, AiOutlineRobot, AiOutlineSearch, AiOutlineSortAscending, AiOutlineUpload, AiTwotoneQuestionCircle } from 'react-icons/ai';
import Badge from './Badge/Badge';
import SimpleMarkDown from './HelperComponents/SimpleMarkDown';
import PatientCompletedModal from './PatientCompletedModal/PatientCompletedModal';
import LoginModal from './LoginModal/LoginModal';
import AudioUploadModal from './AudioUploadModal/AudioUploadModal';
import { Checkbox, ListItemIcon, ListItemText, Menu, MenuItem, TableSortLabel, Tooltip, Delete, MenuList, Typography, TextField, InputAdornment, IconButton, Divider, Box, CircularProgress, Button } from '@mui/material';
import { ImFilter } from 'react-icons/im';
import { Dangerous, DeleteOutline, Done, Filter, Filter1, FilterAltOutlined, FilterListOutlined, FilterListSharp, FilterListTwoTone, FilterVintage, Flag, FlagCircle, NewReleases, Search, Warning, Work } from '@mui/icons-material';

import { ThemeProvider } from '@emotion/react';
import theme from '../theme/theme';
import RecordingButton from './RecordingButton/RecordingButton';

const ScribePage = () => {
    const navigate = useNavigate();

    // Login Modal States
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const toggleLoginModal = () => setIsLoginModalOpen(!isLoginModalOpen);

    // Upload Audio Modal States
    const [isAudioUploadModalOpen, setIsAudioUploadModalOpen] = useState(false);
    const handleCloseModal = () => {
        setIsAudioUploadModalOpen(false);
        document.getElementById('audio-upload').value = ''; // Reset the input
    };
    // Loading Patient
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [previousPatientIdBasic, setPreviousPatientIdBasic] = useState(null);

    // Badge States
    const [badgeVisible, setBadgeVisible] = useState(false);
    const [badgeMessage, setBadgeMessage] = useState('');

    const [isTokenValid, setIsTokenValid] = useState(null);
    const [userFirstName, setUserFirstName] = useState("");
    const [patients, setPatients] = useState([])

    // useEffect(() => {
    //     const token = sessionStorage.getItem('token'); // Or localStorage.getItem('token');
    //     const principal = sessionStorage.getItem('principal');


    //     const validateToken = async () => {
    //         if (!token) {
    //             console.log('No token found');
    //             navigate("/entry");
    //             return;
    //         }

    //         try {
    //             const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/validate-token", { token });
                
    //             if (!response.data.isValid) {
    //                 console.log('Token is invalid or expired');
    //                 navigate("/entry");
    //                 // Handle invalid token (e.g., redirect to login)
    //             } else {
    //                 console.log('Token is valid');
    //                 setIsTokenValid(true);
    //                 retrievePatients(); // Call retrievePatients upon successful token validation
    //             }
    //         } catch (error) {
    //             console.error("Error validating token:", error);
    //             navigate("/entry");
    //             // Handle communication or server errors
    //         }
    //     };
        
    //     const retrievePatients = async () => {
    //         try {
    //             // Use Authorization header to pass the token securely
    //             const patientResponse = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-patients", { principal: principal }, {
    //                 headers: { Authorization: `Bearer ${token}` }
    //             });
        
    //             // Assuming the API returns an array of patients
    //             setPatients(patientResponse.data);
    //             console.log(patientResponse.data)
    //         } catch (error) {
    //             console.error("Error retrieving patients:", error);
    //             // Handle errors (e.g., show a notification to the user)
    //         }
    //     };

    //     validateToken();
    // }, [navigate]);
    // useEffect(() => {
    //     const retrievePatients = async () => {
    //         try {
    //             // Directly fetch patients; token validation and user extraction handled by the server
    //             const patientResponse = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-patients", {
    //                 withCredentials: true  // Ensure credentials are sent with the request
    //             });
    //             setPatients(patientResponse.data);
    //             console.log(patientResponse.data);
    //         } catch (error) {
    //             console.error("Error retrieving patients:", error);
    //             if (error.response && error.response.status === 401) {
    //                 navigate("/entry");  // Redirect to login on auth failure
    //             }
    //         }
    //     };
    
    //     retrievePatients();
    // }, [navigate]);

    useEffect(() => {
        if(!isLoginModalOpen){
            const retrievePatients = async () => {
                try {
                    // Directly fetch patients; token validation and user extraction handled by the server
                    const patientResponse = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-patients", {}, { withCredentials: true });
                    setPatients(patientResponse.data);
                    console.log(patientResponse.data);
                } catch (error) {
                    console.error("Error retrieving patients:", error);
                    if (error.response && error.response.status === 401) {
                        setIsLoginModalOpen(true);  // Redirect to login on auth failure
                    }
                }
            }

            retrievePatients();
        };
    
    }, [navigate, isLoginModalOpen]);
    
    
    const [patientsVisible, setPatientsVisible] = useState(true);
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);
    const [patientData, setPatientData] = useState({});
    const [displayingPatientNotes, setDisplayingPatientNotes] = useState(false);

    const [shouldRender, setShouldRender] = useState(patientsVisible);

    useEffect(() => {
      if (patientsVisible) setShouldRender(true);
    }, [patientsVisible]);
  
    const onAnimationEnd = () => {
      if (!patientsVisible) setShouldRender(false);
    };


    // Search Functionality

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e) => {
      setSearchQuery(e.target.value);
    };

    const [filters, setFilters] = useState({
        new: false,
        in_progress: false,
        completed: false,
        flagged: false,
        recycleBin: false,
    });
    
    const [filteredPatients, setFilteredPatients] = useState([
        { firstName: 'Loading...', lastName: '' },
    ]);
    
    const newCount = useMemo(() => filteredPatients.filter(patient => patient.view_state === 'new').length, [filteredPatients]);
    const inProgressCount = useMemo(() => filteredPatients.filter(patient => patient.view_state === 'in_progress').length, [filteredPatients]);
    const completedCount = useMemo(() => filteredPatients.filter(patient => patient.view_state === 'completed').length, [filteredPatients]);
    const flaggedCount = useMemo(() => filteredPatients.filter(patient => patient.flagged).length, [filteredPatients]);
    const recycleBinCount = useMemo(() => filteredPatients.filter(patient => patient.view_state === 'recycle_bin').length, [filteredPatients]);
    

    const handleFilterChange = (filterName) => {
        setFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: !prevFilters[filterName],
        }));
    };
    
    // Filtered and sorted patients based on the filters
    const filteredAndSortedPatients = useMemo(() => {
        const activeFilters = Object.keys(filters).filter(key => filters[key]);
    
        if (activeFilters.length === 0) {
            return filteredPatients.sort((a, b) => new Date(b.recording_date) - new Date(a.recording_date));
        }
    
        return filteredPatients
            .filter(patient => 
                activeFilters.includes(patient.view_state) || 
                (filters.flagged && patient.flagged)
            )
            .sort((a, b) => new Date(b.recording_date) - new Date(a.recording_date));
    }, [filteredPatients, filters]);
    
    useEffect(() => {
      if (patients) {
        const filtered = patients.filter((patient) =>
          `${patient.first_name}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
        setFilteredPatients(filtered);
      }
    }, [patients, searchQuery]);
    
    
    // Holds mock up patients
    // const patients = [
    //     {
    //         name: "John Doe",
    //         nickname: "Johnny",
    //         recordingDate: "2024-02-01 3:30PM",
    //         conversationTitle: "Annual Physical Exam",
    //         subjective: "Patient reports feeling generally well with no specific complaints. Mentions regular exercise and balanced diet.",
    //         objective: "Vital signs within normal limits. Physical examination unremarkable.",
    //         action: "Recommended continuation of current lifestyle. Updated vaccinations.",
    //         plan: "Schedule next annual exam for 2025. Monitor any changes in health status."
    //     },
    //     {
    //         name: "Jane Smith",
    //         nickname: "Janie",
    //         recordingDate: "2024-02-02 10:00AM",
    //         conversationTitle: "Consultation for Allergies",
    //         subjective: "Patient describes seasonal allergy symptoms including sneezing, itchy eyes, and nasal congestion.",
    //         objective: "Mild conjunctival redness observed. No other abnormalities noted.",
    //         action: "Prescribed non-sedating antihistamine and nasal corticosteroid spray.",
    //         plan: "Follow-up in 4 weeks to assess effectiveness of treatment. Consider allergy testing if no improvement."
    //     },
    //     {
    //         name: "Alex Johnson",
    //         nickname: "AJ",
    //         recordingDate: "2024-02-03 2:00PM",
    //         conversationTitle: "Follow-up on Knee Surgery",
    //         subjective: "Patient reports decreased pain and improved mobility in the knee since the surgery.",
    //         objective: "Knee shows signs of healing well with minimal swelling. Range of motion is good.",
    //         action: "Recommended continuation of physical therapy. Advised on signs of infection to watch for.",
    //         plan: "Next follow-up in 6 weeks to evaluate progress. Discuss potential for return to full activity."
    //     },
    //     {
    //         name: "Ryan Williams",
    //         nickname: "Bob",
    //         recordingDate: "2024-02-04 9:00AM",
    //         conversationTitle: "Annual Physical Exam",
    //         subjective: "Patient mentions occasional headaches but no other concerns. Sleep patterns are regular.",
    //         objective: "Physical exam shows all vital signs within normal ranges. No signs of distress.",
    //         action: "Advised on headache management strategies, including hydration, proper rest, and stress reduction techniques.",
    //         plan: "Encourage patient to keep a headache diary and report if frequency or severity increases. Return for evaluation in one year or sooner if needed."
    //     },
        
    // ];

    const handlePatientClick = async (selectedPatient) => {
        // Update local state
        setFilteredPatients(prevPatients =>
            prevPatients.map(patient =>
                patient.id === selectedPatient.id
                    ? { ...patient, view_state: patient.view_state === 'new' ? 'in_progress' : patient.view_state }
                    : patient
            )
        );
    
        // Make backend update if necessary
        if (selectedPatient.view_state === 'new') {
            try {
                await axios.post(
                    'https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-view-state',
                    {
                        patient_visit_id: selectedPatient.id, // Use patient_visit_id as per backend requirement
                        view_state: 'in_progress',
                    },
                    { withCredentials: true } // Include credentials in the request
                );
                console.log('View state updated successfully');
            } catch (error) {
                console.error('Error updating view state:', error);
                // Optionally revert the change on failure
                setFilteredPatients(prevPatients =>
                    prevPatients.map(patient =>
                        patient.id === selectedPatient.id
                            ? { ...patient, view_state: 'new' }
                            : patient
                    )
                );
            }
        }
    
        // Continue with original logic
        setPatientData(selectedPatient);
        setDisplayingPatientNotes(true);
    };
    
    

    const handleFinalizeNotesForPatient = async (patientId) => {
        // const token = sessionStorage.getItem('token');
    
        const patientIndex = patients.findIndex(patient => patient.id === patientId);
        if (patientIndex === -1) {
            console.error("Patient not found");
            return;
        }
    
        const currentStatus = patients[patientIndex].note_finalized;
        const newStatus = currentStatus === null ? true : !currentStatus;
    
        try {
            const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-patient-visit_finals", {
                patient_visit_id: patientId,
                noteType: "whole_note",
                booleanValue: newStatus
            },
            { withCredentials: true }
            );
    
            if (response.status === 200) {
                // Create a new array with the updated patient data
                const updatedPatients = [...patients];
                updatedPatients[patientIndex] = {...patients[patientIndex], ...response.data, view_state: newStatus ? 'completed' : 'in_progress'};
                setPatients(updatedPatients); // Set the new state which will trigger a re-render
                setPatientData(response.data); // Update the patient data for the current patient (if needed)
                console.log("Note finalized status updated successfully", response.data);
    
                // Update the filteredPatients view_state by mapping over the current filteredPatients
                setFilteredPatients((prevFilteredPatients) =>
                    prevFilteredPatients.map((p) =>
                        p.id === patientId ? { ...p, view_state: newStatus ? 'completed' : 'in_progess' } : p
                    )
                );
            } else {
                console.error("Failed to update note status");
            }
        } catch (error) {
            console.error("Error finalizing notes:", error.response ? error.response.data : error.message);
        }
    };
    

    const deletePatient = async (patientId) => {
        console.log("Patient ID to delete:", patientId)
    
        try {
            const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/delete-patient-visit", {
                patient_visit_id: patientId
            }, 
            { withCredentials: true }
            );
    
            if (response.status === 200) {
                console.log("Patient deleted successfully", response.data);
                if (patientId === activePatientIdBasic) {
                    cancelRecording();
                }
                // Update the patients array to remove the deleted patient
                setPatients(prevPatients => {
                    const index = prevPatients.findIndex(patient => patient.id === patientId);
                    const newPatients = prevPatients.filter(patient => patient.id !== patientId);
    
                    if (newPatients.length === 0) {
                        return newPatients;
                    }
    
                    let newIndex = index >= newPatients.length ? newPatients.length - 1 : index;
    
                    handlePatientClick(newPatients[newIndex]);
                    return newPatients;
                });
            } else {
                console.error("Failed to delete patient", response.data);
            }
        } catch (error) {
            console.error("Error deleting patient:", error.response ? error.response.data : error.message);
        }
    };    
    
    const flagPatientVisit = async (id) => {
        try {
            const response = await axios.post(
                "https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-patient-visit-flagged",
                { patient_visit_id: id },
                { withCredentials: true }
            );
    
            if (response.status === 200) {
                console.log("Patient visit flagged successfully", response.data);
                
                // Update the patients array with the new data from the response
                const updatedPatientData = response.data;
                setPatients(prevPatients => {
                    return prevPatients.map(patient => {
                        if (patient.id === updatedPatientData.id) {
                            return updatedPatientData;
                        }
                        return patient;
                    });
                });
    
            } else {
                console.error("Failed to flag patient visit", response.data);
            }
        } catch (error) {
            console.error("Error flagging patient visit:", error);
        }
    };
    

    // Function to capture audio from the microphone
    const [isBasic, setIsBasic] = useState(true); // This will be used to determine if the basic or advanced AI is being used

    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioURL, setAudioURL] = useState('');
    const [uploadedAudio, setUploadedAudio] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(0); // Timer state
    const [intervalId, setIntervalId] = useState(null); // Store interval ID for clearing
    const [elapsedTime, setElapsedTime] = useState(0); // Store elapsed time in seconds
    const [startTime, setStartTime] = useState(null); // Store start time of recording
    const [conversationStarted, setConversationStarted] = useState(false);
    const [conversationInitialized, setConversationInitialized] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);
    const [conversation, setConversation] = useState("");
    const [errorProccessingAudio, setErrorProccessingAudio] = useState(false); 
    const [processingAudio, setProcessingAudio] = useState(false); 
    const [startNewPatient, setStartNewPatient] = useState(false); // This will be used to determine if a new patient is being added
    const [patientTypeSelected, setPatientTypeSelected] = useState("New"); // This will be used to determine if a new patient is being added
    const [newPatientData, setNewPatientData] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nickName, setNickName] = useState('');

    // Assuming you already have `patients` state or prop
    const [filter, setFilter] = useState('');

    // This keeps track of model openings

    // Convo Modal ---------------------------------------------------------------
    const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);

    // Transcript ---------------------------------------------------------------
    const [showTranscript, setShowTranscript] = useState(false);

    // AiEditModal ---------------------------------------------------------------
    const [isAIEditModalOpen, setIsAIEditModalOpen] = useState({
        subjective: false,
        objective: false,
        assessment_and_plan: false,
        encounter: false,
        medications: false,
        labs: false,
        referrals: false,
    });     
    const toggleAIEditModal = (noteType, isOpen) => {
    setIsAIEditModalOpen(prevState => ({
        ...prevState,
        [noteType]: isOpen,
    }));
    };

    // Profile Modal ---------------------------------------------------------------
    const [profileModalOpen, setProfileModalOpen] = useState(false);
    const toggleProfileModal = () => setProfileModalOpen(!profileModalOpen);

    // Patient Completed Modal ---------------------------------------------------------------
    const [patientCompletedModalOpen, setPatientCompletedModalOpen] = useState(false);
    



    useEffect(() => {
        // Access the microphone and create a media recorder
        const getMicrophoneAccess = async () => {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            
            let options = {};
          
            // Check if the browser supports the desired MIME type
            if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
              options = { mimeType: 'audio/webm;codecs=opus' };
            } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
              // Fallback to mp4 if webm is not supported, but prefer webm for consistency
              options = { mimeType: 'audio/mp4' };
            }
            // Additional else-if blocks can be added for more fallbacks if necessary
          
            const mediaRecorder = new MediaRecorder(stream, options);
            setMediaRecorder(mediaRecorder);
          };
          
    
        getMicrophoneAccess();
    }, []);

    // Audio Visualization
    let audioContext, analyser, microphoneStream;

    const setupAudioAnalysis = async () => {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        analyser = audioContext.createAnalyser();
        analyser.fftSize = 4096; // Increase to 4096, 8192, etc., for more detail
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        microphoneStream = audioContext.createMediaStreamSource(stream);
        microphoneStream.connect(analyser);
        // Now analyser is ready to analyze the audio frequencies
    };

    const visualizeFrequencies = () => {
        const canvas = document.getElementById('audioVisual');
        if (!canvas) return; // Ensure canvas exists
        const ctx = canvas.getContext('2d');
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        
        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;
    
        const draw = () => {
            requestAnimationFrame(draw);
            analyser.getByteFrequencyData(dataArray);
            
            ctx.clearRect(0, 0, WIDTH, HEIGHT); // Clear the canvas for a transparent background
            
            const barWidth = (WIDTH / bufferLength) * 20; // Calculate the width of each bar
            let x = 0; // Initialize the x position of the first bar
            
            for(let i = 0; i < bufferLength; i++) {
                const barHeight = dataArray[i]; // The height of each bar is directly from the frequency data
                
                const y = HEIGHT - barHeight; // Calculate the y position to start drawing from the bottom up
                
                // Use a blue shade for the bars, varying the lightness based on the bar's height
                const hue = 210; // A hue around 210 gives a nice blue
                const saturation = 100; // Full saturation for the richest blue color
                const lightness = 30 + (barHeight / 255) * 40; // Vary lightness between 30% and 70% based on the frequency data
                
                ctx.fillStyle = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                ctx.fillRect(x, y, barWidth, barHeight); // Draw the bar
                
                x += barWidth + 1; // Move the x position for the next bar
            }
        };
        
    
        draw(); // Start the loop
    };
    
    
    
    // Important Variables
    const [previousTranscription, setPreviousTranscription] = useState("");
    const [conversationHeadline, setConversationHeadline] = useState('');
    const [summaries, setSummaries] = useState([]); // This will be used to store the summary of the conversation
    const [previousSummary, setPreviousSummary] = useState("")
    const [questions, setQuestions] = useState([]); // This will be used to store the questions that will be asked
    const [previousQuestions, setPreviousQuestions] = useState("");
    const [timestampSelected, setTimestampSelected] = useState([]);


    const [activePatientId, setActivePatientId] = useState(null)
    const [activePatientIdBasic, setActivePatientIdBasic] = useState(null)
    const [subjective, setSubjective] = useState('');
    const [objective, setObjective] = useState('');
    const [assestment, setAssestment] = useState('')   
    const [assessment_and_plan, setAssessment_and_plan] = useState("");
    const [encounter, setEncounter] = useState("");
    const [medications, setMedications] = useState("");
    const [labs, setLabs] = useState("");
    const [referrals, setReferrals] = useState("");

    // Speech Recognition -----------------------------------------------------------------------------------------------------------------------------------------------------
    const [transcript, setTranscript] = useState([]);
    const [recognition, setRecognition] = useState(null); // State to hold the recognition instance
    const [interimClearCount, setInterimClearCount] = useState(0);
    const isRecordingRef = useRef(isRecording);

    // Audio Modal States
    const [sendingAudioToServer, setSendingAudioToSever] = useState(false)


    const updateInterimClearCount = (newCount) => {
        setInterimClearCount(prevCount => {
            newCount = prevCount + 1;
            return newCount; // Return the updated count
        });
    }

    const updatedTranscript = (finalTranscript, timestamp, interimTranscripts) => {
        setTranscript(prevTranscript => {
            // Remove the previous interim transcripts from the state
            const updatedTranscript = prevTranscript.filter(entry => entry.isFinal);
            if (finalTranscript) {
                // Append the final transcript to the state
                updatedTranscript.push({ text: finalTranscript.trim(), time: timestamp, isFinal: true, whisper: false });
            } else if (interimTranscripts.length > 0) {
                // Append the last interim transcript to the state
                updatedTranscript.push({ text: interimTranscripts[interimTranscripts.length - 1].trim(), time: timestamp, isFinal: false, whisper: false });
            }
            return updatedTranscript;
        });
    }

    const startSpeechRecognition = (setTranscript) => {
        let interimTranscripts = []; // Hold interim transcripts
      
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            const recognition = new SpeechRecognition();
            recognition.continuous = true;
            recognition.interimResults = true;
            recognition.lang = 'en-US'; // Set the language appropriately
            let newCount;
          
      
            recognition.onresult = (event) => {
                // if(!isRecording) return;
                let interimTranscript = '';
                let finalTranscript = '';
                let timestamp = Date.now();
        
                if(isRecordingRef.current){
                    for (let i = event.resultIndex; i < event.results.length; ++i) {
                        const transcriptPortion = event.results[i][0].transcript;
                        if (event.results[i].isFinal) {
                            finalTranscript += transcriptPortion;
                            interimTranscripts = []; // Clear interim transcripts on final result
                            updateInterimClearCount(newCount);
                            // We are keeping track of times the interim transcripts are cleared so we can submit some audio to whisper
                        } else {
                            interimTranscript += transcriptPortion;
                            interimTranscripts.push(interimTranscript); // Collect interim transcripts
                        }
                    }
                        updatedTranscript(finalTranscript, timestamp, interimTranscripts)
                    }
            };
      
            recognition.start();

            // Return the recognition instance
            return recognition;
        } else {
            console.warn('Speech Recognition not available');
            return null; // Return null if Speech Recognition is not available
        }
    };

    const startSpeechRecognitionBasic = (setTranscript) => {
        let interimTranscripts = []; // Hold interim transcripts
      
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            const recognition = new SpeechRecognition();
            recognition.continuous = true;
            recognition.interimResults = true;
            recognition.lang = 'en-US'; // Set the language appropriately
            let newCount;
          
      
            recognition.onresult = (event) => {
                // if(!isRecording) return;
                let interimTranscript = '';
                let finalTranscript = '';
                let timestamp = Date.now();
        
                if(isRecordingRef.current && !isBasic){
                    for (let i = event.resultIndex; i < event.results.length; ++i) {
                        const transcriptPortion = event.results[i][0].transcript;
                        if (event.results[i].isFinal) {
                            finalTranscript += transcriptPortion;
                            interimTranscripts = []; // Clear interim transcripts on final result
                            updateInterimClearCount(newCount);
                            // We are keeping track of times the interim transcripts are cleared so we can submit some audio to whisper
                        } else {
                            interimTranscript += transcriptPortion;
                            interimTranscripts.push(interimTranscript); // Collect interim transcripts
                        }
                    }
                        updatedTranscript(finalTranscript, timestamp, interimTranscripts)
                    }
            };
      
            recognition.start();

            // Return the recognition instance
            return recognition;
        } else {
            console.warn('Speech Recognition not available');
            return null; // Return null if Speech Recognition is not available
        }
    };

    const pauseSpeechRecognition = (recognition) => {
        if (recognition) {
            recognition.stop();
            // Optionally set recognition to null if you want to completely reset it
            setRecognition(null);
          }
    };

    useEffect(() => {
        if (!recognition) return;
    
        const handleEnd = () => {
            console.log("Recognition stopped");
            // Only restart recognition if explicitly intended (e.g., isRecording still true)
            if (isRecordingRef.current) {
                console.log("Restarting recognition");
                // Optionally, add a slight delay to prevent immediate restarts that might cause loops
                setTimeout(() => {
                    if (isRecordingRef.current) recognition.start();
                }, 100); // Adjust delay as needed
            }
        };
    
        recognition.onend = handleEnd;
    
        // Clean up function to remove the event listener
        return () => {
            recognition.onend = null;
        };
    }, [recognition, isRecording]); // Ensure useEffect reacts to changes in both recognition and isRecording



    const stopRecordingAndProcessAudio = async (functionNumber) => {
        console.log("I was just called with " + functionNumber + "as my function number.");
        if (mediaRecorder && mediaRecorder.state === "recording") {

            mediaRecorder.ondataavailable = async (e) => {
                if (e.data && e.data.size > 0) {
                    if(!isRecordingRef.current){
                        return
                    }
                    console.log("Processing blob data");
                    const audioBlob = e.data; 
                    const formData = new FormData();
                    let fileExtension = mediaRecorder.mimeType.includes('webm') ? '.webm' : '.mp4';
                    formData.append("audioFile", audioBlob, `audio-recording${fileExtension}`);
            
                    let taskCompletionRegistry = {
                        headline: conversationHeadline !== ''
                    };
            
                    // Convert taskCompletionRegistry to a JSON string and append it to formData
                    formData.append("taskCompletionRegistry", JSON.stringify(taskCompletionRegistry));
                    const token = sessionStorage.getItem('token'); // Retrieve the token from session storage

                    // Generate Text from Audio
                    console.log("Processing audio segment - TRANSCRIPTION");
                    const whisperTextRaw = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/whisper", formData, {
                        headers: { 
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    const whisperText = whisperTextRaw.data.transcription;
                    setPreviousTranscription(whisperText);
                    const timestamp = await updateTranscriptWithWhisper(whisperText);


                    // Generate Subjective
                    let subjectiveProccessed;
                    let objectiveProccessed;
                    if(functionNumber > 0){
                        console.log("Processing audio segment - SUBJECTIVE");
                        const subjectiveRaw = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/subjective", { whisperText, prevSubjective: subjective, patient_visit_id: activePatientId }, {
                            headers: { 
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        })
                        subjectiveProccessed = subjectiveRaw.data.newSubjective
                        console.log(subjectiveRaw.data)
                        updateSubjective(subjectiveProccessed);

                        // Generate Objective 
                        console.log("Proccesing audio segment - OBJECTIVE");
                        const objectiveRaw = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/objective", { whisperText, prevObjective: objective, patient_visit_id: activePatientId }, {
                            headers: { 
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        objectiveProccessed = objectiveRaw.data.newObjective
                        console.log(objectiveRaw.data);
                        updateObjective(objectiveProccessed);

                        // Generate Summary
                        try {
                            const requestBody = {
                                whisperText,
                                prevSummaries: summaries, // Array of previous summaries to append the new summary to
                                patientVisitId: activePatientId, // ID to identify which row to update in the database
                            };
                        
                            // Making the POST request to the backend
                            const summaryResponse = await axios.post(
                                "https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/summarize-text", 
                                requestBody, 
                                {
                                    headers: { 
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}` // Authorization token
                                    }
                                }
                            );
                        
                            // Extracting the new summary from the response
                            const newSummary = summaryResponse.data.summary;
                            
                            // Updating the frontend state or UI with the new summary
                            updateSummary(newSummary, timestamp);
                        
                        } catch (error) {
                            // Log or handle errors appropriately
                            console.error("Error processing summary:", error.response ? error.response.data : error.message);
                        }
                    }

                    let assessmentAndPlanProcessed;
                    if(functionNumber > 1){
                        // Generate Assessment And Plan
                        const prevAssessmentAndPlan = assessment_and_plan;
                        console.log("Processing audio segment - Assessment and plan");
                        const assessmentRaw = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/assessment-and-plan", {whisperText, prevAssessmentAndPlan, subjective, objective, patient_visit_id: activePatientId}, {
                            headers: { 
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        assessmentAndPlanProcessed = assessmentRaw.data.newAssessmentAndPlan;
                        updateAssessmentAndPlan(assessmentAndPlanProcessed);

                        if(conversationHeadline === ""){
                            // Generate headline if needed
                            console.log("Processing audio segment - HEADLINE");
                            const headlineRaw = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/create-headline", { whisperText, previousTranscription, patient_visit_id: activePatientId }, {
                                headers: { 
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}` // Add this line
                                }
                            });
                            const headline= headlineRaw.data.headline;
                            updateHeadline(headline);
                        }
                    }
                }
            };
            
      
          // Stop the current recording to trigger the ondataavailable event
          mediaRecorder.stop();
      
          // Immediately start a new recording session
          // Ensure mediaRecorder is ready and configured for a new session
          if (mediaRecorder.state === "inactive") {
            mediaRecorder.start();
          }
        }
    };
    
    const stopRecordingAndProcessAudioBasic = async (openCompleteModal) => {
        if(openCompleteModal){
            setPatientCompletedModalOpen(true);
            return;
        }
        console.log("Stop recording and process audio function called")
        if (mediaRecorder && mediaRecorder.state === "recording") {
                mediaRecorder.ondataavailable = async (e) => {
                    if (e.data && e.data.size > 0) {
                    if (!isRecordingRef.current) {
                        return;
                    }
                    console.log("Processing blob data");
                    const audioBlob = e.data;
                    const formData = new FormData();
                    let fileExtension = mediaRecorder.mimeType.includes('webm') ? '.webm' : '.mp4';
                    formData.append("audioFile", audioBlob, `audio-recording${fileExtension}`);
                    
                    let taskCompletionRegistry = {
                        patient_visit_id: activePatientIdBasic // Include activePatientIdBasic in the taskCompletionRegistry object
                    };

                    // Convert taskCompletionRegistry to a JSON string and append it to formData
                    formData.append("taskCompletionRegistry", JSON.stringify(taskCompletionRegistry));

                    try{
                        try{
                            const processAudio = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/process-audio-basic-active", 
                            formData, 
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                withCredentials: true
                            });    
                        }catch(error){
                            if (error.response && error.response.status === 401) {
                                setIsLoginModalOpen(true);
                            }else{
                                alert("Error processing audio: " + (error.response ? error.response.data : error));
                            }
                        }

                        console.log("Happened first 1")
                        setLoadingPatient(true);
                        setPreviousPatientIdBasic(activePatientIdBasic);

                        // Update the patient_visit_state in the patients array
                        setPatients((prevPatients) =>
                            prevPatients.map((patient) =>
                                patient.id === activePatientIdBasic
                                ? { ...patient, patient_visit_state: 'processing' }
                                : patient
                            )
                        );

                        cancelRecording();
                    }catch (error) {
                        if (error.response && error.response.status === 401) {
                            // Assuming `setIsLoginModalOpen` is the method to open the login modal
                            setIsLoginModalOpen(true);
                        } else {
                            alert("Error processing audio: " + (error.response ? error.response.data : error));
                        }
                    }
                }

            };
            // Stop the current recording to trigger the ondataavailable event  
            console.log("Happened first 2")
            mediaRecorder.stop();
        }else{
            try {
                const processAudio = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/process-audio-basic-inactive", 
                    { patient_visit_id: activePatientIdBasic }, 
                    { withCredentials: true }
                );
            
                console.log("Happened first 1")

                console.log("Happened first 1")
                setLoadingPatient(true);
                setPreviousPatientIdBasic(activePatientIdBasic);

                // Update the patient_visit_state in the patients array
                setPatients((prevPatients) =>
                    prevPatients.map((patient) =>
                        patient.id === activePatientIdBasic
                        ? { ...patient, patient_visit_state: 'processing' }
                        : patient
                    )
                );

                cancelRecording();
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Assuming `setIsLoginModalOpen` is the method to open the login modal
                    setIsLoginModalOpen(true);
                } else {
                    alert("Error processing audio: " + (error.response ? error.response.data : error));
                }
            }                     
        }
    }

    const processAudioFileBasic = async (audioFile) => {
        const patientId = await startConversationBasic();
        setSendingAudioToSever(true)

        console.log("Process audio file function called");
    
        const formData = new FormData();
        formData.append("audioFile", audioFile, `audio-recording${audioFile.name.substr(audioFile.name.lastIndexOf('.'))}`);
        
        let taskCompletionRegistry = {
            patient_visit_id: patientId
        };
    
        // Convert taskCompletionRegistry to a JSON string and append it to formData
        formData.append("taskCompletionRegistry", JSON.stringify(taskCompletionRegistry));
    
        try {
            const response = await axios.post(
                "https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/process-audio-basic-active",
                formData, 
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                }
            );
            console.log("Audio processing initiated", response);
    
            // Here, handle further state updates or UI changes after successful upload
            setLoadingPatient(true);
            setPreviousPatientIdBasic(patientId);
    
            // Update the patient_visit_state in the patients array
            setPatients((prevPatients) =>
                prevPatients.map((patient) =>
                    patient.id === patientId
                    ? { ...patient, patient_visit_state: 'processing' }
                    : patient
                )
            );
    
        } catch (error) {
            console.error("Error processing audio:", error);
            if (error.response && error.response.status === 401) {
                setIsLoginModalOpen(true);
            } else {
                alert("Error processing audio: " + (error.response ? error.response.data : error));
            }
        } finally {
            setTimeout(() => {
                setSendingAudioToSever(false)
                setIsAudioUploadModalOpen(false)
                cancelRecording();
            }, 3000)

        }
    };
    

    const noteTypes = ['transcript', 'subjective', 'objective', 'assessment_and_plan', 'conversation_title'];

    // This runs when the doctor finishes the visit
    useEffect(() => {
      if (loadingPatient) {
        let currentNoteIndex = 0;
    
        const interval = setInterval(async () => {
          try {
            const currentNoteType = noteTypes[currentNoteIndex];
    
            const response = await axios.post(
              "https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-single-note-basic",
              { patient_visit_id: previousPatientIdBasic, noteType: currentNoteType },
              { withCredentials: true }
            );
            console.log(response.data);
    
            if (response.data.note !== null) {
              // Update the specific note section in the patients array
              setPatients((prevPatients) =>
                prevPatients.map((patient) =>
                  patient.id === previousPatientIdBasic
                    ? { ...patient, [currentNoteType]: response.data.note }
                    : patient
                )
              );
    
              // Move to the next note type
              currentNoteIndex++;
    
              if (currentNoteIndex === noteTypes.length) {
                // All note sections are completed
                setPatients((prevPatients) =>
                  prevPatients.map((patient) =>
                    patient.id === previousPatientIdBasic
                      ? { ...patient, patient_visit_state: 'completed' }
                      : patient
                  )
                );
                setLoadingPatient(false);
                setPreviousPatientIdBasic(null);
              }
            }
          } catch (error) {
            if (error.response && error.response.status === 404) {
              console.error("Patient visit not found");
            } else {
              console.error("Error checking patient status:", error.response ? error.response.data : error);
            }
          }
        }, 2000);
    
        return () => clearInterval(interval);
      }
    }, [loadingPatient, previousPatientIdBasic]);
    


    // Helper functions
    const handleAudioUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
        // Here you might want to do something with the file
        // For now, we're just saving it to state
        setUploadedAudio(file);
        setIsAudioUploadModalOpen(true);
        
        // Optionally, if you want to read and play the file immediately:
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   const audioSrc = e.target.result;
        //   // Do something with audioSrc, like setting it to an audio player's source
        // };
        // reader.readAsDataURL(file);
        }
    };

    const splitIntoSentences = (text) => {
        // This regex splits the text by punctuation marks followed by spaces or end of text
        const sentenceEndings = /(?<=[.!?])\s+(?=[A-Z])|(?<=[.!?])$/g;
        return text.split(sentenceEndings).filter(Boolean); // Filter out any empty strings
    };

    const updateTranscriptWithWhisper = async (whisperText) => {
        const sentences = splitIntoSentences(whisperText);
        const timestamp = Date.now();
    
        let updatedTranscript = [];
    
        setTranscript((prevTranscript) => {
            const retainedEntries = prevTranscript.filter(entry => entry.whisper === true);
            const newEntries = sentences.map(sentence => ({
                text: sentence,
                whisper: true,
                isFinal: true,
                time: timestamp,
            }));
            updatedTranscript = [...retainedEntries, ...newEntries];
            return updatedTranscript;
        });
    
        await sendUpdatedTranscriptToServer(updatedTranscript);
    
        return timestamp;
    };
    
    const sendUpdatedTranscriptToServer = async (updatedTranscript) => {
        try {
            const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-transcript", {
                activePatientId: activePatientId,
                transcript: updatedTranscript,
            });
            console.log('Transcript updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating transcript:', error);
        }
    };

    const updateHeadline = (headline) => { 
        setConversationHeadline((prevHeadline) => {
            return headline;
        });
    };

    const updateSummary = (summary, timestamp) => {
        console.log("Adding summary:", summary, "at timestamp:", timestamp);
        setSummaries(prevSummaries => [...prevSummaries, { summary, timestamp }]);
    };

    const updateQuestions = (questions) => {
        setQuestions(questions);
    };

    const updateSubjective = (subjectiveText) => {
        setSubjective((prevSubjective) => {
            return subjectiveText;
        })
    }

    const updateObjective = (objectiveText) => {
        setObjective((prevObjective) => {
            return objectiveText
        })
    }

    const updateAssessmentAndPlan = (assessment_and_plan) => {
        setAssessment_and_plan((prevAssessmentAndPlan) => {
            return assessment_and_plan;
        })
    }
    
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const pad = (num) => num.toString().padStart(2, '0');
        return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    };  

    const [copyDisplayText, setCopyDisplayText] = useState("Copy");
    const copyToClipboard = (text, noteType) => {
        navigator.clipboard.writeText(text).then(() => {
          setCopyDisplayText(`${noteType} Copied to Clipboard!`);
          setTimeout(() => setCopyDisplayText("Copy"), 5000);
        }).catch((err) => {
          console.error('Could not copy text: ', err);
          setCopyDisplayText("Failed to copy");
          setTimeout(() => setCopyDisplayText("Copy"), 5000);
        });
    };


    useEffect(() => {
        if (isBasic) return;
        return () => {
            if (recognition) {
                recognition.stop();
            }
        };
    }, [recognition]);

    useEffect(() => {
        if (isRecording) {
            console.log("Recording is in progress. Interim Clear Count: " + interimClearCount);
        } else {
            console.log("Recording has stopped. Interim Clear Count: " + interimClearCount);
        }
    }, [interimClearCount]);

    useEffect(() => {
        isRecordingRef.current = isRecording;
        console.log("Set isRecording to status:  " + isRecording)
    }, [isRecording]);

    useEffect(() => {
        if (interimClearCount === 10) {
            console.log("Calling at 10")
            stopRecordingAndProcessAudio(1, isRecording);
        }else if(interimClearCount === 20){
            console.log("Calling at 20")
            stopRecordingAndProcessAudio(1, isRecording);
        }else if(interimClearCount === 30){
            console.log("Calling at 30")
            stopRecordingAndProcessAudio(2, isRecording);
        }else if(interimClearCount === 40){
            console.log("Calling at 40")
            stopRecordingAndProcessAudio(1, isRecording);
        }else if(interimClearCount === 50){
            console.log("Calling at 50")
            stopRecordingAndProcessAudio(2, isRecording);
            setInterimClearCount(0)
        }
    }, [interimClearCount]); 
    // This is a very important section, this will be the heart of the data flow and we must figure out which sections we should call and when to generate the tree of data
    
      
    const startConversation = async () => {
            const principal = sessionStorage.getItem('principal');
            const token = sessionStorage.getItem('token'); // Retrieve the token from session storage
            const currentDate = new Date().toISOString(); // Get current date in ISO format

            console.log("Adding new patient visit")
    
            const payload = {
                principal: principal, // Include the principal from session storage
                first_name: firstName,
                last_name: lastName,
                nick_name: nickName,
                date_added: currentDate, // Include the current date
            };
    
            try {
                const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/insert-new-patient", payload, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
    
                if (response.status === 201) {
                    console.log(response.data);
                    setPatients(currentPatients => [...currentPatients, response.data]);
                    setActivePatientId(response.data.id)
                    setIsBasic(false);
                    // We are routing up the processes if the user is using the basic version or the advanced version
                    // So now what we need to do is go in and adjust the functions not to be called over a certain amount of transcript results
                    // Then on the complete button we need to make a function that sends the whole audio file over for processing.
                    // Then it will just save the subjective and all of that stuff automatically and we can view it afterwards
                }else if(response.status === 401){
                    alert("Your login credentials have expired. Login and and try again.");
                    navigate("/entry");
                }else{
                    alert("There was an error. Please log in and try again. Response: " + response.status)
                    navigate("/entry")
                }
            } catch (error) {
                console.error(error); // Log or handle error
            }
        setConversationInitialized(true);
        // setPatientsVisible(false);
        setBadgeMessage("New Patient Visit Added!");
        setBadgeVisible(true);
        setTimeout(() => {
            setBadgeVisible(false);
        }, 3000);
    };

    const startConversationBasic = async () => {
        const principal = sessionStorage.getItem('principal');
        const currentDate = new Date().toISOString(); // Get current date in ISO format
    
        console.log("Adding new patient visit")
    
        const payload = {
            principal: principal, // Include the principal from session storage
            first_name: firstName,
            last_name: lastName,
            nick_name: nickName,
            date_added: currentDate, // Include the current date
        };
    
        let response;

        try {
            response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/insert-new-patient", 
                payload,
                { withCredentials: true }
            );
    
            if (response.status === 201) {
                // console.log(response.data);
                setPatients(currentPatients => [...currentPatients, response.data]);
                setIsBasic(true);
                if (!uploadedAudio) {
                    startRecordingBasic();
                }
                const activePatientId = response.data.id;
                setActivePatientIdBasic(response.data.id)

                const updateNotes = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-patient-visit-state-basic", 
                    {patient_visit_id: activePatientId, newState: "incomplete"},
                    { withCredentials: true }
                );

            } else if (response.status === 401) {
                setIsLoginModalOpen(true);
            } else {
                alert("There was an error. Please log in and try again. Response: " + response.status)
                navigate('/entry?login=true')
            }
        } catch (error) {
            console.error(error); // Log or handle error
        }
        if (!uploadedAudio) {
            setConversationInitialized(true);
        }else{
            return response.data.id;
        }
        // setPatientsVisible(false);
    };
    
    
    
    // Start and stop recording functions ------------------------------------------------------------------------------------------------------------------------------------
    const [isPaused, setIsPaused] = useState(false);
    const [isRecordingPreviousVisit, setIsRecordingPreviousVisit] = useState(false);

    const startRecording = async () => {
        console.log("Starting recording");
        setIsRecording(true);
        if (!conversationStarted) {
            setConversationStarted(true);
            setElapsedTime(0); 
            setTimer(null);
        }
        if (mediaRecorder && mediaRecorder.state === "inactive") {
            mediaRecorder.start();
            console.log(mediaRecorder.mimeType);
            const startTime = new Date().getTime();
            setStartTime(startTime);
            setAudioURL('');
    
            const intervalId = setInterval(() => {
                const now = new Date().getTime();
                const elapsed = Math.floor((now - startTime) / 1000);
                setElapsedTime(elapsed);
            }, 1000);
            setIntervalId(intervalId);

            setSuggestionsVisible(true);
            
            // Setup audio analysis for frequency streaming
            await setupAudioAnalysis();
            visualizeFrequencies();

            
            // Start Speech Recognition
            const recognitionInstance = startSpeechRecognition(setTranscript);
            setRecognition(recognitionInstance); // Save the instance for later use
        }
    };

    const startRecordingBasic = async () => {
        if (!conversationStarted) {
          setConversationStarted(true);
          setElapsedTime(0);
          setTimer(null);
        }
        if (mediaRecorder && mediaRecorder.state === "inactive") {
            console.log("Starting recording");
            setIsRecording(true);   
            mediaRecorder.start();
            console.log(mediaRecorder.mimeType);
            const startTime = new Date().getTime();
            setStartTime(startTime);
            setAudioURL('');
            const intervalId = setInterval(() => {
                const now = new Date().getTime();
                const elapsed = Math.floor((now - startTime) / 1000);
                setElapsedTime(elapsed);
            }, 1000);
            setIntervalId(intervalId);
            setSuggestionsVisible(true);
        } else if (mediaRecorder && mediaRecorder.state === "paused") {
            console.log("Resuming recording")
            resumeRecording();
        }
    };

    const pauseRecording = async () => {
        console.log("Stop recording and process audio function called")
        if (mediaRecorder && mediaRecorder.state === "recording") {
            console.log("Regisered as recording, starting everything")
                mediaRecorder.ondataavailable = async (e) => {
                    if (e.data && e.data.size > 0) {
                    if (!isRecordingRef.current) {
                        return;
                    }
                    console.log("Processing blob data");
                    const audioBlob = e.data;
                    const formData = new FormData();
                    let fileExtension = mediaRecorder.mimeType.includes('webm') ? '.webm' : '.mp4';
                    formData.append("audioFile", audioBlob, `audio-recording${fileExtension}`);
                    
                    let taskCompletionRegistry = {
                        patient_visit_id: activePatientIdBasic // Include activePatientIdBasic in the taskCompletionRegistry object
                    };

                    // Convert taskCompletionRegistry to a JSON string and append it to formData
                    formData.append("taskCompletionRegistry", JSON.stringify(taskCompletionRegistry));

                    const processAudio = await axios.post(
                        "https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-transcript-basic",
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            withCredentials: true
                        }
                    );
                }
                console.log("Happened first 1")
            } ;
             // Stop the current recording to trigger the ondataavailable event  
                console.log("Happened first 2")
                console.log("Paused")
                mediaRecorder.stop();
                setIsPaused(true);
                clearInterval(intervalId);

                const processPausedState = await axios.post(
                    "https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-patient-visit-state-basic",
                    { patient_visit_id: activePatientIdBasic, newState: "paused" },
                        {withCredentials: true}
                );

                // Update the patient_visit_state in the patients array
                setPatients((prevPatients) =>
                    prevPatients.map((patient) =>
                        patient.id === activePatientIdBasic
                        ? { ...patient, patient_visit_state: 'paused' }
                        : patient
                    )
                );
        } else {
            console.log("No active media being recorded");
            setIsPaused(true);
            clearInterval(intervalId);
        }
    };

    const resumeRecording = async () => {
        if (mediaRecorder && (mediaRecorder.state === "recording" || mediaRecorder.state === "paused")) {
            console.log("Recording is already active or paused. Please stop the current session before starting a new one.");
            return;
        }
    
        console.log("Starting new recording session");
        setIsRecording(true);
        setConversationStarted(true); // Assuming you want to mark the conversation as started
        setElapsedTime(0); // Reset elapsed time for a new recording session
        setTimer(null); // Reset any existing timers, if applicable
    
        try {
            if (mediaRecorder && mediaRecorder.state === "inactive") {
                mediaRecorder.start();
            
                console.log(mediaRecorder.mimeType); // Logging MIME type for debugging
                const startTime = Date.now();
                setStartTime(startTime);
                setAudioURL(''); // Clear any previous audio URL if applicable
                const intervalId = setInterval(() => {
                    const now = Date.now();
                    const elapsed = Math.floor((now - startTime) / 1000);
                    setElapsedTime(elapsed);
                }, 1000);
                setIntervalId(intervalId);
                setSuggestionsVisible(true); // Adjust UI elements as necessary
                setIsPaused(false); // Reset paused state if applicable

                // Update the patient_visit_state in the patients array
                setPatients((prevPatients) =>
                prevPatients.map((patient) =>
                    patient.id === activePatientIdBasic
                    ? { ...patient, patient_visit_state: 'active' }
                    : patient
                ))
            }
        } catch (err) {
            console.error("Error starting new recording session: ", err);
        }
    };

    const resumePausedRecording = async (patientId, patientName) => {
        if (activePatientIdBasic === patientId) {
            if(mediaRecorder && mediaRecorder.state === "paused"){
                setBadgeMessage("Recording Resumed");
                setBadgeVisible(true);
                setDisplayingPatientNotes(false);
                resumeRecording();
                return;
            }
        }
        if (mediaRecorder && mediaRecorder.state === "active") {
            setBadgeMessage("You are already recording. Please complete the current recording before starting a new one.");
            setBadgeVisible(true);
            return;
        }
    
        const patient = patients.find(p => p.id === patientId); // Finding the patient in the array
        if (!patient) {
            console.error("No patient found with the id", patientId);
            return;
        }
    
        setNewPatientData({
            first_name: patientName,
            last_name: "",
        });
        setIsBasic(true);
        setActivePatientIdBasic(patientId);
        setBadgeMessage("Resuming Recording with " + patient.first_name);
        setBadgeVisible(true);
        setDisplayingPatientNotes(false);
        setIsRecordingPreviousVisit(true);
        setConversationInitialized(true);
        setIsPaused(true);
    };
    
    const cancelRecording = () => {
        if (mediaRecorder && mediaRecorder.state === "recording") {
            mediaRecorder.stop();
        }
        setIsRecording(false);

        // Clear the timer
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

        setConversationInitialized(false);
        setPatientData({});
        setConversationStarted(false);
        setAudioURL('');
        setAudioChunks([]);
        setElapsedTime(0);
        setIntervalId(null);
        setStartTime(null);
        setTimer(null);
        if(!isBasic){
            setPatientTypeSelected(null);
        }
        setStartNewPatient(false);
        setNewPatientData({});
        setFirstName('');
        setLastName('');
        setNickName('');
        setPatientsVisible(true);
        setSuggestionsVisible(false);
        setTranscript([]);
        setConversation('');
        setConversationHeadline('');
        setQuestions([]);
        setSummaries([]);
        setInterimClearCount(0);
        setSubjective("")
        setObjective("")
        setAssestment("")
        setIsPaused(false);
        setIsRecordingPreviousVisit(false);
    };
    
    // const stopRecordingAndProcessAudioBasic = async (openCompleteModal) => {
    //     if(openCompleteModal){
    //         setPatientCompletedModalOpen(true);
    //         return;
    //     }
    //     console.log("Stop recording and process audio function called")
    //     if (mediaRecorder && mediaRecorder.state === "recording") {
    //             mediaRecorder.ondataavailable = async (e) => {
    //                 if (e.data && e.data.size > 0) {
    //                 if (!isRecordingRef.current) {
    //                     return;
    //                 }
    //                 console.log("Processing blob data");
    //                 const audioBlob = e.data;
    //                 const formData = new FormData();
    //                 let fileExtension = mediaRecorder.mimeType.includes('webm') ? '.webm' : '.mp4';
    //                 formData.append("audioFile", audioBlob, `audio-recording${fileExtension}`);
                    
    //                 let taskCompletionRegistry = {
    //                     patient_visit_id: activePatientIdBasic // Include activePatientIdBasic in the taskCompletionRegistry object
    //                 };

    //                 // Convert taskCompletionRegistry to a JSON string and append it to formData
    //                 formData.append("taskCompletionRegistry", JSON.stringify(taskCompletionRegistry));

    //                 const token = sessionStorage.getItem('token'); // Retrieve the token from session storage

    //                 const processAudio = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-transcript-basic", formData, {
    //                     headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                     'Authorization': `Bearer ${token}`
    //                     }
    //                 });
    //             }
    //         } ;
    //          // Stop the current recording to trigger the ondataavailable event  
    //             console.log("Paused")
    //             mediaRecorder.stop();
    //             setIsPaused(true);
    //             clearInterval(intervalId);
    //     }else{
    //         alert("No active media being recorded")
    //     }
    // }
    

    // const resumeRecording = () => {
    //     if (mediaRecorder && mediaRecorder.state === "paused") {
    //       mediaRecorder.resume();
    //       setIsPaused(false);
    //       const startTime = new Date().getTime() - elapsedTime * 1000; // Adjust the start time
    //       setStartTime(startTime);
    //       const intervalId = setInterval(() => {
    //         const now = new Date().getTime();
    //         const elapsed = Math.floor((now - startTime) / 1000);
    //         setElapsedTime(elapsed);
    //       }, 1000);
    //       setIntervalId(intervalId);
    //     }else{
    //         alert("No active media being recorded")
    //     }
    // };
    
    const stopRecording = async () => {
        console.log("Stopping recording");
        setProcessingAudio(true);
        if (mediaRecorder && mediaRecorder.state === "recording") {
    
            // Stop the recording, which will trigger the ondataavailable event
            mediaRecorder.stop();

            pauseSpeechRecognition(recognition); // Stop the recognition
    

        }
        // Clear the timer
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setProcessingAudio(false);
        setIsRecording(false);
    };
    
    const completeRecording = () => {
        if (mediaRecorder && mediaRecorder.state === "recording") {
            mediaRecorder.stop();
        }
        setIsRecording(false);
        setConversationStarted(false);
    
        // Clear the timer
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setElapsedTime(0); // Reset elapsed time
    
        // No need to send data here, but ensure the conversation state is fully reset
        // If sending the last chunk is desired, it should be included in the onstop handler logic
    };

    const clearNameData = async () => {
         setFirstName("")
         setLastName("")
         setNickName("")
         setNewPatientData({})
    }

    useEffect(() => {
        let interval;
        if (isRecording && startTime) {
            // Update elapsed time every second
            interval = setInterval(() => {
                const now = new Date().getTime();
                const elapsed = Math.floor((now - startTime) / 1000); // Convert milliseconds to seconds
                setElapsedTime(elapsed);
            }, 1000);
        } else {
            setElapsedTime(0); // Reset elapsed time when not recording
        }
    
        return () => clearInterval(interval); // Cleanup interval on component unmount or when recording stops
    }, [isRecording, startTime]);

    const [audioStream, setAudioStream] = useState(null);
    useEffect(() => {
        if (isRecording) {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => setAudioStream(stream))
            .catch(err => console.error("Error accessing the microphone", err));
        } else if (audioStream) {
          audioStream.getTracks().forEach(track => track.stop());
          setAudioStream(null);
        }
    }, [isRecording]);


    const [currentNote, setCurrentNote] = useState('This is the current note text.');
    const [noteType, setNoteType] = useState("")

    const openAIAssistModal = async (noteType, noteText) => {
        setCurrentNote(noteText);
        setNoteType(noteType);
        toggleAIEditModal(noteType, true); // Pass true to open the modal
    };

    // Finalization functions
    const [subjectiveFinalized, setSubjectiveFinalized] = useState(false);
    const [objectiveFinalized, setObjectiveFinalized] = useState(false);
    const [assessmentAndPlanFinalized, setAssessmentAndPlanFinalized] = useState(false);
    const [medicationsFinalized, setMedicationsFinalized] = useState(false);
    const [encounterFinalized, setEncounterFinalized] = useState(false);
    const [labsFinalized, setLabsFinalized] = useState(false);
    const [referralsFinalized, setReferralsFinalized] = useState(false);
    const [imagingFinalized, setImagingFinalized] = useState(false);

    // Locked state variables
    const [subjectiveLocked, setSubjectiveLocked] = useState(false);
    const [objectiveLocked, setObjectiveLocked] = useState(false);
    const [assessmentAndPlanLocked, setAssessmentAndPlanLocked] = useState(false);
    const [medicationsLocked, setMedicationsLocked] = useState(false);
    const [encounterLocked, setEncounterLocked] = useState(false);
    const [labsLocked, setLabsLocked] = useState(false);
    const [referralsLocked, setReferralsLocked] = useState(false);
    const [imagingLocked, setImagingLocked] = useState(false);

    // Editing state variables
    const [subjectiveEditing, setSubjectiveEditing] = useState(false);
    const [objectiveEditing, setObjectiveEditing] = useState(false);
    const [assessmentAndPlanEditing, setAssessmentAndPlanEditing] = useState(false);
    const [medicationsEditing, setMedicationsEditing] = useState(false);
    const [encounterEditing, setEncounterEditing] = useState(false);
    const [labsEditing, setLabsEditing] = useState(false);
    const [referralsEditing, setReferralsEditing] = useState(false);
    const [imagingEditing, setImagingEditing] = useState(false);

    
    const [isOpen, setIsOpen] = useState({
        subjective: false,
        objective: false,
        assessment_and_plan: false,
        encounter: false,
        medications: false,
        labs: false,
        referrals: false
    });

    useEffect(() => {
        const updates = {};
    
        if (!subjective) {
            updates.subjective = true;
        }
        if (!objective) {
            updates.objective = true;
        }
        if (!assessment_and_plan) {
            updates.assessment_and_plan = true;
        }
    
        if (Object.keys(updates).length > 0) {
            setIsOpen(prev => ({ ...prev, ...updates }));
        }
    }, [subjective, objective, assessment_and_plan]);
    

    const toggleSection = (section) => {
        setIsOpen(prev => ({ ...prev, [section]: !prev[section] }));
    };

    const finalizeNotes = (noteType, visitID, value) => {
        // Assume patients state and activePatientId are already defined and correctly managed
    
        let currentState;
        let currentPatientIndex;
        // Find the current patient in the array
        if(activePatientId){
            currentPatientIndex = patients.findIndex(patient => patient.id === activePatientId);
        }else{
            currentPatientIndex = patients.findIndex(patient => patient.id === visitID);
        }

        if (currentPatientIndex === -1) {
            alert("Active patient not found in the patients array.");
            return;
        }
    
        // Clone the patients array for immutability
        const updatedPatients = [...patients];
        // Access the current patient object
        const currentPatient = updatedPatients[currentPatientIndex];
    
        // Switch on the noteType to update both the local state and the patient object
        switch(noteType) {
            case "subjective":
                currentState = subjectiveFinalized;
                setSubjectiveFinalized(!subjectiveFinalized);
                // Update the patient object
                currentPatient.subjective_finalized = !currentState;
                if(currentState){
                    setBadgeMessage('Subjective is no longer finalized.');
                }else{
                    setBadgeMessage('Subjective has been finalized.');
                }
                setBadgeVisible(true);
                break;
            case "objective":
                currentState = objectiveFinalized;
                setObjectiveFinalized(!objectiveFinalized);
                currentPatient.objective_finalized = !currentState;
                if(currentState){
                    setBadgeMessage('Objective is no longer finalized.');
                }else{
                    setBadgeMessage('Objective has been finalized.');
                }
                setBadgeVisible(true);
                break;
            case "assessment_and_plan":
                currentState = assessmentAndPlanFinalized;
                setAssessmentAndPlanFinalized(!assessmentAndPlanFinalized);
                currentPatient.assessment_and_plan_finalized = !currentState;
                if(currentState){
                    setBadgeMessage('Assessment and Plan is no longer finalized.');
                }else{
                    setBadgeMessage('Assessment and Plan has been finalized.');
                }
                setBadgeVisible(true);
                break;
            case "medications":
                currentState = medicationsFinalized;
                setMedicationsFinalized(!medicationsFinalized);
                currentPatient.medications_finalized = !currentState;
                if(currentState){
                    setBadgeMessage('Medications is no longer finalized.');
                }else{
                    setBadgeMessage('Medications has been finalized.');
                }
                setBadgeVisible(true);
                break;
            case "encounter":
                currentState = encounterFinalized;
                setEncounterFinalized(!encounterFinalized);
                currentPatient.encounter_finalized = !currentState;
                if(currentState){
                    setBadgeMessage('Encounter is no longer finalized.');
                }else{
                    setBadgeMessage('Encounter has been finalized.');
                }
                setBadgeVisible(true);
                break;
            case "labs":
                currentState = labsFinalized;
                setLabsFinalized(!labsFinalized);
                currentPatient.labs_finalized = !currentState;
                if(currentState){
                    setBadgeMessage('Labs is no longer finalized.');
                }else{
                    setBadgeMessage('Labs have been finalized.');
                }
                setBadgeVisible(true);
                break;
            case "referrals":
                currentState = referralsFinalized;
                setReferralsFinalized(!referralsFinalized);
                currentPatient.referrals_finalized = !currentState;
                if(currentState){
                    setBadgeMessage('Referrals are no longer finalized.');
                }else{
                    setBadgeMessage('Referrals have been finalized.');
                }
                setBadgeVisible(true);
                break;
            default:
                console.log("Invalid note type");
                return; // Exit if the note type is not recognized
        }
    
        // Update the patients array in the state with the modified patient object
        setPatients(updatedPatients);
    
        // Prepare the data to send to backend
        let dataToSend = {}

        if(activePatientId){
            dataToSend = {
                noteType: noteType,
                booleanValue: !currentState,
                patient_visit_id: activePatientId
            }
        }else{
            dataToSend = {
                noteType: noteType,
                booleanValue: !value,
                patient_visit_id: visitID
            }
        }
    
        // Retrieve the token from session storage and make the request
        axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-patient-visit_finals', dataToSend, 
        {
            withCredentials: true,
        })
        .then(response => {
            console.log("Note finalized status updated successfully:", response.data);

            // Update the patients array with the new data from the response
            const updatedPatientData = response.data;
            const updatedPatients = patients.map((patient) => {
              if (patient.id === updatedPatientData.id) {
                return updatedPatientData;
              }
              return patient;
            });
        
            // Update the patients state with the modified array
            setPatients(updatedPatients);
        })
        .catch(error => {
            console.error("Error updating note finalized status:", error.response ? error.response.data : error);
        });
    };

    const saveNotes = (noteType, content) => {
        const token = sessionStorage.getItem("token")

        axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-patient-notes-manual', {noteType, patient_visit_id: activePatientId, content }, {
            withCredentials: true,
        })
        .then(response => {
            console.log("Note content updated successfully:", response.data);
        })
        .catch(error => {
            console.error("Error updating note content. Status:", error.response ? error.response.data : error);
        });
    }

    // Anchor menu stuff
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    // Copy Note
    const copyNoteFromSideBar = async (field, patientId) => {
        // Possible note options:
        const noteFields = [
            'subjective',
            'objective',
            'assessment_and_plan',
            'medications',
            'referrals',
            'labs',
            'encounter'
        ];
    
        const formatFieldName = (fieldName) => {
            return fieldName
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        };
    
        try {
            if (!noteFields.includes(field)) {
                throw new Error('Invalid field name');
            }
    
            const response = await axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-singular-note', 
                { field: field, patientId: patientId }, 
                { withCredentials: true }
            );
    
            const note = response.data.note;
    
            if (note) {
                await navigator.clipboard.writeText(note);
                console.log('Note copied to clipboard');
                
                const formattedFieldName = formatFieldName(field);
                setBadgeMessage(`${formattedFieldName} copied to clipboard`);
                setBadgeVisible(true);
                
                // Set a timeout to hide the badge after 2 seconds
                setTimeout(() => {
                    setBadgeVisible(false);
                }, 2000);
            } else {
                console.log('No note found to copy');
                setBadgeMessage("No note found to copy");
                setBadgeVisible(true);
                setTimeout(() => {
                    setBadgeVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error('Error copying note:', error);
            setBadgeMessage("Error copying note");
            setBadgeVisible(true);
            setTimeout(() => {
                setBadgeVisible(false);
            }, 2000);
        }
    };
    
    


  return (<>
    <ThemeProvider theme={theme} >
        <header className={`fixed top-0 w-full text-primary bg-white drop-shadow-md px-4 py-4 lg:py-4 z-50 font-display`}>
            <div className="w-full flex justify-around items-center h-full px-4">
                <div className="flex items-center space-x-6 sm:space-x-12">
                    <Tooltip title={patientsVisible ? 'Hide Patients' : 'View Patients'}>
                        <div className="flex flex-col items-center">
                            <IconButton
                                sx={{
                                    padding:"0px",
                                    _hover:
                                        {
                                            background:"none",
                                            backgroundColor:"none"
                                        }
                                }}
                                onClick={() => setPatientsVisible(!patientsVisible)}
                                disableRipple
                            >
                                <FaUserShield
                                    size={36}
                                    className="text-primary hover:text-secondary transition-colors duration-300"
                                />
                            </IconButton>
                            <Typography variant="caption" className="text-primary font-bold mt-1">
                                {patientsVisible ? 'Hide' : 'History View'}
                            </Typography>
                        </div>
                    </Tooltip>
                </div>
                <div className="hidden sm:flex items-center space-x-4">
                    <Typography variant="h4" className="text-primary font-semibold drop-shadow-md">
                        Chart.<span className='font-bold'>MD</span>
                    </Typography>
                </div>
                <Tooltip title="View Profile">
                    <div className="flex flex-col items-center p-0">
                        <IconButton
                            sx={{padding:"0px"}}
                            onClick={() => setProfileModalOpen(true)}
                            disableRipple
                        >
                            <FaUserCircle
                                size={36}
                                className="text-primary hover:text-secondary transition-colors duration-300"
                            />
                        </IconButton>
                        <Typography variant="caption" className="text-primary font-bold mt-1">
                            Profile
                        </Typography>
                    </div>
                </Tooltip>
            </div>
        </header>

        <div className='w-full min-h-screen flex justify-between pb-[8vh] pt-[5.5rem] sm:pb-0  bg-background' style={{position:"relative"}}>
            
            {/* Side bar */}
            <div 
                onAnimationEnd={onAnimationEnd} 
                className={`sidebar ${patientsVisible ? 'visible w-[100vw] sm:w-3/12' : ''} mt-3  min-h-full max-h-[90vh] bg-white`} 
                style={{
                    overflowY: 'auto',
                    scrollbarWidth: 'wide', // Firefox
                    msOverflowStyle: 'none',  // Internet Explorer and Edge
                    direction: 'rtl',
                }}
            >
                {/* Search Patients */}
                <div className="relative" style={{direction: "ltr"}}>

                    <TextField
                        id="searchPatients"
                        value={searchQuery}
                        onChange={handleSearch}
                        placeholder="Search Patients"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                            ),
                            classes: {
                            root: 'border-b border-r border-border focus:outline-none focus:ring-2 focus:ring-blue-500',
                            input: 'pt-5 pb-3 pl-4 pr-5',
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                            root: 'absolute text-center left-16 text-primary text-sm transition-all duration-200 ease-in-out',
                            shrink: 'top-3 -translate-y-1/2 text-xs',
                            },
                        }}
                    />

                    {/* Menu Dropdown */}
                    <div className="absolute flex flex-col items-center px-5 right-0 top-1/2 -translate-y-1/2 border-l border-l-gray-600">
                        <ThemeProvider theme={theme}>
                            <div className="flex items-center">
                                <Tooltip title="Filter" arrow placement="top">
                                <button
                                    onClick={handleMenuOpen}
                                    className="flex flex-col justify-center items-center p-1 hover:bg-gray-100 text-gray-500 rounded-full transition-all duration-200"
                                >
                                    <FilterListOutlined className="text-lg text-textPrimary" />
                                </button>
                                </Tooltip>
                                <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                className="mt-2 z-50"
                                sx={{ padding: '0px 0px', minHeight: 'auto' }}
                                >
                                <MenuList sx={{ padding: '0px 8px', minHeight: 'auto' }}>
                                    <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ padding: '4px 8px', fontSize: '0.75rem', fontWeight: 'bold', color: 'gray' }}
                                    >
                                    <FilterListOutlined className="text-xs mr-2 text-primary" /> Filter Visits By:
                                    </Typography>

                                    {/* New */}
                                    <MenuItem sx={{ padding: '4px 8px', minHeight: 'auto' }}>
                                    <ListItemIcon>
                                        <NewReleases fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="New" primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                    <Checkbox
                                        size="small"
                                        checked={filters.new}
                                        onChange={() => handleFilterChange('new')}
                                    />
                                    </MenuItem>

                                    {/* In Progress */}
                                    <MenuItem sx={{ padding: '4px 8px', minHeight: 'auto' }}>
                                    <ListItemIcon>
                                        <Work fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="In Progress" primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                    <Checkbox
                                        size="small"
                                        checked={filters.in_progress}
                                        onChange={() => handleFilterChange('in_progress')}
                                    />
                                    </MenuItem>

                                    {/* Completed */}
                                    <MenuItem sx={{ padding: '4px 8px', minHeight: 'auto' }}>
                                    <ListItemIcon>
                                        <Done fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Completed" primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                    <Checkbox
                                        size="small"
                                        checked={filters.completed}
                                        onChange={() => handleFilterChange('completed')}
                                    />
                                    </MenuItem>

                                    <Divider />

                                    {/* Flagged */}
                                    <MenuItem sx={{ padding: '4px 8px', minHeight: 'auto' }}>
                                    <ListItemIcon>
                                        <Flag fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Flagged" primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                    <Checkbox
                                        size="small"
                                        checked={filters.flagged}
                                        onChange={() => handleFilterChange('flagged')}
                                    />
                                    </MenuItem>

                                    {/* Recycle Bin */}
                                    <MenuItem sx={{ padding: '4px 8px', minHeight: 'auto' }}>
                                    <ListItemIcon>
                                        <DeleteOutline fontSize="small" color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Recycle Bin" primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                    <Checkbox
                                        size="small"
                                        checked={filters.recycleBin}
                                        onChange={() => handleFilterChange('recycleBin')}
                                    />
                                    </MenuItem>
                                </MenuList>
                                </Menu>
                            </div>
                        </ThemeProvider>
                    </div>

                </div> 

                {/* Start New Visit */}
                <div className="flex items-center justify-center border-b-2 border-r border-border text-xs py-5 px-3" style={{direction: "ltr"}}>
                        <button
                            onClick={() => setDisplayingPatientNotes(false)}
                            className='flex w-full justify-center items-center py-4 px-5 mr-1 drop-shadow-md rounded-xl bg-primary text-white hover:bg-secondary text-lg focus:outline-none'
                        >
                            <FaPlus className='mr-2' />
                            <div className='text-white font-bold'>Start New Visit</div>
                        </button>
                </div>

                {/* Map out patients */}
                {filteredAndSortedPatients.map((patient, index) => (
                    <div
                        key={index}
                        style={{direction: "ltr"}}
                        onClick={() => {
                        handlePatientClick(patient);
                        }}
                        className={`p-4 border-b ${patientData.id === patient.id ? "bg-background border-border border-t border-b" :  "hover:bg-darkerBackground border-gray-200 border-r border-r-border"}  flex justify-between items-center cursor-pointer min-w-100 transition duration-300 ease-in-out`}
                    >
                        <div className="min-w-[100%] text-lg">

                            {/* First Line (patient name, icon and checkbox) */}
                            <div className="flex justify-between items-center w-100">

                                {/* Patient Name and Icon */}
                                <div className="relative group">

                                    <div className="inline-flex items-center">
                                        {/* Patient Name */}
                                        <p className={`${patient.note_finalized ? "text-primary" : "text-textPrimary"} font-semibold flex items-center`}>
                                            
                                            {patient.first_name} {patient.last_name} {patient.view_state === "new" && <span className='border-[1px] border-orange-500 text-orange-500  px-1 text-sm rounded ml-2'>New</span>}
                                            
                                            {/* Processing Icon */}
                                            {
                                                {
                                                'active': <FaMicrophone className='ml-2 pulse-icon' />,
                                                'paused': (
                                                    <button
                                                        className='ml-2 p-1 rounded-full bg-primary hover:bg-secondary focus:outline-none'
                                                        onClick={(e) => {e.stopPropagation(); resumePausedRecording(patient.id, patient.first_name);}}
                                                        title='Conversation paused. Click to resume.'
                                                        >
                                                            <AiOutlinePause className='text-white' />
                                                    </button>
                                                ),
                                                'processing': <FaSpinner className='ml-2 processing-rotate' />,
                                                'incomplete': (
                                                    <div className='relative ml-2'>
                                                    <FaExclamationCircle className='text-red-500' />
                                                    <div className='absolute top-full left-1/2 transform -translate-x-1/2 p-2 bg-gray-900 text-white text-sm rounded shadow-lg hidden hover:block'>
                                                        There was a critical error processing this conversation.
                                                    </div>
                                                    </div>
                                                ),
                                                }[patient.patient_visit_state || 'null']
                                            }
                                        </p>

                                        {/* If nickname exists, display it */}
                                        {patient.nickname && (
                                            <FaInfoCircle className={`ml-1 ${patient.note_finalized ? "text-green-400" : "text-blue-400"}  cursor-pointer`} />
                                        )}

                                        {/* Flagged Icon */}
                                        {patient.flagged && <Warning className='text-yellow-500 ml-2' sx={{height:"1rem", width:"auto"}} />}

                                    </div>

                                    {/* Nickanme display */}
                                    {patient.nickname && (
                                        <div className="absolute bottom-full left-0 mb-2 px-4 py-3 w-64 bg-white border border-gray-300 shadow-md text-gray-700 text-sm rounded-lg opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out pointer-events-none">
                                            <div className="flex items-center">
                                                <FaUserTag className="mr-2 text-blue-500" />
                                                <span className="font-semibold">Nickname: {patient.nickname}</span>
                                            </div>
                                        </div>
                                    )}

                                </div>

                                {/* Check Box */}
                                {
                                    patient.note_finalized ? (<>
                                        <FaCheckSquare
                                            onClick={(e) => {e.stopPropagation(); handleFinalizeNotesForPatient(patient.id) }}
                                            className="mx-2 text-primary hover:text-secondary transition duration-300 ease-in-out"
                                        />
                                    </>) 
                                    :
                                    (<>
                                        <FaRegSquare
                                            onClick={(e) => {e.stopPropagation(); handleFinalizeNotesForPatient(patient.id) }}
                                            className="mx-2 text-gray-500 hover:text-primary transition duration-300 ease-in-out"
                                        />
                                    </>)
                                }

                            </div>

                            {/* Second Line (Title) */}
                            <p
                                className={`text-xs font-medium ${
                                patient.conversation_title ? "text-gray-500" : "text-gray-500"
                                }`}
                            >
                                {patient.conversation_title || "No Title"}
                            </p>

                            {/* Third Line (Date and SOAP completions) */}
                            <div className="flex justify-between items-center">

                                {/* Date */}
                                <div className='flex items-center mt-3'>
                                    <FaCalendarDay className='text-gray-500 text-sm mr-2' />
                                    <p className="text-sm text-gray-500">
                                        {new Date(patient.recording_date).toLocaleString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "2-digit",
                                        hour12: true,
                                        })}
                                    </p>
                                </div>

                                {/* Soap Elements */}
                                <Tooltip title="Double-click to copy" arrow>
                                    <div className="flex space-x-2 text-xs  mt-2">
                                        <div
                                            onClick={(e) => e.stopPropagation()}
                                            onDoubleClick={() => copyNoteFromSideBar("subjective", patient.id)}
                                            className={`w-5 h-5 flex items-center justify-center rounded-full transition duration-300 ease-in-out drop-shadow-md font-medium hover:bg-secondary hover:text-white ${
                                                patient.subjective_finalized ? 'bg-primary text-white' : patientData.id === patient.id ? "bg-white text-gray-500" : 'bg-gray-200 text-gray-500'
                                            }`}
                                        >
                                                S
                                        </div>
                                        <div
                                            onClick={(e) => e.stopPropagation()}
                                            onDoubleClick={() => copyNoteFromSideBar("objective", patient.id)}
                                            className={`w-5 h-5 flex items-center justify-center rounded-full transition duration-300 ease-in-out drop-shadow-md font-medium hover:bg-secondary hover:text-white ${
                                                patient.objective_finalized ? 'bg-primary text-white' : patientData.id === patient.id ? "bg-white text-gray-500" : 'bg-gray-200 text-gray-500'
                                            }`}
                                        >
                                                O
                                        </div>
                                        <div
                                            onClick={(e) => e.stopPropagation()}
                                            onDoubleClick={() => copyNoteFromSideBar("assessment_and_plan", patient.id)}
                                            className={`w-5 h-5 flex items-center justify-center rounded-full transition duration-300 ease-in-out drop-shadow-md font-medium hover:bg-secondary hover:text-white ${
                                                patient.assessment_and_plan_finalized ? 'bg-primary text-white' : patientData.id === patient.id ? "bg-white text-gray-500" : 'bg-gray-200 text-gray-500'
                                            }`}
                                        >
                                                A
                                        </div>
                                        <div
                                            onClick={(e) => e.stopPropagation()}
                                            onDoubleClick={() => copyNoteFromSideBar("assessment_and_plan", patient.id)}
                                            className={`w-5 h-5 flex items-center justify-center rounded-full transition duration-300 ease-in-out drop-shadow-md font-medium hover:bg-secondary hover:text-white ${
                                                patient.assessment_and_plan_finalized ? 'bg-primary text-white' : patientData.id === patient.id ? "bg-white text-gray-500" : 'bg-gray-200 text-gray-500'
                                            }`}
                                        >
                                                P
                                        </div>
                                    </div>
                                </Tooltip>
                                
                            </div>
                            
                            {/* Fourth Line (Progress Bar) */}
                            <div className="w-full mt-1 flex items-center">
                                {patient.patient_visit_state === 'processing' && (
                                    <>
                                    <div className="flex-grow">
                                        <div className="bg-accent rounded-full h-2">
                                        <div
                                            className={`h-2 rounded-full transition-all duration-500 ease-in-out ${
                                            patient.conversation_title ? 'bg-secondary' : 'bg-primary'
                                            }`}
                                            style={{
                                            width: `${
                                                ((patient.transcript ? 1 : 0) +
                                                (patient.subjective ? 1 : 0) +
                                                (patient.objective ? 1 : 0) +
                                                (patient.assessment_and_plan ? 1 : 0) +
                                                (patient.conversation_title ? 1 : 0)) *
                                                20
                                            }%`,
                                            }}
                                        ></div>
                                        </div>
                                    </div>
                                    <span className="ml-2 text-xs text-gray-500">
                                        {Math.round(
                                        ((patient.transcript ? 1 : 0) +
                                            (patient.subjective ? 1 : 0) +
                                            (patient.objective ? 1 : 0) +
                                            (patient.assessment_and_plan ? 1 : 0) +
                                            (patient.conversation_title ? 1 : 0)) *
                                            20
                                        )}
                                        %
                                    </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

            </div>

            {/* Main content  */}
            {!displayingPatientNotes ? (<>
                
                {/* Main container */}
                <div className={`${patientsVisible ? "hidden sm:flex justify-start sm:justify-center sm:items-center sm:w-4/5 transition-opacity-transform" : "w-full sm:w-full flex justify-start sm:flex sm:justify-center sm:items-center transition-opacity-transform"} sm:h-full bg-background space-y-12`}>
                    
                    {/* Fixed Container */}
                    <div className={`space-y-8 flex flex-col items-center ${patientTypeSelected ?  "justify-start sm:justify-center" : "justify-center"  } w-full ${patientsVisible ? "sm:w-full" : "sm:w-full "} sm:min-w-[50%]  min-h-[90vh] max-h-[75%] p-5 rounded-3xl`} >
                        
                        {/* Input Container */}
                        <div className="flex flex-col justify-center items-center w-full mb-8">
                            
                            {!conversationInitialized ? 

                            // Start New Visit fields and buttons
                            (

                                // Start new visit input
                                <>
                                    <h3 className="text-4xl text-center font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary mb-2 fade-in-text">
                                        Welcome Back, {sessionStorage.getItem("firstName")}!
                                    </h3>
                                    <h2 className="text-textPrimary text-center text-xl font-semibold mb-6 fade-in-text">
                                        Who's the patient you're seeing today?
                                    </h2>

                                    <ThemeProvider theme={theme} >
                                        <div className="w-full flex justify-center max-w-md fade-in-text">
                                            <div className="flex justify-center w-full space-x-4">
                                                <div className="mb-6 w-3/4">
                                                <TextField
                                                    id="firstName"
                                                    label="Patient Name"
                                                    placeholder=" "
                                                    variant="outlined"
                                                    value={firstName}
                                                    onChange={(e) => {
                                                        setFirstName(e.target.value);
                                                        setNewPatientData({
                                                        first_name: e.target.value,
                                                        last_name: lastName,
                                                        nickname: nickName,
                                                        });
                                                    }}                                        
                                                    fullWidth
                                                    InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                        <FaUser className="text-gray-400" />
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    inputProps={{
                                                    maxLength: 50,
                                                    }}
                                                    className="rounded-md"
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    </ThemeProvider>

                                    {/* Start Button Logic */}
                                    <div className="flex flex-col items-center mt-8 fade-in-text">
                                        <button
                                            className={`p-5 rounded-full transition duration-300 ease-in-out ${
                                                (firstName || lastName)
                                                ? "bg-accent drop-shadow-2xl hover:drop-shadow-none"
                                                : "bg-darkerBackground opacity-50 cursor-not-allowed"
                                            } flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}
                                            disabled={!firstName}
                                            onClick={startConversationBasic}
                                        >
                                            <img src={logo2} alt="ChartMD Logo" className="h-36 w-36" />
                                        </button>
                                        <span className={`${(!firstName && !lastName) ? "hidden" : "flex"} text-gray-400 text-md mt-8 items-center`}>
                                            Click To Start Recording 
                                            {/* <FaPlay className="ml-2 text-sm" /> */}
                                        </span>

                                        <div className='mt-4 text-gray-400 text-md'>
                                            <p>or</p>
                                        </div>
                                        <div className="mt-4 text-gray-400 text-md">
                                            <Tooltip title={firstName ? "Click here to upload audio file" : "Please enter the patient's name to upload a file"}>
                                                <label htmlFor="audio-upload" className="flex items-center space-x-2 cursor-pointer">
                                                    <span className={`text-md ${firstName ? "underline text-gray-500" : "text-gray-200"}`}>
                                                        Upload Audio File
                                                    </span>
                                                    {/* <AiOutlineUpload className="text-lg" /> */}
                                                </label>
                                            </Tooltip>
                                            <input
                                                disabled={!firstName}
                                                type="file"
                                                id="audio-upload"
                                                className="hidden"
                                                accept="audio/*"
                                                onChange={handleAudioUpload}
                                            />
                                        </div>
                                    </div>
                                </>

                            ) 

                            : 

                            // Pause and End Visit Stuff
                            (
                                patientCompletedModalOpen ? 
                                (<>

                                    <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', gap: 2, zIndex: 50 }}>
                                        {/* Complete Visit Button */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<FaCheck />}
                                            sx={{
                                                px: 3,
                                                py: 2,
                                                background: 'linear-gradient(to right, #10b981, #059669)',
                                                borderRadius: '20px',
                                                boxShadow: 3,
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                    transition: 'transform 0.3s',
                                                },
                                                minWidth: "200px"
                                            }}
                                            onClick={() => {stopRecordingAndProcessAudioBasic(false); setPatientCompletedModalOpen(false);}}
                                        >
                                            <Typography fontWeight="bold" variant="h6">
                                                Complete Visit
                                            </Typography>
                                        </Button>

                                        {/* Not Yet Button */}
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<FaPlay />}
                                            sx={{
                                                px: 3,
                                                py: 2,
                                                background: 'linear-gradient(to right, #cbd5e1, #94a3b8)',
                                                borderRadius: '20px',
                                                boxShadow: 3,
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                    transition: 'transform 0.3s',
                                                },
                                                minWidth: "200px"
                                            }}
                                            onClick={() =>  {setPatientCompletedModalOpen(false); resumeRecording();}}
                                        >
                                            <Typography fontWeight="bold" variant="h6">
                                                Not Yet
                                            </Typography>
                                        </Button>
                                    </Box>

                                
                                </>) 
                                : 
                                (<>
                                
                                {/* Pause and end visit funtionality */}
                                <div className="flex flex-col items-center mt-8">

                                    {/* Recording button */}
                                    <RecordingButton isRecording={isRecording} isPaused={isPaused} logo={logo2}audioStream={audioStream} />

                                    {/* Activity Label (shows if not recording, recording or if paused) */}
                                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {isRecording && !isPaused && (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <CircularProgress size={20} color="error" sx={{ mr: 1 }} />
                                                <Typography variant="h6" color="error" fontWeight="bold">
                                                    Recording
                                                </Typography>
                                            </Box>
                                        )}
                                        {isRecording && isPaused && (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box
                                                    sx={{
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: '50%',
                                                    bgcolor: 'warning.main',
                                                    mr: 1,
                                                    }}
                                                />
                                                <Typography variant="h6" color="warning.main" fontWeight="bold">
                                                    Paused
                                                </Typography>
                                            </Box>
                                        )}
                                        {!isRecording && (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box
                                                    sx={{
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: '50%',
                                                    bgcolor: 'grey.400',
                                                    mr: 1,
                                                    }}
                                                />
                                                <Typography variant="h6" color="text.secondary" fontWeight="bold">
                                                    Not Recording
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>

                                    {/* Pause, Resume, & Complete Visit Buttons */}
                                    <Box sx={{ mt: 5, display: 'flex', gap: 2, zIndex: 50 }}>

                                        {/* Pause and Resume Buttons */}
                                        {isPaused ? (

                                            // Resume
                                            <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<FaPlay />}
                                            sx={{
                                                px: 3,
                                                py: 2,
                                                background: 'linear-gradient(to right, #3b82f6, #2563eb)',
                                                borderRadius: '20px',
                                                boxShadow: 3,
                                                '&:hover': {
                                                transform: 'scale(1.05)',
                                                transition: 'transform 0.3s',
                                                },
                                                minWidth:"200px"
                                            }}
                                            onClick={resumeRecording}
                                            >
                                            <Typography fontWeight="bold" variant="h6">
                                                Resume
                                            </Typography>
                                            </Button>

                                        ) : (

                                            // Pause
                                            <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<FaPause />}
                                            sx={{
                                                px: 3,
                                                py: 2,
                                                background: 'linear-gradient(to right, #3b82f6, #2563eb)',
                                                borderRadius: '20px',
                                                boxShadow: 3,
                                                '&:hover': {
                                                transform: 'scale(1.05)',
                                                transition: 'transform 0.3s',
                                                },
                                                minWidth:"200px"
                                            }}
                                            onClick={pauseRecording}
                                            >
                                                <Typography fontWeight="bold" variant="h6">
                                                    Pause
                                                </Typography>
                                            </Button>

                                        )}

                                        {/* Complete Visit Button */}
                                        <Button
                                            variant="contained"
                                            color="success"
                                            startIcon={<FaCheck />}
                                            sx={{
                                            px: 3,
                                            py: 2,
                                            background: 'linear-gradient(to right, #10b981, #059669)',
                                            borderRadius: '20px',
                                            boxShadow: 3,
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                transition: 'transform 0.3s',
                                            },
                                            minWidth:"200px"
                                            }}
                                            onClick={() => {pauseRecording(); stopRecordingAndProcessAudioBasic(true);}}
                                        >
                                            <Typography fontWeight="bold" variant="h6">
                                            Complete
                                            </Typography>
                                        </Button>

                                    </Box>

                                </div>
                                
                                </>)

                            )}

                        </div>

                        {/* Start Button and Recording Screen */}
                        <div className='w-full sm:w-full '>
                        </div>

                    </div>
                </div>
            </>) 

            :

            (<>
                {/* Patient Details Page */}
                <PatientDetails patientData={patientData} patientsVisible={patientsVisible} deletePatient={deletePatient} finalizeNotes={finalizeNotes} displayingPatientNotes={displayingPatientNotes} flagPatientVisit={flagPatientVisit} />
            </>)} 




            {/* Patient Completed Modal */}
            {/* <PatientCompletedModal patientData={newPatientData} isOpen={patientCompletedModalOpen} onClose={() => setPatientCompletedModalOpen(false)} onPause={pauseRecording} onResume={resumeRecording} onComplete={stopRecordingAndProcessAudioBasic} /> */}


            {/* Upload Audio States */}
            <AudioUploadModal isOpen={isAudioUploadModalOpen} onClose={() => setIsAudioUploadModalOpen(false)} audioFile={uploadedAudio} onProcess={processAudioFileBasic} patientName={newPatientData.first_name ? newPatientData.first_name : ""} sendingAudioToServer={sendingAudioToServer} />
            
            {/* Conversation Modal */}
            <ConversationModal
                isOpen={isConversationModalOpen}
                onClose={() => setIsConversationModalOpen(false)}
                text={conversation}
            />

            {/* Ask AI Modal */}
            {isAIEditModalOpen && noteType && (
                <AskAIModal
                isOpen={isAIEditModalOpen}
                onClose={() => toggleAIEditModal(noteType, false)}
                    currentNote={currentNote}
                    noteType={noteType}
                />
            )}

            {/* Transcript Modal */}
            <TranscriptDisplay 
                transcript={transcript} 
                timestampSelected={timestampSelected} 
                isRecording={isRecording} 
                isOpen={showTranscript} 
                onClose={() => setShowTranscript(false)} 
                summaries={summaries}
                onOpenCopilot={() => openAIAssistModal("Transcript", null)}
                stopRecording={stopRecording}
                startRecording={startRecording}
            />

            <ProfileModal isOpen={profileModalOpen} onClose={toggleProfileModal} firstName={userFirstName} />

            <Badge message={badgeMessage} isVisible={badgeVisible} onClose={() => setBadgeVisible(false)}/>

            <LoginModal isOpen={isLoginModalOpen} onClose={toggleLoginModal} />

        </div>
    </ThemeProvider>
  </>)
}

export default ScribePage;

{/* <button
    onClick={() => {
    setStartNewPatient(true);
    setPatientTypeSelected("New");
    }}
    style={{ animationDelay: '0.5s' }}
    className="bg-white hover:bg-gray-100 text-blue-600 sm:text-xl py-4 px-8 flex justify-center items-center rounded-md font-semibold shadow-lg hover:shadow-xl w-full max-w-md transform hover:-translate-y-1 transition duration-300 ease-in-out fade-in-text"
>
    <FaUserAlt className="mr-4 text-2xl" />
    New Patient
</button> */}
{/* <button
    onClick={() => {
    setStartNewPatient(false);
    setPatientTypeSelected("Existing");
    }}
    style={{ animationDelay: '0.8s' }}
    className="bg-gradient-to-r from-blue-700 to-blue-500 hover:from-blue-800 hover:to-blue-600 text-white sm:text-xl py-4 px-8 flex justify-center items-center rounded-md font-semibold shadow-lg hover:shadow-xl w-full max-w-md transform hover:-translate-y-1 transition duration-300 ease-in-out fade-in-text"
>
    <FaUserShield className="mr-4 text-2xl" />
    Existing Patient
</button> */}
// {conversationInitialized && (
//     <div className='w-full bg-blue-100 rounded relative'>
//         <div className="absolute top-0 left-0 p-4 sm:p-6">
//         <div className="hidden sm:flex items-center bg-blue-50 rounded-md shadow-md px-4 py-2">

//             <div className="flex items-center">
//                 <AiFillClockCircle className="text-2xl sm:text-xl text-blue-600 mr-2" />
//             <p className="text-gray-800 text-sm sm:text-base font-semibold">{formatTime(elapsedTime)}</p>
//             </div>

//             {conversationHeadline && (
//             <div className="ml-4 pl-4 border-l border-gray-300">
//                 <h3 className="text-sm sm:text-base font-semibold text-gray-600">{`Title: ${conversationHeadline}`}</h3>
//             </div>
//             )}
//         </div>
//         </div>

//         <button
//             onClick={cancelRecording}
//             className="absolute top-4 right-4 sm:top-6 sm:right-6 sm:bg-red-100 text-red-600 text-sm font-semibold sm:py-2 sm:px-4 rounded-md sm:shadow-md hover:bg-red-200 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center"
//             >
//             <FaTimes className="text-lg sm:mr-2" />
//             <span className='hidden sm:block'>Cancel</span>
//         </button>

//         {/* Continue, Complete, and View Conversation buttons */}
//         <div className="flex flex-col w-full">
//             <div className="flex justify-center items-center w-full bg-white py-5">
//                 <h3 className="text-blue-600 text-3xl font-semibold flex justify-center items-center flex-col">
//                     <span>{newPatientData.first_name} {newPatientData.last_name}</span>
//                     <span className='text-gray-500 text-sm'>{newPatientData.nickname && `Goes by "${newPatientData.nickname}"`}</span>
//                 </h3>
//             </div>
//             <div className='w-full flex justify-center sm:justify-center items-end px-2 bg-white text-gray-800'>
//                 <div className='flex flex-col justify-center items-center mr-3'>
//                     <button disabled={transcript.length === 0} onClick={() => setShowTranscript(true)} className={`text-white ${transcript.length === 0 ? "bg-gray-300" : "bg-blue-600 hover:bg-blue-700 transform hover:-translate-y-0.5"} sm:text-sm py-3 px-3 mx-2 flex justify-center items-center rounded-full shadow-md`}>
//                         <AiFillExperiment className='text-xl sm:text-3xl'/>
//                     </button>
//                     <p className='text-xs text-gray-500 mt-2'>Analysis</p>
//                 </div>
//                 <div className='flex flex-col justify-center items-center mr-3'>
//                     <button  className={`text-white bg-orange-600 hover:bg-orange-700 transform hover:-translate-y-0.5 sm:text-sm py-3 px-3 mx-2 flex justify-center items-center rounded-full shadow-md`}>
//                         <AiOutlineRobot className='text-xl sm:text-3xl'/>
//                     </button>
//                     <p className='text-xs text-gray-500 mt-2'>CoPilot</p>
//                 </div>
//             </div>

//             <div className="flex justify-center sm:justify-start items-center p-5 bg-white border-b-2">
//                 {conversationStarted ?
//                     (<>
//                         {isRecording ?
//                             <button onClick={stopRecording} className="bg-white hover:bg-gray-100 text-blue-600 sm:text-lg py-1 px-3 flex items-center rounded shadow-md transform hover:-translate-y-0.5 pulse">
//                                 <FaPauseCircle className='mr-3' /> Pause
//                             </button>
//                             :
//                             <button onClick={startRecording} className="bg-blue-600 hover:bg-blue-700 text-white sm:text-lg py-1 px-3 mx-1 flex justify-center items-center rounded shadow-md transform hover:-translate-y-0.5">
//                                 <FaPlay className='mr-3'/>Continue
//                             </button>
//                         }
//                         <button onClick={completeRecording} className="bg-green-500 hover:bg-green-700 text-white sm:text-lg py-1 px-3 mx-3 flex justify-center items-center rounded shadow-md transform hover:-translate-y-0.5">
//                             <FaCheck className='mr-3'/>Complete
//                         </button>
//                         {isRecording && (<>
//                             <canvas id="audioVisual" className="w-full h-12 rounded-xl hidden sm:block"></canvas>
//                         </>)}
//                     </>)  
//                     :
//                     (<>
//                         <button onClick={startRecording} className="bg-blue-600 hover:bg-blue-700 text-white sm:text-lg py-1 px-5 mx-1 flex justify-center items-center rounded shadow-md transform hover:-translate-y-0.5 drop-in">
//                             <FaPlay className='mr-3'/> Start
//                         </button>
//                     </>)}
//             </div>

//             {/*Fields*/}
//             <div className='w-full bg-white flex flex-col py-5 px-1 min-h-[60vh] overflow-y-auto rounded-b-3xl'>

//                 {/* Subjective Section */}
//                 <div className="">
//                     <h4 className={`w-full p-5 ${subjectiveFinalized ? "bg-green-50" : isOpen.subjective ? 'bg-slate-200' : 'hover:bg-slate-100 '} cursor-pointer flex items-center justify-between font-semibold text-lg`} onClick={() => toggleSection('subjective')}>
//                         <span className='flex items-center'><span className={`mr-2 text-2xl ${subjectiveFinalized ? "text-green-500" : "text-gray-500 hover:text-green-500" } text-gray-500 hover:text-green-500 cursor-pointer`}>{subjectiveFinalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("subjective")}}  /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("subjective")}}  /> }</span>Subjective</span>
//                         {isOpen.subjective ? <FaChevronUp /> : <FaChevronDown />}
//                     </h4>
//                     {/* The accordian need to start on this div */}
//                     <div className={`accordion-content flex ${isOpen.subjective ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}> 
//                         <div className={`w-8/12 border-2 border-t-0 p-2 flex flex-col justify-between ${subjectiveLocked && "fade-in-bg-field"}`}>
//                             {subjectiveLocked || !subjectiveEditing || subjectiveFinalized? (
//                                 <textarea disabled className={`focus:outline-none  ${subjectiveLocked ? "fade-in-bg-field" : "bg-white"} `} rows={8} value={subjective}/>
//                                 ) : (<>
//                                 <textarea autoFocus className='focus:outline-none bg-gray-50' onChange={(e) => setSubjective(e.target.value)} rows={8} value={subjective}/>
//                             </>)}
//                             <div className='flex justify-between mt-4'>
//                                 <div className="flex flex-col items-center">
//                                     <button onClick={() => copyToClipboard(subjective, "Subjective")} className='bg-gray-100 hover:bg-gray-300 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                         <FaCopy className='sm:mr-1' /> <span className='hidden sm:block'>{copyDisplayText.toLowerCase().includes("subjective") ? copyDisplayText : "Copy"}</span>
//                                     </button>
//                                 </div>
//                                 <div className='space-x-1 sm:space-x-2 flex items-center'>
//                                     {subjectiveFinalized ? 
//                                         (<>
//                                             <button disabled className='border-[1px] border-gray-300 bg-green-100 text-green-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                 <AiOutlineCheck className='mr-2' /> Finalized
//                                             </button>
//                                         </>) 
//                                     : 
//                                         (<>
//                                             {!subjectiveLocked && (<>
//                                                 {subjectiveEditing ? (<>
//                                                     <button onClick={() => {saveNotes("subjective", subjective); setSubjectiveEditing(!subjectiveEditing);}}
//                                                         className='border-[1px] border-gray-300 bg-green-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'
//                                                         >
//                                                         <FaSave className='sm:mr-2' /> <span className='hidden sm:block'>Save</span>
//                                                     </button>
//                                                 </>): (<>
//                                                     <button onClick={() => setSubjectiveEditing(!subjectiveEditing)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                         <FaEdit className='sm:mr-2' /> <span className='hidden sm:block'>Edit</span>
//                                                     </button>
//                                                 </>)}
//                                             </>)}
//                                                 {subjectiveLocked ? (<>
//                                                     <button onClick={() => setSubjectiveLocked(!subjectiveLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                         <FaLock className='sm:mr-2' /> <span className='hidden sm:block'>Locked</span>
//                                                     </button>
//                                                 </>) : (<>
//                                                     <button onClick={() => setSubjectiveLocked(!subjectiveLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                         <FaLockOpen className='sm:mr-2' /> <span className='hidden sm:block'>Unlocked</span>
//                                                     </button>
//                                             </>)}
//                                         </>)}
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='w-4/12 border-2 border-t-0 sm:p-2 flex flex-col sm:flex-row justify-center items-center sm:items-end space-y-2 sm:space-x-3 sm:space-y-0'>
//                             <div className="flex flex-col items-center">
//                                 <button onClick={() => openAIAssistModal("subjective", subjective)} className='bg-orange-400 hover:bg-orange-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaQuestionCircle className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Ask AI</span>
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <button className='bg-purple-400 hover:bg-purple-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaMicrophone className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Dictate</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Objective Section */}
//                 <div className="">
//                     <h4 className={`w-full p-5 ${objectiveFinalized ? "bg-green-50" : isOpen.objective ? 'bg-slate-200' : 'hover:bg-slate-100 '} cursor-pointer flex items-center justify-between font-semibold text-lg`} onClick={() => toggleSection('objective')}>
//                         <span className='flex items-center'> <span className={`mr-2 text-2xl ${objectiveFinalized ? "text-green-500" : "text-gray-500 hover:text-green-500" } text-gray-500 hover:text-green-500 cursor-pointer`}>{objectiveFinalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("objective")}}  /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("objective")}}  /> }</span>Objective</span>
//                         {isOpen.objective ? <FaChevronUp /> : <FaChevronDown />}
//                     </h4>
//                     <div className={`accordion-content flex ${isOpen.objective ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}> 
//                         <div className={`w-8/12 border-2 border-t-0 p-2 flex flex-col justify-between ${objectiveLocked && "fade-in-bg-field"}`}>
//                             {objectiveLocked || !objectiveEditing || objectiveFinalized ? (
//                                 <textarea disabled className={`focus:outline-none  ${objectiveLocked ? "fade-in-bg-field" : "bg-white"} `} rows={8} value={objective}/>
//                                 ) : (<>
//                                 <textarea autoFocus className='focus:outline-none bg-gray-50' onChange={(e) => setObjective(e.target.value)} rows={8} value={objective}/>
//                             </>)}                                                    
//                             <div className='flex justify-between mt-4'>
//                                 <div className="flex flex-col items-center">
//                                     <button onClick={() => copyToClipboard(objective, "Objective")} className='bg-gray-100 hover:bg-gray-300 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                         <FaCopy className='mr-1' /> {copyDisplayText.toLowerCase().includes("objective") ? copyDisplayText : "Copy"}
//                                     </button>
//                                 </div>
//                                 <div className='space-x-1 sm:space-x-2 flex items-center'>
//                                     {objectiveFinalized ? 
//                                         (<>
//                                             <button disabled className='border-[1px] border-gray-300 bg-green-100 text-green-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                 <AiOutlineCheck className='mr-2' /> Finalized
//                                             </button>
//                                         </>) 
//                                     : 
//                                         (<>
//                                             {!objectiveLocked && (<>    
//                                                 {objectiveEditing ? (<>
//                                                     <button onClick={() => { setObjectiveEditing(!objectiveEditing); saveNotes("objective", objective)}} className='border-[1px] border-gray-300 bg-green-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                         <FaSave className='mr-2' /> Save
//                                                     </button>
//                                                     </>): (<>
//                                                         <button onClick={() => setObjectiveEditing(!objectiveEditing)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                             <FaEdit className='mr-2' /> Edit
//                                                         </button>
//                                                     </>)}
//                                                 </>)}
//                                                     {objectiveLocked ? (<>
//                                                         <button onClick={() => setObjectiveLocked(!objectiveLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                             <FaLock className='mr-2' /> Locked
//                                                         </button>
//                                                     </>) : (<>
//                                                         <button onClick={() => setObjectiveLocked(!objectiveLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                             <FaLockOpen className='mr-2' /> Unlocked
//                                                         </button>
//                                                 </>)}
//                                         </>) 
//                                     }

//                                 </div>
//                             </div>
//                         </div>
//                         <div className='w-4/12 border-2 border-t-0 sm:p-2 flex flex-col sm:flex-row justify-center items-center sm:items-end space-y-2 sm:space-x-3 sm:space-y-0'>
//                             <div className="flex flex-col items-center">
//                                 <button onClick={() => openAIAssistModal("objective", objective)} className='bg-orange-400 hover:bg-orange-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaQuestionCircle className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Ask AI</span>
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <button className='bg-purple-400 hover:bg-purple-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaMicrophone className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Dictate</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Assessment & Plan Section */}
//                 <div className="">
//                     <h4 className={`w-full p-5 ${assessmentAndPlanFinalized ? "bg-green-50" : isOpen.assessment_and_plan ? 'bg-slate-200' : 'hover:bg-slate-100 '} cursor-pointer flex items-center justify-between font-semibold text-lg`} onClick={() => toggleSection('assessment_and_plan')}>
//                         <span className='flex items-center'><span className={`mr-2 text-2xl ${assessmentAndPlanFinalized ? "text-green-500" : "text-gray-500 hover:text-green-500" } text-gray-500 hover:text-green-500 cursor-pointer`}>{assessmentAndPlanFinalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("assessment_and_plan")}}  /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("assessment_and_plan")}}  /> }</span>Assessment & Plan </span>
//                         {isOpen.assessment_and_plan ? <FaChevronUp /> : <FaChevronDown />}
//                     </h4>
//                     <div className={`accordion-content flex ${isOpen.assessment_and_plan ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}> 
//                         <div className={`w-8/12 border-2 border-t-0 p-2 flex flex-col justify-between ${assessmentAndPlanLocked && "fade-in-bg-field"}`}>
//                             {assessmentAndPlanLocked || !assessmentAndPlanEditing || assessmentAndPlanFinalized ? (
//                                 <textarea disabled className={`focus:outline-none  ${assessmentAndPlanLocked ? "fade-in-bg-field" : "bg-white"} `} rows={12} value={assessment_and_plan}/>
//                                 ) : (<>
//                                 <textarea autoFocus className='focus:outline-none bg-gray-50' onChange={(e) => setAssessment_and_plan(e.target.value)} rows={12} value={assessment_and_plan}/>
//                             </>)}                                                     
//                             <div className='flex justify-between mt-4'>
//                                 <div className="flex flex-col items-center">
//                                     <button onClick={() => copyToClipboard(assessment_and_plan, "Assessment")} className='bg-gray-100 hover:bg-gray-300 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                         <FaCopy className='mr-1' /> {copyDisplayText.toLowerCase().includes("assessment") ? copyDisplayText : "Copy"}
//                                     </button>
//                                 </div>
//                                 <div className='space-x-1 sm:space-x-2 flex items-center'>
//                                 {assessmentAndPlanFinalized ? 
//                                     (<>
//                                         <button disabled className='border-[1px] border-gray-300 bg-green-100 text-green-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                             <AiOutlineCheck className='mr-2' /> Finalized
//                                         </button>
//                                     </>) 
//                                 : 
//                                     (<>
//                                         {!assessmentAndPlanLocked && (<>
//                                             {assessmentAndPlanEditing ? (<>
//                                                 <button onClick={() => {saveNotes("assessment_and_plan", assessment_and_plan); setAssessmentAndPlanEditing(!assessmentAndPlanEditing);}} className='border-[1px] border-gray-300 bg-green-100 hover:bg-green-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                     <FaSave className='mr-2' /> Save
//                                                 </button>
//                                             </>): (<>
//                                                 <button onClick={() => setAssessmentAndPlanEditing(!assessmentAndPlanEditing)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                     <FaEdit className='mr-2' /> Edit
//                                                 </button>
//                                             </>)}
//                                         </>)}
//                                         {assessmentAndPlanLocked ? (<>
//                                             <button onClick={() => setAssessmentAndPlanLocked(!assessmentAndPlanLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                 <FaLock className='mr-2' /> Locked
//                                             </button>
//                                         </>) : (<>
//                                             <button onClick={() => setAssessmentAndPlanLocked(!assessmentAndPlanLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                 <FaLockOpen className='mr-2' /> Unlocked
//                                             </button>
//                                         </>)}
//                                     </>)}

//                                 </div>
//                             </div>
//                         </div>
//                         <div className='w-4/12 border-2 border-t-0 sm:p-2 flex flex-col sm:flex-row justify-center items-center sm:items-end space-y-2 sm:space-x-3 sm:space-y-0'>
//                             <div className="flex flex-col items-center">
//                                 <button onClick={() => openAIAssistModal("assessment_and_plan", assessment_and_plan)} className='bg-orange-400 hover:bg-orange-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaQuestionCircle className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Ask AI</span>
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <button className='bg-purple-400 hover:bg-purple-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaMicrophone className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Dictate</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Encounter Section */}
//                 <div className="">
//                     <h4 className={`w-full p-5 ${encounterFinalized ? "bg-green-50" : isOpen.encounter ? 'bg-slate-200' : 'hover:bg-slate-100 '} cursor-pointer flex items-center justify-between font-semibold text-lg`} onClick={() => toggleSection('encounter')}>
//                         <span className='flex items-center'><span className={`mr-2 text-2xl ${encounterFinalized ? "text-green-500" : "text-gray-500 hover:text-green-500" } text-gray-500 hover:text-green-500 cursor-pointer`}>{encounterFinalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("encounter")}}  /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("encounter")}}  /> }</span>Encounter</span>
//                         {isOpen.encounter ? <FaChevronUp /> : <FaChevronDown />}
//                     </h4>
//                     <div className={`accordion-content flex ${isOpen.encounter ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}> 
//                         <div className={`w-8/12 border-2 border-t-0 p-2 flex flex-col justify-between ${encounterLocked && "fade-in-bg-field"}`}>
//                             {encounterLocked || !encounterEditing || encounterFinalized ? (
//                                 <textarea disabled className={`focus:outline-none  ${encounterLocked ? "fade-in-bg-field" : "bg-white"} `} rows={8} value={encounter}/>
//                                 ) : (<>
//                                 <textarea autoFocus className='focus:outline-none bg-gray-50' onChange={(e) => setEncounter(e.target.value)} rows={8} value={encounter}/>
//                             </>)} 
//                             <div className='flex justify-between mt-4'>
//                                 <div className="flex flex-col items-center">
//                                     <button onClick={() => copyToClipboard(encounter, "Encounter")} className='bg-gray-100 hover:bg-gray-300 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                         <FaCopy className='mr-1' /> {copyDisplayText.toLowerCase().includes("plan") ? copyDisplayText : "Copy"}
//                                     </button>
//                                 </div>
//                                 <div className='space-x-1 sm:space-x-2 flex items-center'>
//                                     {encounterFinalized ? 
//                                         (<> 
//                                             <button disabled className='border-[1px] border-gray-300 bg-green-100 text-green-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                 <AiOutlineCheck className='mr-2' /> Finalized
//                                             </button>
//                                         </>) 
//                                     : 
//                                         (<>
//                                         {!encounterLocked && (<>        
//                                             {encounterEditing ? (<>
//                                                 <button onClick={() => {saveNotes("encounter", encounter); setEncounterEditing(!encounterEditing);}} className='border-[1px] border-gray-300 bg-green-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                     <FaSave className='mr-2' /> Save
//                                                 </button>
//                                             </>): (<>
//                                                 <button onClick={() => setEncounterEditing(!encounterEditing)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                     <FaEdit className='mr-2' /> Edit
//                                                 </button>
//                                             </>)}
//                                         </>)}
//                                             {encounterLocked ? (<>
//                                                 <button onClick={() => setEncounterLocked(!encounterLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                     <FaLock className='mr-2' /> Locked
//                                                 </button>
//                                             </>) : (<>
//                                                 <button onClick={() => setEncounterLocked(!encounterLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                     <FaLockOpen className='mr-2' /> Unlocked
//                                                 </button>
//                                             </>)}
//                                                 </>)
//                                         }
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='w-4/12 border-2 border-t-0 sm:p-2 flex flex-col sm:flex-row justify-center items-center sm:items-end space-y-2 sm:space-x-3 sm:space-y-0'>
//                             <div className="flex flex-col items-center">
//                                 <button onClick={() => openAIAssistModal("encounter", encounter)} className='bg-orange-400 hover:bg-orange-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaQuestionCircle className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Ask AI</span>
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <button className='bg-purple-400 hover:bg-purple-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaMicrophone className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Dictate</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Medications Section */}
//                 <div className="">
//                     <h4 className={`w-full p-5 ${medicationsFinalized ? "bg-green-50" : isOpen.medications ? 'bg-slate-200' : 'hover:bg-slate-100 '} cursor-pointer flex items-center justify-between font-semibold text-lg`} onClick={() => toggleSection('medications')}>
//                         <span className='flex items-center'><span className={`mr-2 text-2xl ${medicationsFinalized ? "text-green-500" : "text-gray-500 hover:text-green-500" } text-gray-500 hover:text-green-500 cursor-pointer`}>{medicationsFinalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("medications")}}  /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("medications")}}  /> }</span>Medications</span>
//                         {isOpen.medications ? <FaChevronUp /> : <FaChevronDown />}
//                     </h4>
//                     <div className={`accordion-content flex ${isOpen.medications ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}> 
//                         <div className={`w-8/12 border-2 border-t-0 p-2 flex flex-col justify-between ${medicationsLocked && "fade-in-bg-field"}`}>
//                             {medicationsLocked || !medicationsEditing || medicationsFinalized ? (
//                                 <textarea disabled className={`focus:outline-none  ${medicationsLocked ? "fade-in-bg-field" : "bg-white"} `} rows={8} value={medications}/>
//                                 ) : (<>
//                                 <textarea autoFocus className='focus:outline-none bg-gray-50' onChange={(e) => setMedications(e.target.value)} rows={8} value={medications}/>
//                             </>)}
//                             <div className='flex justify-between mt-4'>
//                                 <div className="flex flex-col items-center">
//                                     <button onClick={() => copyToClipboard(medications, "Medications")} className='bg-gray-100 hover:bg-gray-300 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                         <FaCopy className='mr-1' /> {copyDisplayText.toLowerCase().includes("plan") ? copyDisplayText : "Copy"}
//                                     </button>
//                                 </div>
//                                 <div className='space-x-1 sm:space-x-2 flex items-center'>
//                                 {medicationsFinalized ? 
//                                     (<>
//                                         <button disabled className='border-[1px] border-gray-300 bg-green-100 text-green-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                             <AiOutlineCheck className='mr-2' /> Finalized
//                                         </button>
//                                     </>) 
//                                 : 
//                                     (<>
//                                         {!medicationsLocked && (<>
//                                             {medicationsEditing ? (<>
//                                                 <button onClick={() => {saveNotes("medications", medications); setMedicationsEditing(!medicationsEditing);;}} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                     <FaSave className='mr-2' /> Save
//                                                 </button>
//                                             </>): (<>
//                                                 <button onClick={() => setMedicationsEditing(!medicationsEditing)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                     <FaEdit className='mr-2' /> Edit
//                                                 </button>
//                                             </>)}
//                                         </>)}
//                                             {medicationsLocked ? (<>
//                                                 <button onClick={() => setMedicationsLocked(!medicationsLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                     <FaLock className='mr-2' /> Locked
//                                                 </button>
//                                             </>) : (<>
//                                                 <button onClick={() => setMedicationsLocked(!medicationsLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                     <FaLockOpen className='mr-2' /> Unlocked
//                                                 </button>
//                                             </>)}
//                                         </>)}
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='w-4/12 border-2 border-t-0 sm:p-2 flex flex-col sm:flex-row justify-center items-center sm:items-end space-y-2 sm:space-x-3 sm:space-y-0'>
//                             <div className="flex flex-col items-center">
//                                 <button onClick={() => openAIAssistModal("medications", medications)} className='bg-orange-400 hover:bg-orange-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaQuestionCircle className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Ask AI</span>
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <button className='bg-purple-400 hover:bg-purple-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaMicrophone className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Dictate</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Labs Section */}
//                 <div className="">
//                     <h4 className={`w-full p-5 ${labsFinalized ? "bg-green-50" : isOpen.labs ? 'bg-slate-200' : 'hover:bg-slate-100 '} cursor-pointer flex items-center justify-between font-semibold text-lg`} onClick={() => toggleSection('labs')}>
//                         <span className='flex items-center'><span className={`mr-2 text-2xl ${labsFinalized ? "text-green-500" : "text-gray-500 hover:text-green-500" } text-gray-500 hover:text-green-500 cursor-pointer`}>{labsFinalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("labs")}}  /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("labs")}}  /> }</span>Labs</span>
//                         {isOpen.labs ? <FaChevronUp /> : <FaChevronDown />}
//                     </h4>
//                     <div className={`accordion-content flex ${isOpen.labs ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}> 
//                         <div className={`w-8/12 border-2 border-t-0 p-2 flex flex-col justify-between ${labsLocked && "fade-in-bg-field"}`}>
//                             {labsLocked || !labsEditing || labsFinalized ? (
//                                 <textarea disabled className={`focus:outline-none  ${labsLocked ? "fade-in-bg-field" : "bg-white"} `} rows={8} value={labs}/>
//                                 ) : (<>
//                                 <textarea autoFocus className='focus:outline-none bg-gray-50' onChange={(e) => setLabs(e.target.value)} rows={8} value={labs}/>
//                             </>)} 
//                             <div className='flex justify-between mt-4'>
//                                 <div className="flex flex-col items-center">
//                                     <button onClick={() => copyToClipboard(labs, "Labs")} className='bg-gray-100 hover:bg-gray-300 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                         <FaCopy className='mr-1' /> {copyDisplayText.toLowerCase().includes("plan") ? copyDisplayText : "Copy"}
//                                     </button>
//                                 </div>
//                                 <div className='space-x-1 sm:space-x-2 flex items-center'>
//                                     {labsFinalized ? 
//                                         (<>
//                                             <button disabled className='border-[1px] border-gray-300 bg-green-100 text-green-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                 <AiOutlineCheck className='mr-2' /> Finalized
//                                             </button>
//                                         </>) 
//                                     : 
//                                         (<>
//                                             {!labsLocked && (<>
//                                                 {labsEditing ? (<>
//                                                     <button onClick={() => {saveNotes("labs", labs); setLabsEditing(!labsEditing);}} className='border-[1px] border-gray-300 bg-green-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                         <FaSave className='mr-2' /> Save
//                                                     </button>
//                                                 </>): (<>
//                                                     <button onClick={() => setLabsEditing(!labsEditing)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                         <FaEdit className='mr-2' /> Edit
//                                                     </button>
//                                                 </>)}
//                                             </>)}
//                                             {labsLocked ? (<>
//                                                 <button onClick={() => setLabsLocked(!labsLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                     <FaLock className='mr-2' /> Locked
//                                                 </button>
//                                             </>) : (<>
//                                                 <button onClick={() => setLabsLocked(!labsLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                     <FaLockOpen className='mr-2' /> Unlocked
//                                                 </button>
//                                             </>)}
//                                         </>
//                                     )}

//                                 </div>
//                             </div>
//                         </div>
//                         <div className='w-4/12 border-2 border-t-0 sm:p-2 flex flex-col sm:flex-row justify-center items-center sm:items-end space-y-2 sm:space-x-3 sm:space-y-0'>
//                             <div className="flex flex-col items-center">
//                                 <button onClick={() => openAIAssistModal("labs", labs)} className='bg-orange-400 hover:bg-orange-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaQuestionCircle className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Ask AI</span>
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <button className='bg-purple-400 hover:bg-purple-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaMicrophone className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Dictate</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Refferals Section */}
//                 <div className="">
//                     <h4 className={`w-full p-5 ${referralsFinalized ? "bg-green-50" : isOpen.referrals ? 'bg-slate-200' : 'hover:bg-slate-100 '} cursor-pointer flex items-center justify-between font-semibold text-lg`} onClick={() => toggleSection('referrals')}>
//                         <span className='flex items-center'><span className={`mr-2 text-2xl ${referralsFinalized ? "text-green-500" : "text-gray-500 hover:text-green-500" } text-gray-500 hover:text-green-500 cursor-pointer`}>{referralsFinalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("referrals")}}  /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNotes("referrals")}}  /> }</span>Referrals</span>
//                         {isOpen.referrals ? <FaChevronUp /> : <FaChevronDown />}
//                     </h4>
//                     <div className={`accordion-content flex ${isOpen.referrals ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}> 
//                         <div className={`w-8/12 border-2 border-t-0 p-2 flex flex-col justify-between ${referralsLocked  && "fade-in-bg-field"}`}>
//                             {referralsLocked || !referralsEditing || referralsFinalized ? (
//                                     <textarea
//                                         disabled
//                                         className={`focus:outline-none ${referralsLocked ? "fade-in-bg-field" : "bg-white"}`}
//                                         rows={8}
//                                         value={referrals}
//                                     />
//                                 ) : (
//                                     <>
//                                         <textarea
//                                             autoFocus
//                                             className="focus:outline-none bg-gray-50"
//                                             rows={8}
//                                             value={referrals}
//                                             onChange={(e) => setReferrals(e.target.value)} 
//                                         />
//                                     </>
//                                 )}
//                             <div className='flex justify-between mt-4'>
//                                 <div className="flex flex-col items-center">
//                                     <button onClick={() => copyToClipboard(referrals, "Referrals")} className='bg-gray-100 hover:bg-gray-300 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                         <FaCopy className='mr-1' /> {copyDisplayText.toLowerCase().includes("plan") ? copyDisplayText : "Copy"}
//                                     </button>
//                                 </div>
//                                 <div className='space-x-1 sm:space-x-2 flex items-center'>
//                                     {referralsFinalized ? 
//                                         (<>
//                                             <button disabled className='border-[1px] border-gray-300 bg-green-100 text-green-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                 <AiOutlineCheck className='mr-2' /> Finalized
//                                             </button>
//                                         </>) 
//                                         : 
//                                         (<>
//                                             {!referralsLocked && (<>
//                                                 {referralsEditing ? (<>
//                                                     <button onClick={() => {saveNotes("referrals", referrals); setReferralsEditing(!referralsEditing)}} className='border-[1px] border-gray-300 bg-green-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                         <FaSave className='mr-2' /> Save
//                                                     </button>
//                                                 </>): (<>
//                                                     <button onClick={() => setReferralsEditing(!referralsEditing)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center fade-in-header-quick'>
//                                                         <FaEdit className='mr-2' /> Edit
//                                                     </button>
//                                                 </>)}
//                                             </>)}
//                                                 {referralsLocked ? (<>
//                                                     <button onClick={() => setReferralsLocked(!referralsLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                         <FaLock className='mr-2' /> Locked
//                                                     </button>
//                                                 </>) : (<>
//                                                     <button onClick={() => setReferralsLocked(!referralsLocked)} className='border-[1px] border-gray-300 bg-gray-100 hover:bg-gray-200 text-gray-800 px-3 py-1 rounded text-xs shadow-sm flex items-center'>
//                                                         <FaLockOpen className='mr-2' /> Unlocked
//                                                     </button>
//                                                 </>)}
//                                             </>)}
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='w-4/12 border-2 border-t-0 sm:p-2 flex flex-col sm:flex-row justify-center items-center sm:items-end space-y-2 sm:space-x-3 sm:space-y-0'>
//                             <div className="flex flex-col items-center">
//                                 <button onClick={() => openAIAssistModal("referrals", referrals)} className='bg-orange-400 hover:bg-orange-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaQuestionCircle className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Ask AI</span>
//                             </div>
//                             <div className="flex flex-col items-center">
//                                 <button className='bg-purple-400 hover:bg-purple-500 text-gray-800 p-3 rounded-full flex items-center justify-center shadow-sm'>
//                                     <FaMicrophone className='text-md sm:text-sm text-white' />
//                                 </button>
//                                 <span className="text-xs mt-1 text-gray-500">Dictate</span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         </div>
//     </div>
// )}


{/* New Patient Form */}
{/* {patientTypeSelected === "New" && !conversationInitialized && (
<div className="flex flex-col items-center w-full sm:w-4/12 p-8 sm:p-12 mt-5 fade-in-header rounded-xl bg-white shadow-lg border-t-4 border-blue-500">
    <div className="flex justify-start items-center w-full mb-8">
        <button
        onClick={() => {
            clearNameData();
            setPatientTypeSelected(null);
        }}
        className="bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4 flex justify-center items-center rounded-md transition duration-300"
        >
        <FaArrowCircleLeft className="mr-2" /> Go Back
        </button>
    </div>
    <h2 className="text-gray-800 text-2xl font-semibold mb-6 flex items-center">
        Enter New Patient Information
    </h2>
    <div className="w-full mb-6 relative">
        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
        First Name
        </label>
        <div className="relative">
        <input
            type="text"
            id="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => {
            setFirstName(e.target.value);
            setNewPatientData({
                first_name: `${e.target.value}`,
                last_name: lastName,
                nickname: nickName,
            });
            }}
            className="w-full px-4 py-3 pr-10 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
            <FaUser className="absolute right-3 top-4 text-gray-400" />
        </div>
    </div>
    <div className="w-full mb-6 relative">
        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
        Last Name
        </label>
        <div className="relative">
        <input
            type="text"
            id="lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => {
            setLastName(e.target.value);
            setNewPatientData({
                last_name: `${e.target.value}`,
                first_name: firstName,
                nickname: nickName,
            });
            }}
            className="w-full px-4 py-3 pr-10 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <FaUser className="absolute right-3 top-4 text-gray-400" />
        </div>
    </div>
    <div className="w-full relative">
        <label htmlFor="nickName" className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
        Nick Name (optional)
        </label>
        <div className="relative">
        <input
            type="text"
            id="nickName"
            placeholder="Nick Name"
            value={nickName}
            onChange={(e) => {
            setNickName(e.target.value);
            setNewPatientData((prev) => ({ ...prev, nickname: e.target.value }));
            }}
            className="w-full px-4 py-3 pr-10 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
            <FaTag className="absolute right-3 top-4 text-gray-400" />
        </div>
    </div>
</div>
)} */}

{/* Existing Patient Form */}
{/* {patientTypeSelected === "Existing" && !conversationInitialized && (
<>
    <div className="flex flex-col items-center w-full mt-5 fade-in-header">
    <div className={`flex justify-start items-center w-full sm:w-2/4 mb-5 ${Object.keys(newPatientData).length > 0 && "hidden"}`}>
        <button
        onClick={() => {
            clearNameData();
            setPatientTypeSelected(null);
        }}
        className="bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4 flex justify-center items-center rounded-md transition duration-300"
        >
        <FaArrowCircleLeft className="mr-2" /> Go Back
        </button>
    </div>
    <div className={`flex justify-between items-center w-full sm:w-2/4 mb-4 ${Object.keys(newPatientData).length > 0 && "hidden"}`}>
        <p className="text-gray-800 font-semibold flex items-center">
        <FaUserFriends className="mr-2 text-blue-500" /> Select Patient
        </p>
    </div>
    <div className={`relative w-full sm:w-2/4 ${Object.keys(newPatientData).length > 0 && "hidden"}`}>
        <div className="relative">
        <input
            type="text"
            placeholder="Search Patient"
            value={newPatientData.first_name ? `${newPatientData.first_name} ${newPatientData.last_name}` : filter}
            className="w-full pl-4 pr-10 py-3 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => {
            setFilter(e.target.value);
            // Assuming you want to keep the structure of newPatientData but clear the name
            // setNewPatientData(prev => ({ ...prev, name: e.target.value }));
            }}
        />
        <FaSearch className="absolute right-3 top-4 text-gray-400" />
        </div>
        {(newPatientData && newPatientData.first_name) || filter ? (
        <button
            onClick={() => {
            setNewPatientData({}); // Clear newPatientData
            setFilter(""); // Clear filter if needed
            }}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700 transition duration-300"
        >
            <FaTimes className="h-5 w-5" />
        </button>
        ) : null}
    </div>
    <div
        className={`w-full sm:w-2/4 mt-4 flex flex-col justify-start items-center border border-gray-300 rounded-md shadow-md overflow-auto max-h-80 ${
            Object.keys(newPatientData).length > 0 && "hidden"
        }`}
        >
        {[...patients]
            .filter((patient) => patient.first_name.toLowerCase().includes(filter.toLowerCase()))
            .reverse()
            .map((filteredPatient, index) => (
            <div
                key={index}
                className="px-4 py-3 w-full sm:w-full bg-white border-b border-gray-200 last:border-b-0 hover:bg-blue-50 cursor-pointer transition duration-300 ease-in-out flex items-center"
                onClick={() => {
                setNewPatientData(filteredPatient);
                setActivePatientId(filteredPatient.id);
                setFirstName(filteredPatient.first_name);
                setLastName(filteredPatient.last_name);
                setNickName(filteredPatient.nickname);
                }}
            >
                <div className="flex-grow">
                <p className="text-gray-800 font-semibold">
                    {filteredPatient.first_name} {filteredPatient.last_name}
                </p>
                <p className="text-xs text-gray-500 mt-1">
                    {new Date(filteredPatient.recording_date).toLocaleString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                    })}
                </p>
                </div>
                <FaChevronRight className="text-gray-400" />
            </div>
            ))}
        </div>

        {Object.keys(newPatientData).length > 0 && (<>
            <div className="flex flex-col items-center w-2/4 mt-5 space-y-6 bg-white py-10 rounded shadow-lg relative">
                <div className="flex justify-center items-center w-full mb-4">
                    <p className="text-blue-800 text-xl font-semibold">Selected Patient</p>
                </div>
                <div className="flex flex-col items-center w-full">
                    <div className="bg-blue-100 rounded-full p-8">
                    <div className="flex justify-center items-center">
                        <FaUserAlt size="60" className="text-blue-600" />
                    </div>
                    </div>
                    <p className="text-blue-600 text-2xl font-bold mt-4">
                    {newPatientData.first_name} {newPatientData.last_name}
                    </p>
                    <div className="mt-6">
                    <p className="text-gray-600 text-md font-semibold">
                        <span className="font-bold">Last Seen On:</span>
                    </p>
                    <p className="text-gray-600 text-lg font-semibold">
                        {new Date(newPatientData.recording_date).toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                        })}
                    </p>
                    </div>
                </div>
                <div className="absolute top-0 right-4">
                    <FaRegTimesCircle
                    size="24"
                    className="text-blue-600 cursor-pointer transition duration-300 ease-in-out hover:text-blue-800"
                    onClick={() => {
                        clearNameData();
                        setFilter('');
                        setNewPatientData({});
                        setPatientTypeSelected(null);
                    }}
                    />
                </div>
            </div>
        </>)}
    </div>
</>)} */}

{/* <div className="mb-6 w-1/2">
    <div className="relative">
        <input
        type="text"
        id="lastName"
        placeholder=" "
        value={lastName}
        onChange={(e) => {
            setLastName(e.target.value);
            setNewPatientData({
            last_name: e.target.value,
            first_name: firstName,
            nickname: nickName,
            });
        }}
        className="w-full px-4 py-3 pt-6 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 peer"
        />
        <label
        htmlFor="lastName"
        className="absolute left-4 top-2 text-blue-500 text-xs transition-all duration-200 ease-in-out peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-focus:top-2 peer-focus:text-xs peer-focus:text-blue-600"
        >
        Last Name
        <span className="text-gray-400 text-xs ml-1">(optional)</span>
        </label>
        <FaUser className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
    </div>
</div> */}
