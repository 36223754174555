import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import logo from '../Images/logo2.png';
import { FaRocket } from 'react-icons/fa';


export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    // Create a state varibale that tracks if the location pathname includes /scribe
    const [isScribe, setIsScribe] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        // Update the state variable when the location changes
        setIsScribe(location.pathname.includes('/scribe'));
        setIsAuth(location.pathname.includes('/login'));
    }, [location]);

    const scrollToSection = (sectionId) => {
        setIsNavOpen(false);
        navigate('/');
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                const headerOffset = 5; // This is the offset in vh. Adjust as necessary.
                const sectionPosition = section.getBoundingClientRect().top;
                const offsetPosition = sectionPosition + window.pageYOffset - (window.innerHeight * headerOffset / 100);
    
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }, 300);
    };

  if(isScribe){
    return null;
  }

  if(isAuth){
    return null;
  }

  return (<>
    {/* Header */}
    <header className={`fixed top-0 w-full text-primary bg-darkerBackground border-b-primary px-4 py-4 lg:py-4 z-50 font-display`}>
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo and Title */}
        <div className="flex items-center space-x-4">
          <img src={logo} alt="ChartMD Logo" className="h-10 lg:h-12" />
          <h1 className="text-2xl lg:text-3xl font-bold">ChartMD</h1>
        </div>
        {/* Hamburger Icon */}
        <div className="lg:hidden">
          <button
            onClick={() => setIsNavOpen((prev) => !prev)}
            className="text-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <span className="material-icons text-3xl">menu</span>
          </button>
        </div>
        {/* Navigation */}
        <nav
          className={`${
            isNavOpen ? "flex" : "hidden"
          } flex-col lg:flex lg:flex-row lg:items-center absolute lg:relative top-full left-0 w-full lg:w-auto bg-accent font-semibold lg:bg-transparent transition-transform duration-300 ease-in-out z-10`}
        >
          <div className="flex flex-col space-y-4 py-6 lg:py-0 lg:flex-row lg:space-y-0 lg:space-x-4">
            <a
              onClick={() => {
                scrollToSection("home");
                setIsNavOpen(false);
              }}
              className="block text-lg text-center lg:text-left hover:text-secondary transition-colors px-6 py-3 lg:py-2 cursor-pointer"
            >
              Home
            </a>
            <a
              onClick={() => {
                scrollToSection("how-chartmd-works");
                setIsNavOpen(false);
              }}
              className="block text-lg text-center lg:text-left hover:text-secondary transition-colors px-6 py-3 lg:py-2 cursor-pointer"
            >
              How It Works
            </a>
            <a
              onClick={() => {
                scrollToSection("chartmd-features");
                setIsNavOpen(false);
              }}
              className="block text-lg text-center lg:text-left hover:text-secondary transition-colors px-6 py-3 lg:py-2 cursor-pointer"
            >
              Features
            </a>
            <a
              onClick={() => {
                scrollToSection("pricing-plans");
                setIsNavOpen(false);
              }}
              className="block text-lg text-center lg:text-left transition-colors hover:text-secondary px-6 py-3 lg:py-2 cursor-pointer"
            >
              Pricing
            </a>
            </div>
            {/* Get Started Button */}
            <a
              onClick={() => {
                navigate("/login");
                setIsNavOpen(false);
              }}
              className="bg-primary hover:bg-secondary text-white text-lg py-2 px-6 rounded-lg transition-colors cursor-pointer my-5 sm:my-0 mx-4 lg:mt-0 lg:ml-6 flex items-center justify-center"
            >
              <FaRocket className="mr-2" />
              Get Started
            </a>
            </nav>
      </div>
    </header>
    </>)
}
