import React from 'react';
import { Modal, Box, Typography, Button, Paper } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import theme from '../../../theme/theme';
import { CalendarMonth } from '@mui/icons-material';

const NoteDetailsModal = ({
   open, 
   handleClose, 
   noteDetails, 
   onAccept, 
   onDecline,
   handleTextareaChange
  }) => {
  if (!noteDetails) return null;

  const formatDate = (dateString) => {
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="note-details-modal"
        aria-describedby="note-details-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "65vw",
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: "1rem",
          p: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>
          <div className='flex justify-between'>
            <Typography variant="body1" color="text.secondary" gutterBottom sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
              <CalendarMonth className='mr-2' /> {formatDate(noteDetails.created_at)}
            </Typography>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              <span className='font-bold'>Section:</span> <span>{noteDetails.note_type}</span>
            </Typography>
          </div>
          <Paper elevation={3} sx={{ p: 2, mb: 2, minHeight:"250px", borderColor:"primary.main", borderWidth:"2px" }}>
            <Typography variant="body1" paragraph>
              {noteDetails.note_content}
            </Typography>
          </Paper>
          <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, }}>
            <Button onClick={handleClose} variant="outlined" color="error" sx={{marginRight:"1rem"}}>
              Cancel
            </Button>
            <Button onClick={() => {handleTextareaChange(noteDetails.note_type, noteDetails.note_content); handleClose();}} variant="contained" color="primary">
              Revert to this note
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default NoteDetailsModal;