import React from 'react';
import { FaMinusCircle, FaTrash } from 'react-icons/fa';
import './PatientDelete.css';

export default function PatientDelete({ isOpen, onClose, basicPatientInfo, onDelete }) {

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 backdrop-filter backdrop-blur-sm flex items-center justify-center p-4 fade-in-modal">
      <div className="bg-white rounded-lg shadow-2xl m-4 max-w-md w-full p-8">
        <div className="flex items-center justify-center mb-6">
          <h2 className="text-2xl font-bold">Confirm Visit Deletion</h2>
        </div>
        <div className="text-center">
          <p className="font-semibold text-xl text-gray-800 mb-1">
            {basicPatientInfo.first_name} {basicPatientInfo.last_name}
          </p>
          <p className="text-lg text-gray-600 border-b-2 pb-4">
            {new Date(basicPatientInfo.recording_date).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </p>
        </div>
        <p className="text-center mt-6 text-gray-700 leading-relaxed">
          Are you certain you want to delete this patient visit? This action will permanently delete all data associated with this visit.
        </p>
        <div className="flex justify-center space-x-4 mt-8 pt-4 border-t">
          <button
            className="px-6 py-3 bg-gray-200 text-gray-800 rounded-lg flex items-center hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 transition duration-300"
            onClick={onClose}
          >
            <FaMinusCircle className="mr-2" />
            Cancel
          </button>
          <button
            className="px-6 py-3 bg-red-600 text-white rounded-lg flex items-center hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-300"
            onClick={() => { onClose(); onDelete(basicPatientInfo.patient_visit_id)}}
          >
            <FaTrash className="mr-2" />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}