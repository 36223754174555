import React, { useState } from 'react';
import { Box, Typography, Switch, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Notifications as NotificationsIcon, Lock as LockIcon } from '@mui/icons-material';

const SettingsBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const SettingItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const Settings = () => {
  const [notifications, setNotifications] = useState(false);

  const handleNotificationToggle = () => {
    setNotifications(!notifications);
  };

  return (
    <SettingsBox>
      <Typography variant="h6" gutterBottom>Settings</Typography>
      <SettingItem container spacing={2} alignItems="center">
        <Grid item>
          <NotificationsIcon color="primary" />
        </Grid>
        <Grid item xs>
          <Typography>Notifications</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={notifications}
            onChange={handleNotificationToggle}
            color="primary"
          />
        </Grid>
      </SettingItem>
      <SettingItem container spacing={2} alignItems="center">
        <Grid item>
          <LockIcon color="primary" />
        </Grid>
        <Grid item xs>
          <Typography>Security</Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary">
            Change Password
          </Button>
        </Grid>
      </SettingItem>
    </SettingsBox>
  );
};

export default Settings;
