import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Button, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Description as DescriptionIcon, Add as AddIcon } from '@mui/icons-material';

const TemplatesBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const SectionBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Templates = ({ templates, onAddTemplate }) => {
  const sections = [
    'subjective',
    'objective',
    'assessment and plan',
    'encounter',
    'medications',
    'labs',
    'referrals'
  ];

  return (
    <TemplatesBox>
      <Typography variant="h5" gutterBottom>Templates</Typography>
      {sections.map((section, index) => (
        <React.Fragment key={section}>
          <SectionBox>
            <Typography variant="h6" gutterBottom sx={{ textTransform: 'capitalize' }}>
              {section.replace('_', ' ')}
            </Typography>
            <List>
              {templates
                .filter(template => template.section === section)
                .map((template, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary={template.name} />
                  </ListItem>
                ))}
            </List>
            <Button
              disableRipple
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => onAddTemplate(section)}
              sx={{ mt: 2 }}
            >
              Add Template
            </Button>
          </SectionBox>
          {index < sections.length - 1 && <Divider sx={{ my: 2 }} />}
        </React.Fragment>
      ))}
    </TemplatesBox>
  );
};

export default Templates;
