import React from 'react';
import { Snackbar, IconButton } from '@mui/material';
import { CheckCircle as CheckCircleIcon, Close as CloseIcon } from '@mui/icons-material';

export default function Badge({ message, isVisible, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isVisible}
      autoHideDuration={1500}
      onClose={onClose}
      message={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleIcon style={{ marginRight: '8px' }} />
          {message}
        </span>
      }
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}
