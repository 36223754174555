import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, Typography, Box, LinearProgress, CircularProgress } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { CheckCircle } from '@mui/icons-material';

export default function AudioUploadModal({ audioFile, isOpen, onClose, onProcess, patientName, sendingAudioToServer }) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let timer;

        if (sendingAudioToServer) {
            setProgress(0);
            timer = setInterval(() => {
                setProgress((prevProgress) => {
                    const newProgress = prevProgress + 1;
                    return newProgress >= 100 ? 100 : newProgress;
                });
            }, 100); // Update progress every 100ms for a total of 10 seconds
        } else {
            clearInterval(timer);
            if (progress > 0 && progress < 100) {
                setProgress(100);
            }
        }

        return () => {
            clearInterval(timer);
        };
    }, [sendingAudioToServer]);

    const handleProcessClick = () => {
        onProcess(audioFile);
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <AiOutlineCloudUpload className="mr-2 text-primary" size={32} />
                    Listen to the Uploaded Audio
                </Box>
                <IconButton 
                    aria-label="close" 
                    onClick={onClose} 
                    sx={{ position: 'absolute', right: 8, top: 8, color: 'blue[500]' }}
                >
                    <FaTimes size={24} />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {audioFile && (
                    <Box mb={4} p={2} bgcolor="blue[50]" borderRadius={2}>
                        <audio controls className="w-full">
                            <source src={URL.createObjectURL(audioFile)} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </Box>
                )}
                <Typography variant="body1" color="textSecondary" align="center">
                    After reviewing your audio, click the button below to process your visit for <br/> <span className='text-primary underline'>{patientName}</span>.
                </Typography>
                {sendingAudioToServer && (
                    <Box mt={2}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
                <Box textAlign="center">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleProcessClick}
                        startIcon={sendingAudioToServer ? <CircularProgress size={20} color="inherit" /> : <CheckCircle />}
                        sx={{ borderRadius: 2, py: 1.5, px: 4 }}
                        disabled={sendingAudioToServer}
                    >
                        {sendingAudioToServer ? 'Processing...' : 'Process Visit'}
                    </Button>
                    <Typography variant="caption" display="block" color="textSecondary" mt={1}>
                        {sendingAudioToServer ? 'Uploading audio file, please wait...' : 'Click to proceed'}
                    </Typography>
                </Box>
            </DialogActions>
        </Dialog>
    );
}