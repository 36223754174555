import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Person as PersonIcon,
  Description as DescriptionIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
} from '@mui/icons-material';
import { ThemeProvider } from '@emotion/react';
import theme from '../../theme/theme';

import ProfileInfo from './components/ProfileInfo';
import Templates from './components/Templates';
import Settings from './components/Settings';

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 800,
  height: '50vh',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  overflow: 'hidden',
}));

const SideBar = styled(Box)(({ theme }) => ({
  width: '25%',
  height: '100%',
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
}));

const MainContent = styled(Box)(({ theme }) => ({
  width: '75%',
  height: '100%',
  padding: theme.spacing(2),
  overflow: 'auto',
}));

const SidebarContent = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const LogoutButton = styled(ListItem)({
  marginTop: 'auto',
});

export default function ProfileModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [activeTab, setActiveTab] = useState('profile');
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (isOpen) {
      axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-user-info", {}, {
        withCredentials: true,
      })
      .then(response => {
        setUserInfo(response.data);
      })
      .catch(error => {
        console.error("Error fetching user info:", error);
      });
    }
  }, [isOpen]);

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    navigate("/entry");
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledModal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="profile-modal-title"
        aria-describedby="profile-modal-description"
      >
        <ModalContent>
          <SideBar>
            <SidebarContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar sx={{ mr: 2 }}><PersonIcon /></Avatar>
                <Typography variant="h6">Profile</Typography>
              </Box>
              <List>
                {[
                  { text: 'Profile Info', icon: <PersonIcon />, value: 'profile' },
                  { text: 'Templates', icon: <DescriptionIcon />, value: 'templates' },
                  { text: 'Settings', icon: <SettingsIcon />, value: 'settings' },
                ].map((item) => (
                  <ListItem
                    button
                    key={item.text}
                    selected={activeTab === item.value}
                    onClick={() => handleTabChange(item.value)}
                    disableRipple
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </SidebarContent>
            <Divider />
            <LogoutButton button onClick={handleLogout}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary="Log Out" />
            </LogoutButton>
          </SideBar>
          <MainContent>
            {activeTab === 'profile' && <ProfileInfo userInfo={userInfo} />}
            {activeTab === 'templates' && <Templates templates={templates} />}
            {activeTab === 'settings' && <Settings />}
          </MainContent>
        </ModalContent>
      </StyledModal>
    </ThemeProvider>
  );
}
