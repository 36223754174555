import React, { useState, useEffect, useRef } from 'react';
import { AiFillExperiment } from 'react-icons/ai';
import { FaPauseCircle, FaPencilAlt, FaPlay, FaQuestionCircle, FaRegEdit, FaUserInjured, FaUserMd } from 'react-icons/fa';

const TranscriptDisplay = ({ transcript, timestampSelected, isRecording, isOpen, onClose, summaries, onOpenCopilot, stopRecording, startRecording }) => {
  const endOfTranscriptRef = useRef(null);
  const [isClosing, setIsClosing] = useState(false);
  const modalRef = useRef();
  const [hoveredTimestamp, setHoveredTimestamp] = useState(null);
  const [clickedTimestamp, setClickedTimestamp] = useState(null);
  const [transcriptArray, setTranscriptArray] = useState(Array.isArray(transcript) ? transcript : []);

  useEffect(() => {
    setTranscriptArray(Array.isArray(transcript) ? transcript : []);
  }, [transcript]);

  const handleClose = () => {
    setIsClosing(true);
    // Delay state update until animation completes
    setTimeout(() => onClose(), 500);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Reset closing state and attach the event listener when the modal is open
      setIsClosing(false);
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Remove the event listener when the modal is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]); 

  useEffect(() => {
    if (endOfTranscriptRef.current) {
      endOfTranscriptRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [transcript]);

  useEffect(() => {
    if (timestampSelected) {
      const selectedElement = document.querySelector(`[data-timestamp='${timestampSelected}']`);
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [timestampSelected]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const pad = (num) => num.toString().padStart(2, '0');
    return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
  };

  const toggleRole = (index) => {
    // Create a new array with all items from transcriptArray
    let newTranscriptArray = [...transcriptArray];
    
    // Update the role of the item at the given index
    newTranscriptArray[index] = {
      ...newTranscriptArray[index],
      role: newTranscriptArray[index].role === 'Patient' ? 'Doctor' : 'Patient',
    };
  
    // Update the state with the modified array
    setTranscriptArray(newTranscriptArray);
  };
  
  if (!isOpen && !isClosing) return null;

  return (
    <div className={`fixed inset-0 z-40 overflow-auto bg-black bg-opacity-60 flex items-center justify-center transform transition-transform duration-300 ${!isOpen && isClosing ? 'hidden' : 'visible'}`} onClick={() => {setClickedTimestamp(null); onClose();}}>
      <div className={`p-4 w-full max-w-6xl bg-white rounded-lg transform transition-transform duration-500 ${isOpen && !isClosing ? 'translate-x-0' : 'translate-x-full '} relative`} ref={modalRef}  onClick={e => {e.stopPropagation()}}>
        <div className="flex justify-between items-center pb-3">
          <div className='flex justify-between items-center w-full'>
            <div className='flex justify-start'>
                <h2 className="text-2xl font-bold flex items-center text-blue-500"><AiFillExperiment className='mr-2 text-3xl ' /> Conversation Analysis</h2>
            </div>
            {isRecording ?
              <button onClick={() => stopRecording()} className="bg-white hover:bg-gray-100 text-blue-600 sm:text-lg py-1 px-3 flex items-center rounded-xl shadow-lg transform hover:-translate-y-0.5 pulse sm:ml-10">
                <FaPauseCircle className='mr-3' /> Pause
              </button>
              :
              <button onClick={() => startRecording()} className="bg-blue-600 hover:bg-blue-700 text-white mr-4 sm:text-lg py-1 px-3 mx-1 flex justify-center items-center rounded shadow-lg transform hover:-translate-y-0.5 sm:ml-10">
                <FaPlay className='mr-3'/>Continue
              </button>
            }
          </div>
            <div className="modal-close cursor-pointer" onClick={() => {onClose(); setClickedTimestamp(null) }}>
              <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path></svg>
            </div>
        </div>
        <hr className="border-t border-gray-300"/>
        <div className="flex flex-col sm:flex-row justify-center items-center sm:items-start max-h-[80vh] overflow-auto">
          {/* AI Transcription Section */}
          <div className="p-4 w-full sm:w-2/3 max-h-[40vh] sm:max-h-[60vh] bg-white overflow-y-auto">
            <h3 className="text-xl font-bold mb-2 flex items-center"><FaPencilAlt className='mr-2' /> Transcription</h3>
            <ul className="space-y-2">
              {transcriptArray.map((entry, index) => (
                <li key={index} data-timestamp={entry.time} className={`p-2 flex justify-between items-center rounded-lg ${entry.time === clickedTimestamp ? "bg-blue-500" : entry.time === hoveredTimestamp ? "bg-blue-100" : "bg-slate-100"} shadow-md`}>
                  <div className={`${entry.time === clickedTimestamp ? "text-white" : "" } p-2`}>
                    {entry.text}
                    {/* <div className={`text-xs ${entry.time === clickedTimestamp ? "text-slate-300" : entry.time === hoveredTimestamp ? "text-gray-700" : "text-gray-800"}`}>{formatTimestamp(entry.time)}</div> */}
                  </div>
                  {/* <div className="flex items-center">
                    <label className="switch">
                      <input type="checkbox" checked={entry.role === "Doctor"} onChange={() => toggleRole(index)} />
                      <span className="slider round">
                        <FaUserInjured className={`icon patient ${entry.role === 'Patient' ? 'visible' : 'hidden'}`} />
                        <FaUserMd className={`icon doctor ${entry.role === 'Doctor' ? 'visible' : 'hidden'}`} />
                      </span>
                    </label>
                  </div> */}
                </li>
              ))}
              <div ref={endOfTranscriptRef} />
            </ul>
          </div>
  
          {/* AI Summarization Section */}
          <div className="flex flex-col justify-start p-4 w-full sm:w-1/3 max-h-[35vh] sm:min-h-[60vh] sm:max-h-[60vh] bg-slate-100 overflow-y-auto">
            <h3 className="text-xl font-bold mb-2">AI Summarization</h3>
            <ul className="space-y-2">
              {summaries.length === 0 ? (
                <li className="p-2 bg-white rounded-lg shadow-md text-gray-500 text-center">
                  No summarizations made.
                </li>
              ) : (
                summaries.map((summaryItem, index) => (
                  <li key={index} 
                      className={`p-2 rounded-lg shadow-md cursor-pointer ${summaryItem.timestamp === clickedTimestamp ? "bg-blue-500 text-white" : "bg-white hover:bg-blue-100"}`}
                      onMouseEnter={() => setHoveredTimestamp(summaryItem.timestamp)}
                      onMouseLeave={() => setHoveredTimestamp(null)}
                      onClick={() => setClickedTimestamp(summaryItem.timestamp)}>
                    <div>{summaryItem.summary}</div>
                    <div className="text-xs text-gray-300">{formatTimestamp(summaryItem.timestamp)}</div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default TranscriptDisplay;
