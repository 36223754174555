import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes, FaEdit, FaFileAlt, FaFileSignature } from 'react-icons/fa';
import { AiOutlineClose, AiOutlineSave, AiOutlineSend } from 'react-icons/ai';
import { ImSpinner2 } from 'react-icons/im';
import axios from 'axios';

export default function ReWriteModal({ isOpen, onClose, currentNote, subject, rewWriteInstructions, onAccept, onEdit, clearValues }) {
  const [showEditBox, setShowEditBox] = useState(false);
  const [rewrittenNote, setRewrittenNote] = useState('');
  const [editedNote, setEditedNote] = useState(rewrittenNote);
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = () => {
    onAccept(rewrittenNote);
    onClose();
  };

  useEffect(() => {
    const reWriteNote = async () => {
      if(rewrittenNote !== '') return;
      try {
        setIsLoading(true);
        const response = await axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/rewrite-note-basic', {
          note: currentNote,
          noteType: subject,
          instructions: rewWriteInstructions
        }, {
          withCredentials: true
        });
        setRewrittenNote(response.data.rewrittenNote);
        setIsLoading(false);
      } catch (error) {
        console.error('Error rewriting note:', error);
        setIsLoading(false);
      }
    };

    if (isOpen) {
      reWriteNote();
    }
  }, [isOpen]);

  const handleEdit = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/rewrite-note-basic', {
        note: rewrittenNote ? rewrittenNote : currentNote,
        noteType: subject,
        instructions: rewWriteInstructions ? editedNote : rewWriteInstructions
      }, {
        withCredentials: true
      });
      setRewrittenNote(response.data.rewrittenNote);
      setEditedNote("")
      setIsLoading(false);
      setShowEditBox(false);
    } catch (error) {
      console.error('Error editing note:', error);
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    onEdit(editedNote);
    setShowEditBox(false);
  };

  const handleCancel = () => {
    setShowEditBox(false);
    setEditedNote(rewrittenNote);
  };
  

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg shadow-lg w-full max-w-6xl mx-auto p-10 z-10">
            <div className="flex items-center justify-between mb-8 w-full bg-blue-800 rounded-lg shadow-lg px-8 py-6">
              <div>
                <h2 className="text-4xl font-semibold text-white mb-1 flex items-center">
                  <span className="inline-block">Review and Accept Changes</span>
                </h2>
                <p className="text-base text-blue-200">
                  Collaborate with your AI partner to refine and approve the rewritten note
                </p>
              </div>
              <button
                className="text-white hover:text-blue-200 transition-colors duration-300 focus:outline-none"
                onClick={() => {
                  onClose();
                  clearValues();
                  setRewrittenNote('');
                }}
              >
                <FaTimes className="w-8 h-8" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-10">
              <div className="flex flex-col items-center">
                <h3 className="text-xl font-semibold mb-4 flex justify-center">
                  <FaFileAlt className="mr-2 text-blue-500" />
                  Current Note
                </h3>
                <div className="border-l-[1px] border-b-[1px] border-gray-500 p-6 h-96 overflow-y-auto w-full">
                  {currentNote}
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h3 className="text-xl font-semibold mb-4 flex justify-center">
                  <FaFileSignature className="mr-2 text-blue-500" />
                  Rewritten Note
                </h3>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96 w-full">
                    <ImSpinner2 className="animate-spin text-4xl text-blue-500" />
                  </div>
                ) : (
                  <>
                    {!showEditBox ? (
                      <div className="border-l-[1px] border-b-[1px] border-blue-500 p-6 h-96 overflow-y-auto w-full">
                        {rewrittenNote}
                      </div>
                    ) : (
                      <div className="border-l-[1px] border-b-[1px] border-blue-500 p-6 h-96 overflow-y-auto w-full">
                        <textarea
                          className="w-full h-full bg-transparent focus:outline-none"
                          value={editedNote}
                          onChange={(e) => setEditedNote(e.target.value)}
                          onKeyDown={(e) => e.key === 'Enter' && handleEdit()}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-end mt-10">
              {!showEditBox ? (
                <>
                  <button
                    className="bg-blue-500 text-white px-6 py-3 rounded-lg mr-4 hover:bg-blue-600 focus:outline-none flex items-center text-lg"
                    onClick={() => handleAccept(rewrittenNote)}
                  >
                    <FaCheck className="mr-2" />
                    Accept
                  </button>
                  <button
                    className="bg-gray-200 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-300 focus:outline-none flex items-center text-lg"
                    onClick={() => setShowEditBox(true)}
                  >
                    <FaEdit className="mr-2" />
                    Edit
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="bg-blue-500 text-white px-6 py-3 rounded-lg mr-4 hover:bg-blue-600 focus:outline-none flex items-center text-lg"
                    onClick={handleEdit}
                  >
                    <AiOutlineSend className="mr-2" />
                    Re-Write
                  </button>
                  <button
                    className="bg-gray-200 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-300 focus:outline-none flex items-center text-lg"
                    onClick={handleCancel}
                  >
                    <AiOutlineClose className="mr-2" />
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}