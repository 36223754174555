import React, { useRef, useEffect } from 'react';

const RecordingButton = ({ isRecording, isPaused, logo, size = 'h-36 w-36', audioStream }) => {
  const canvasRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (isRecording && !isPaused && audioStream) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(audioStream);
      source.connect(analyser);

      analyser.fftSize = 64;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const canvas = canvasRef.current;
      const button = buttonRef.current;

      // Increase canvas size to give more room for the bars
      const scaleFactor = 1.5; // Adjust this value to give more or less room
      canvas.width = button.offsetWidth * scaleFactor;
      canvas.height = button.offsetHeight * scaleFactor;

      const canvasCtx = canvas.getContext('2d');

      const draw = () => {
        requestAnimationFrame(draw);

        analyser.getByteFrequencyData(dataArray);

        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = Math.min(button.offsetWidth, button.offsetHeight) / 2;

        for (let i = 0; i < bufferLength; i++) {
          const barHeight = (dataArray[i] / 255) * radius * 0.7; // Increased multiplier
          const angle = (i / bufferLength) * Math.PI * 2;
          const x1 = centerX + Math.cos(angle) * radius;
          const y1 = centerY + Math.sin(angle) * radius;
          const x2 = centerX + Math.cos(angle) * (radius + barHeight);
          const y2 = centerY + Math.sin(angle) * (radius + barHeight);

          canvasCtx.beginPath();
          canvasCtx.moveTo(x1, y1);
          canvasCtx.lineTo(x2, y2);
          canvasCtx.strokeStyle = '#519872'; // Pastel green color
          canvasCtx.lineWidth = (radius * Math.PI * 2) / bufferLength;
          canvasCtx.stroke();
        }
      };

      draw();

      return () => {
        source.disconnect();
        audioContext.close();
      };
    }
  }, [isRecording, isPaused, audioStream]);

  return (
    <div className="relative inline-block mb-5">
      {isRecording && !isPaused && (
        <div className="absolute inset-0 rounded-full opacity-75"></div>
      )}
      <button
        ref={buttonRef}
        disabled
        className={`p-5 rounded-full border-primary border-2 ${
          isRecording && !isPaused ? '' : 'bg-transparent'
        } flex justify-center items-center focus:outline-none relative`}
      >
        <img src={logo} alt="Logo" className={size} />
      </button>
      <canvas 
        ref={canvasRef} 
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10" 
      />
    </div>
  );
};

export default RecordingButton;
