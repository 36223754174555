// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: 
  {
    primary: {
      main: '#519872', // Gentle green used in buttons and highlights
    },
    secondary: {
      main: '#275d38', // Darker green used in highlights
    },
    background: {
      default: '#f8fafc', // Soft light background color
      paper: '#eceff1', // Slightly darker background color
    },
    text: {
      primary: '#102027', // Dark blue for primary text
    },
    divider: '#B0BEC5', // Light border color for inputs and cards
    accent: {
      main: '#D9EAD3', // Soft green accent for highlights and icons
    },
  },
  // {
  //   primary: {
  //     main: '#8A9A5B', // Olive green used for buttons and highlights
  //   },
  //   secondary: {
  //     main: '#48592E', // Dark olive for highlights
  //   },
  //   background: {
  //     default: '#F8F5F0', // Soft beige background
  //     paper: '#E3DEDA', // Darker beige color
  //   },
  //   text: {
  //     primary: '#2C2E37', // Dark gray text color
  //   },
  //   divider: '#CBD7D9', // Light aqua border color
  //   accent: {
  //     main: '#E1DAAE', // Light gold accent for highlights and icons
  //   },
  // },
//   {
//     primary: {
//       main: '#F78C6B', // Warm coral used in buttons and highlights
//     },
//     secondary: {
//       main: '#9C3B2D', // Darker coral for highlights
//     },
//     background: {
//       default: '#FCFAF5', // Very light warm background
//       paper: '#F2E7DE', // Darker warm background
//     },
//     text: {
//       primary: '#3D1E16', // Dark brown for primary text
//     },
//     divider: '#DED5CB', // Light bronze border for inputs and cards
//     accent: {
//       main: '#F9D6C1', // Soft peach accent for highlights and icons
//     },
//   },
  // {
  //   primary: {
  //     main: '#71B1D1', // Soft blue used in buttons and highlights
  //   },
  //   secondary: {
  //     main: '#3A79A6', // Darker blue used in highlights
  //   },
  //   background: {
  //     default: '#EFF8FB', // Pale blue for background
  //     paper: '#CFE3E9', // Slightly darker pale blue
  //   },
  //   text: {
  //     primary: '#183952', // Dark blue for primary text
  //   },
  //   divider: '#A1BDD2', // Light blue border for inputs and cards
  //   accent: {
  //     main: '#A8E6FF', // Light aqua accent for highlights and icons
  //   },
  // },
  // {
  //   primary: {
  //     main: '#3B82F6', // Blue used in buttons and highlights
  //   },
  //   secondary: {
  //     main: '#1E3A8A', // Darker blue used in highlights
  //   },
  //   background: {
  //     default: '#edf2f5', // Light gray used for background
  //     paper: '#d5e3eb', // Darker background color
  //   },
  //   text: {
  //     primary: '#111827', // Darker text color
  //   },
  //   divider: '#E5E7EB', // Border color for inputs and cards
  //   accent: {
  //     main: '#A6C8FF', // Light blue accent for highlights and icons
  //   },
  // },
  // {
  //   primary: {
  //     main: '#6B7280', // Gray used in buttons and highlights
  //   },
  //   secondary: {
  //     main: '#4B5563', // Darker gray used in highlights
  //   },
  //   background: {
  //     default: '#F3F4F6', // Light gray background color
  //     paper: '#D1D5DB', // Darker gray background color
  //   },
  //   text: {
  //     primary: '#374151', // Darker gray text color
  //   },
  //   divider: '#E5E7EB', // Gray border color for inputs and cards
  //   accent: {
  //     main: '#9CA3AF', // Light gray accent for highlights and icons
  //   },
  // },
  // Customize other theme properties like typography, spacing, etc.
});

export default theme;
