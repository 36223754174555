import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaLock, FaSignInAlt, FaTimes } from 'react-icons/fa'
import axios from 'axios';
import { ThemeProvider } from '@emotion/react';
import theme from '../../theme/theme';

import logo from '../../Images/logo1.png';

import { 
    Box, 
    Typography, 
    TextField, 
    Button, 
    Container, 
    Paper,
    IconButton
  } from '@mui/material';
  import CloseIcon from '@mui/icons-material/Close';
  import EmailIcon from '@mui/icons-material/Email';
  import LockIcon from '@mui/icons-material/Lock';
  import LoginIcon from '@mui/icons-material/Login';

export default function LoginModal({ isOpen, onClose}) {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (event) => {
        const loginData = {
            email,
            password,
        };
    
        try {
            const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/login", loginData, {
            withCredentials: true, // Add this option to allow sending and receiving cookies
            });
            if (response.data.firstName) {
            sessionStorage.setItem('firstName', response.data.firstName);
            sessionStorage.setItem('principal', response.data.id);
            onClose();
            } else {
            alert("Login successful but an error has occurred, looking into the issue now.");
            }
        } catch (error) {
            console.error("Login error:", error);
            alert("Failed to log in. Please check your credentials and try again.");
        }
    }

    
    const onKeyDownLogin = (event) => {
        if (event.key) {
            if (event.key === 'Enter') {
                handleSubmit();
            }
        }
    }

    if (!isOpen) return null;
    
    return (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              position: 'fixed',
              inset: 0,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(8px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 4,
              zIndex: 50,
            }}
          >
            <Container maxWidth="sm">
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  bgcolor: 'accent.main',
                  borderRadius: 4,
                }}
              >
                <IconButton
                  sx={{ position: 'absolute', top: 16, right: 16 }}
                  onClick={() => navigate('/entry?login=true')}
                >
                  <CloseIcon />
                </IconButton>
    
                <Box sx={{ mb: 3, position: 'relative' }}>
                  <img src={logo} alt="ChartMD Logo" style={{ height: '96px' }} />
                </Box>
    
                <Typography variant="h5" component="h2" align="center" sx={{ mb: 3 }}>
                  Your session timed out. <br/> Please log back in to continue.
                </Typography>
    
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  onKeyDown={onKeyDownLogin}
                  InputProps={{
                    startAdornment: <EmailIcon color="primary" sx={{ mr: 1 }} />,
                  }}
                  sx={{ mb: 2 }}
                />
    
                <TextField
                  fullWidth
                  id="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                  onKeyDown={onKeyDownLogin}
                  InputProps={{
                    startAdornment: <LockIcon color="primary" sx={{ mr: 1 }} />,
                  }}
                  sx={{ mb: 3 }}
                />
    
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  startIcon={<LoginIcon />}
                  sx={{
                    py: 1.5,
                    borderRadius: 28,
                    textTransform: 'none',
                  }}
                >
                  Login
                </Button>
              </Paper>
            </Container>
          </Box>
        </ThemeProvider>
      );
}
