import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';

import logo from '../Images/logo1.png';
import TextMaskCustom from './TextMaskCustom';


import "./EntryPage.css"
import { FaEnvelope, FaGoogle, FaLock, FaPhone, FaSignInAlt, FaUser, FaUserPlus } from 'react-icons/fa';
import { Box, IconButton, Input, InputAdornment, TextField } from '@mui/material';
import { Email, Lock, Person, Phone, Visibility, VisibilityOff } from '@mui/icons-material';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/theme';

export default function EntryPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const login = queryParams.get('login');

  const [isLogin, setIsLogin] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [firstThree, setFirstThree] = useState('');
  const [lastFour, setLastFour] = useState('');
  const [dob, setDob] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (login === 'true') {
      setIsLogin(true);
    }
  }, [login]);  

  // useEffect(() => {
  //   console.log("area code", areaCode);
  //   console.log("first three", firstThree);
  //   console.log("last four", lastFour);
  // }, [areaCode, firstThree, lastFour]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!isLogin) {
      const phoneNumber = `${areaCode}-${firstThree}-${lastFour}`;
  
      const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
      if (!phoneRegex.test(phoneNumber)) {
        alert("Please enter a valid phone number.");
        return;
      }
  
      if (password !== confirmPassword) {
        alert("Passwords do not match.");
        return;
      }
  
      const accountData = {
        email,
        password,
        phoneNumber,
        firstName,
        lastName,
      };
  
      try {
        const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/register", accountData);
        console.log(response.data);
  
        alert("Account creation was successful! Proceed to log in.");
  
        window.location.reload();
      } catch (error) {
        console.error("Registration error:", error);
      }
    } else {
      const loginData = {
        email,
        password,
      };
  
      try {
        const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/login", loginData, {
          withCredentials: true, // Add this option to allow sending and receiving cookies
        });
        if (response.data.firstName) {
          sessionStorage.setItem('firstName', response.data.firstName);
          sessionStorage.setItem('principal', response.data.id);
          navigate("/scribe");
        } else {
          alert("Login successful but an error has occurred, looking into the issue now.");
        }
      } catch (error) {
        console.error("Login error:", error);
        alert("Failed to log in. Please check your credentials and try again.");
      }
    }
  };
  
  return (
    <ThemeProvider theme={theme}>
      <div className="flex justify-center items-center min-h-fit h-screen bg-gradient-to-b from-white to-accent p-5 font-display">
        <div className={`container flex flex-col-reverse sm:flex-row justify-around items-center bg-white rounded-3xl drop-shadow-2xl fade-in-header p-10 sm:p-16 transition-all duration-300`}>

          <form onSubmit={handleSubmit} className="rounded-lg w-full sm:w-2/5">

            {isLogin && (
                <>
                  <h1 className="text-3xl lg:text-4xl font-bold text-center mb-4 text-secondary">
                    Welcome Back!
                  </h1>
                  <p className="text-lg lg:text-xl text-center mb-8 text-primary">
                    Please enter your credentials to log in.
                  </p>
                </>
            )}

            {isLogin ? (
              <>
                {/* Login Email */}
                <Box mb={4}>
                  <TextField
                    id="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {/* Login Password */}
                <Box mb={6}>
                  <TextField
                    id="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </>
            ) : (
              <>
                {/* First and Last Name */}
                <Box mb={4} display="flex" spacing={2}>
                  <Box flex={1} mr={2}>
                    <TextField
                      id="firstName"
                      label="First Name"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter your first name"
                      required
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box flex={1}>
                    <TextField
                      id="lastName"
                      label="Last Name"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter your last name"
                      required
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>

                {/* Email */}
                <Box mb={4}>
                  <TextField
                    id="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {/* Password */}
                <Box mb={4}>
                  <TextField
                    id="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {/* Confirm Password */}
                <Box mb={4}>
                  <TextField
                    id="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your password"
                    required
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock color="primary" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {/* Phone number */}
                <Box mb={6}>
                  <TextField
                    id="phone"
                    label="Phone Number"
                    type="tel"
                    value={`+1 ${areaCode}${firstThree}${lastFour}`}
                    onChange={(e) => {
                      const newAreaCode = e.target.value.slice(4, 7);
                      const newFirstThree = e.target.value.slice(9, 12);
                      const newLastFour = e.target.value.slice(13, 17);

                      if (newAreaCode !== areaCode) {
                        setAreaCode(newAreaCode);
                      }
                      if (newFirstThree !== firstThree) {
                        setFirstThree(newFirstThree);
                      }
                      if (newLastFour !== lastFour) {
                        setLastFour(newLastFour);
                      }
                    }}
                    placeholder="XXX XXX XXXX"
                    required
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone color="primary" />
                        </InputAdornment>
                      ),
                      inputComponent: TextMaskCustom,
                    }}
                  />
                </Box>
              </>
            )}

            {/* Sign in / create account buttons */}
            <div className="flex flex-col sm:flex-row items-center justify-between mt-8 space-y-5 sm:space-y-0 sm:space-x-5">
              <button className="w-full sm:w-1/2 bg-primary hover:bg-secondary text-white py-3 px-6 rounded-full hover:secondary transition duration-300 ease-in-out flex items-center justify-center">
                {isLogin ? (
                  <>
                    <FaSignInAlt className="mr-2" />
                    Sign in
                  </>
                ) : (
                  <>
                    <FaUserPlus className="mr-2" />
                    Create Account
                  </>
                )}
              </button>
              {/* Google sign in */}
                <button
                  disabled  
                  className=" cursor-not-allowed w-full sm:w-1/2 text-gray-700 py-3 px-6 rounded-full bg-gray-100 hover:bg-gray-300 border-secondary border transition duration-300 ease-in-out flex items-center justify-center"
                >
                  <FaGoogle className="mr-2 text-primary" />
                  Sign in with <span className='text-primary ml-1'>Google</span>
                </button>
            </div>

            {/* Other container */}
            <div className="mt-6 text-center">
              <button
                className="text-secondary hover:text-primary underline transition duration-300 ease-in-out flex items-center justify-center mx-auto"
                onClick={() => setIsLogin(!isLogin)}
              >
                {isLogin ? (
                  <>
                    <FaUserPlus className="mr-2" />
                    Create a new account
                  </>
                ) : (
                  <>
                    <FaSignInAlt className="mr-2" />
                    Login with existing account
                  </>
                )}
              </button>
            </div>

          </form>
          
          <div className="w-full sm:w-3/5 flex flex-col items-center justify-center border-b-darkerBackground border-b-[1px] sm:border-b-0 mb-5 sm:mb-0 py-8 sm:py-12">
            <div className="relative flex justify-center">
              <img src={logo} alt="ChartMD Logo" className="h-24 lg:h-32 transition duration-300 ease-in-out transform hover:scale-105" />
            </div>
            <div className="text-center mt-6">
              <h1 className="text-3xl lg:text-4xl font-bold text-secondary mb-2 tracking-tight">
                ChartMD
              </h1>
              <p className="text-xl lg:text-2xl text-primary font-medium">
                Your Trusted Medical Scribe
              </p>
            </div>
          </div>

        </div>
      </div>
    </ThemeProvider>
  );  
}
