import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';

const MessageBubble = styled(Paper)(({ isUser }) => ({
  padding: '12px 16px',
  maxWidth: '70%',
  borderRadius: 20,
  background: isUser
    ? 'linear-gradient(to bottom right, #3b82f6, #2563eb)'
    : 'linear-gradient(to bottom right, #f3f4f6, #e5e7eb)',
  color: isUser ? '#ffffff' : '#1f2937',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
}));

const TypingIndicator = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
});

const Dot = styled('span')({
  width: 8,
  height: 8,
  borderRadius: '50%',
  background: '#6b7280',
  marginRight: 4,
  animation: 'pulse 1s infinite',
  '&:nth-of-type(2)': {
    animationDelay: '0.2s',
  },
  '&:nth-of-type(3)': {
    animationDelay: '0.4s',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.4,
      transform: 'scale(0.75)',
    },
    '50%': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '100%': {
      opacity: 0.4,
      transform: 'scale(0.75)',
    },
  },
});

const MessageDisplay = ({ messages, isResponding }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {messages.map((message, index) => (
        <MessageBubble key={index} isUser={message.role === 'user'} elevation={1}>
          <Typography variant="body2">{message.content}</Typography>
        </MessageBubble>
      ))}
      {isResponding && (
        <MessageBubble elevation={1}>
          <TypingIndicator>
            <Dot />
            <Dot />
            <Dot />
          </TypingIndicator>
        </MessageBubble>
      )}
    </Box>
  );
};

export default MessageDisplay;
