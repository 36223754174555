import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { AiOutlineAudio, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineSend } from 'react-icons/ai';
import { FaFileAlt, FaMagic } from 'react-icons/fa';
import MessageDisplay from './MessageDisplay'; // Import the MessageDisplay component
import { Box, IconButton, InputAdornment, Paper, TextField } from '@mui/material';
import { Mic, Send } from '@mui/icons-material';

import { styled } from '@mui/system';

const MessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '15.5rem',
    maxHeight: '15.5rem',
    width: '100%',
  }));
  
  const MessagesDisplay = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    width:"100%"
  }));

export default function Tools({ 
    interactiveToolSelected, 
    toggleInteractiveToolSelected, 
    interactiveToolOpen, 
    clearSelectedTools, 
    saveQuestionToAsk, 
    askQuestion, 
    saveInstructionsToRewrite, 
    currentNote, 
    reWriteNote, 
    updateNoteToRewrittenNote, 
    onAccept, 
    setReWritingNote, 
    setReWrittenNote, 
    setReWriteTextBoxVisible, 
    setTabSelectedForSection,
    setIsModified,
    setPreviousFields,
    editableFields
    }) {
    const [rewriteInstructions, setRewriteInstructions] = useState('');
    const [addNotes, setAddNotes] = useState('');
    const [question, setQuestion] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [selectedTool, setSelectedTool] = useState('');
    const [noteType, setNoteType] = useState('');
    const [recognition, setRecognition] = useState(null);

    useEffect(() => {
        let temportaryNoteType;
        if (interactiveToolOpen) {
            if (interactiveToolOpen === 'subjective'){
                setNoteType('subjective');
                temportaryNoteType = 'subjective'
            }
            if (interactiveToolOpen === 'objective'){
                setNoteType('objective');
                temportaryNoteType = 'objective'
            }
            if (interactiveToolOpen === 'assessment_and_plan'){
                setNoteType('assessment and plan');
                temportaryNoteType = 'assessment and plan'
            }
            if (interactiveToolOpen === 'encounter'){
                setNoteType('encounter');
                temportaryNoteType = 'encounter'
            }
            if (interactiveToolOpen === 'medications'){
                setNoteType('medications');
                temportaryNoteType = 'medications'
            }
            if (interactiveToolOpen === 'labs'){
                setNoteType('labs');
                temportaryNoteType = 'labs'
            }
            if (interactiveToolOpen === 'referrals'){
                setNoteType('referrals');
                temportaryNoteType = 'referrals'
            }
            if(messages.length < 1){
                setMessages([
                    { role: 'assistant', content: `Hello! How can I assist you today with your ${temportaryNoteType} notes?` },
                ]);
            }
        }
    }, [interactiveToolOpen]);

    useEffect(() => {
        setSelectedTool(interactiveToolOpen);
    }, [interactiveToolSelected]);

    // New states for text window and messages
    const [textWindowOpen, setTextWindowOpen] = useState(false);
    const [isResponding, setIsResponding] = useState(false);  
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const toggleTextWindowOpen = () => setTextWindowOpen(!textWindowOpen);
    
    const messageContainerRef = useRef(null);

    const scrollToBottom = () => {
        const element = messageContainerRef.current;
        if (element) {
          const start = element.scrollTop;
          const end = element.scrollHeight - element.clientHeight;
          const change = end - start;
          const duration = 300; // Duration of the scroll animation in milliseconds
          let startTimestamp = null;
      
          const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = timestamp - startTimestamp;
            const progressPercentage = Math.min(progress / duration, 1);
            element.scrollTop = start + change * progressPercentage;
      
            if (progress < duration) {
              window.requestAnimationFrame(step);
            }
          };
      
          window.requestAnimationFrame(step);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, isResponding]);

    // Rewrite note states
    const [chartPalRewrittenNote, setChartPalRewrittenNote] = useState('');
    const [rewrittingNote, setRewrittingNote] = useState(false);

    const handleAddNotesChange = (e) => {
        setAddNotes(e.target.value);
    };

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
        saveQuestionToAsk(e.target.value);
    };

    const handleAskQuestion = () => {
        askQuestion(selectedTool);
        saveQuestionToAsk('');
        setQuestion('');
        if(isListening){
            handleStopListening();
        }
    };

    const handleRewriteChange = (e) => {
        setRewriteInstructions(e.target.value);
        saveInstructionsToRewrite(e.target.value);
        if(isListening){
            handleStopListening();
        }
    };

    const handleRewriteNote = () => {
        reWriteNote(selectedTool);
        setRewriteInstructions('');
        if(isListening){
            handleStopListening();
        }
    };

    const handleStartListening = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognitionInstance = new SpeechRecognition();
        recognitionInstance.continuous = true;
        recognitionInstance.interimResults = true;
        recognitionInstance.lang = 'en-US';
      
        let prevFinalTranscript = '';
      
        recognitionInstance.onresult = (event) => {
          let interimTranscript = '';
          let finalTranscript = '';
      
          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcriptPart = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
              finalTranscript += transcriptPart;
              if (/[.!?]$/.test(finalTranscript)) {
                finalTranscript += ' ';
              }
            } else {
              interimTranscript += transcriptPart;
            }
          }
      
          if (finalTranscript !== '') {
            // Update the notes with the final transcript
            prevFinalTranscript += finalTranscript;
            setNewMessage(prevFinalTranscript);
          } else if (interimTranscript !== '') {
            setNewMessage(prevFinalTranscript + interimTranscript);
          }
        };
      
        recognitionInstance.onend = () => {
          setIsListening(false);
        };
      
        recognitionInstance.start();
        setIsListening(true);
        setRecognition(recognitionInstance);
    };
    
    const handleStopListening = () => {
        if (recognition) {
            recognition.stop();
            setIsListening(false);
            // Focus on the textarea when stopping listening
            // textareaRef.current.focus();
        }
    };

    const handleSendMessage = async () => {
        // Send the message if it is not empty to the server endpoint: chartpal-decision-maker
        let updatedMessages;
        if (newMessage.trim() !== '') {
            setMessages([...messages, { role: 'user', content: newMessage }]);
            updatedMessages = [...messages, { role: 'user', content: newMessage }];
            setNewMessage('');

            // Send the message to the server endpoint with withCredentials set to true
            const decisionToMake = await axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/chartpal-decision-maker', {
                message: newMessage
            }, {
                withCredentials: true
            });
            
            // take this message and choose endpoint /chartpal-rewrite-note, /chartpal-rewrite-part, /chartpal-respond to user based on the respond which will be in all caps and in brackets like this: 

            let decision = decisionToMake.data.response;
            console.log('Decision:', decision);


            if(decision.toLowerCase().includes('rewrite-note')){
                await handleChartPalRewrite(newMessage, updatedMessages);
            } else if(decision.toLowerCase().includes('rewrite-part')){
                // await reWritePart();
            } else if(decision.toLowerCase().includes('respond to user')){
                // Call the respondToUser function
                await handleRespondToUser(updatedMessages);
            }
        }
    };

    const handleChartPalRewrite = async (newMessage, updatedMessages) => {
        // Send the message to the server endpoint with withCredentials set to true
        // console.log("Messages: ", messages)
        let reWrittenNote;
        try{
            await setReWritingNote(true);
            await setReWriteTextBoxVisible(true);
            await setTabSelectedForSection('new');
            console.log('Editable Fields:', editableFields[interactiveToolOpen]);
            const response = await axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/chartpal-rewrite-note', {
                currentNote: editableFields[interactiveToolOpen] ? editableFields[interactiveToolOpen] : currentNote,
                noteType: noteType,
                message: newMessage
            }, {
                withCredentials: true
            });

            // Update the note to the rewritten note
            console.log('Response:', response);
            reWrittenNote = response.data.response;
            console.log('ReWritten Note:', reWrittenNote);
            console.log('Interactive Tool Open:', interactiveToolOpen);
            await onAccept(interactiveToolOpen, response.data.response);
            // await setIsModified(prevState => ({ ...prevState, [interactiveToolOpen]: true }))
        }catch(error){
            console.error('Error rewriting note:', error);
        }finally{
            setRewrittingNote(false);
        }
        try{
            const response = await axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/chartpal-respond-to-user-after-rewrite', {
                currentNote: currentNote,
                rewrittenNote: reWrittenNote,
                noteType: noteType,
                messages: updatedMessages
            }, {
                withCredentials: true
            });
            console.log('Response:', response);

            // update the messages array to include this response as the assistant
            setMessages([...updatedMessages, { role: 'assistant', content: response.data.responseMessage }]);
        }catch(error){
            console.error('Error responding to user:', error);
        }finally{
            await setReWritingNote(false);
        }
    }

    const handleRespondToUser = async (updatedMessages) => {
        // Send the message to the server endpoint with withCredentials set to true
        setIsResponding(true);
        console.log("Messages: ", messages)
        try{
            const response = await axios.post('https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/chartpal-respond-to-user', {
                currentNote: chartPalRewrittenNote ? chartPalRewrittenNote : currentNote,
                noteType: noteType,
                messages: updatedMessages
            }, {
                withCredentials: true
            });
            console.log('Response:', response);

            // update the messages array to include this response as the assistant
            setMessages([...updatedMessages, { role: 'assistant', content: response.data.responseMessage }]);
        }catch(error){
            console.error('Error responding to user:', error);
        }finally{
            setIsResponding(false);
        }
    }

    return (
        <Paper sx={{width:"100%", backgroundColor:"white"}} elevation={0}>
            <MessageContainer>
            <MessagesDisplay ref={messageContainerRef}>
                <MessageDisplay messages={messages} isResponding={isResponding} />
            </MessagesDisplay>
            <Box p={2}>
                <TextField
                fullWidth
                variant="outlined"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                    handleSendMessage();
                    }
                }}
                placeholder="Type your message..."
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <IconButton onClick={isListening ? () => handleStopListening() : () => handleStartListening()}>
                        <Mic color={isListening ? "info" : "action"} />
                        </IconButton>
                    </InputAdornment>
                    ),
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleSendMessage} color="primary">
                        <Send />
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                />
            </Box>
            </MessageContainer>
        </Paper>
      );     
}
