import React, { useEffect, useState } from 'react';
import { FaCheckSquare, FaRegSquare, FaSave, FaUndo, FaCopy, FaMagic, FaCalendarDay } from 'react-icons/fa';
import { AiOutlineShrink, AiOutlineExpandAlt } from 'react-icons/ai';
import Tools from './SubComponents/Tools';
import { ArrowDropDown, AudioFile, Audiotrack, AutoFixHigh, ContentCopy, Description, DescriptionOutlined, PhoneInTalk, Save, Speaker, SpeakerNotes, Transcribe, TranscribeSharp, Undo } from '@mui/icons-material';
import { Box, Button, Divider, Menu, MenuItem, Paper, TextField, Tooltip } from '@mui/material';

export default function Section({
    section,
    sectionTitle,
    patientData,
    toggleSection, 
    isOpen, 
    visibleFields, 
    editableFields,
    isModified,
    tabSelectedForSection,
    setTabSelectedForSection,
    savePatientInfo,
    revertChanges,
    previousFields,
    setPreviousFields,
    isInteractiveToolsOpen,
    setIsInteractiveToolsOpen,
    interactiveToolSelected,
    interactiveToolsValues,
    toggleInteractiveToolSelected,
    setInteractiveToolSelected,
    setInteractiveToolsValues,
    subjectRewriteNote,
    handleAskAi,
    handleRewriteNote,
    handleTextareaChange,
    copyToClipboard,
    copyDisplayText,
    finalizeNote,
    setIsModified,
    transcript,
    setTranscriptVisible,
    transcriptVisible,
    savedNotes,
    updateSavedNoteDates,
    fetchSavedNoteContent
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [reWritingNote, setReWritingNote] = useState(false);
  const [reWriteTextBoxVisible, setReWriteTextBoxVisible] = useState(false);
  const [reWrittenNote, setReWrittenNote] = useState('');
  const sectionFinalized = `${section}_finalized`;

  const isSubjective = section === "subjective";


  return (
    <div className={`w-full transform transition-all relative bg-white px-5 py-4 ${!visibleFields[section] && "hidden"} ${isOpen[section] ? 'shadow-xl my-3 border-2 border-gray-500 min-h-96' : 'border border-gray-300 border-t-gray-100 hover:bg-gray-100'} cursor-pointer fade-in-text`} onClick={isOpen[section] ? null : () => toggleSection(section)}>
      
      <div onClick={(e) => {e.stopPropagation(); toggleSection(section)}} className={`flex justify-between items-center ${isOpen[section] && "border-b border-gray-300 pb-2"}`}>
        <div className='flex items-center'>
            {editableFields[sectionFinalized] ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNote(section, patientData.id, editableFields[sectionFinalized])}} className="text-primary mr-2 text-lg" /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNote(section, patientData.id, editableFields[sectionFinalized])}} className="text-gray-600 hover:text-primary mr-2 text-lg transition duration-150 ease-in-out" />}
            <p className={`text-lg ${isModified[section] && "text-red-800"}  tracking-wide`}>{sectionTitle} {isModified[section] && <span className='text-xs text-gray-300'>(editing)</span>}</p>
        </div>
        <div className='flex items-center'>
          <div className='flex items-center bg-gray-50 hover:bg-gray-300 text-gray-500 hover:text-gray-500 py-1 px-3 text-sm'>
            <p className='mr-2'>{isOpen[section] ? "Collapse" : "Expand"}</p>
            {isOpen[section] ? <AiOutlineShrink className='text-lg text-gray-500' /> : <AiOutlineExpandAlt className='text-lg text-gray-500' />  }
          </div>
        </div>
      </div>

      <div className={`flex items-start w-full h-full relative accordion-content ${isOpen[section] ? 'open mt-3' : ''}`}>

        <div onClick={(e) => e.stopPropagation()} className={`flex flex-col transition-all duration-500 ease-in-out ${isInteractiveToolsOpen[section] ? "pr-2 w-9/12" : 'w-full'}`}>

          <div className="flex justify-between items-center">

          <Box sx={{ display: 'flex', alignItems: 'center' }}>

            {/* Updated Button */}
            <Button
              variant="contained"
              disableElevation
              sx={{
                minWidth: '144px',
                backgroundColor: tabSelectedForSection[section] === "new" ? 'accent.main' : 'grey.100',
                color: tabSelectedForSection[section] === "new" ? 'primary.main' : 'text.secondary',
                '&:hover': {
                  backgroundColor: tabSelectedForSection[section] === "new" ? 'accent.main' : 'grey.200',
                  color: tabSelectedForSection[section] === "new" ? 'secondary.main' : 'text.primary',
                },
                borderLeft: tabSelectedForSection[section] === "new" ? 1 : 0,
                borderRight: tabSelectedForSection[section] === "new" ? 1 : 0,
                borderTop: tabSelectedForSection[section] === "new" ? 1 : 0,
                borderBottom: tabSelectedForSection[section] === "new" ? 1 : 0,
                borderBottomWidth: ".05px",
                borderColor: 'primary.main',
                textTransform: 'none',
                fontSize: '0.875rem',
                padding: '4px 8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius:0
              }}
              onClick={(e) => {
                e.stopPropagation();
                setTabSelectedForSection({ ...tabSelectedForSection, [section]: "new" });
              }}
            >
              {isModified[section] ? "With Changes" : "Updated"}
            </Button>

            {/* Save and Undo Buttons */}
            {tabSelectedForSection[section] === "new" && (
              <>
                <Button
                  startIcon={<Save />}
                  sx={{
                    minWidth: '80px',
                    backgroundColor: 'white',
                    color: isModified[section] ? 'primary.main' : 'text.disabled',
                    '&:hover': {
                      backgroundColor: isModified[section] ? 'green.50' : 'white',
                      color: isModified[section] ? 'secondary.main' : 'text.disabled',
                    },
                    border: 1,
                    borderColor: 'grey.100',
                    textTransform: 'none',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    padding: '4px 8px',
                    marginLeft: 1,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius:0
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    savePatientInfo(section);
                    updateSavedNoteDates(section, editableFields[section]);
                  }}
                  disabled={!isModified[section]}
                >
                  Save
                </Button>
                <Button
                  startIcon={<Undo />}
                  sx={{
                    minWidth: '80px',
                    backgroundColor: 'white',
                    color: isModified[section] ? 'error.main' : 'text.disabled',
                    '&:hover': {
                      backgroundColor: isModified[section] ? 'red.50' : 'white',
                      color: isModified[section] ? 'error.dark' : 'text.disabled',
                    },
                    border: 1,
                    borderColor: 'grey.100',
                    textTransform: 'none',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    padding: '4px 8px',
                    marginLeft: 1,
                    marginRight: 3,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius:0
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    revertChanges(section);
                  }}
                  disabled={!isModified[section]}
                >
                  Undo
                </Button>
              </>
            )}

          </Box>


            <div className={`flex items-center justify-end`}>

              <Button
                sx={{
                  minWidth: '144px',
                  marginRight: '8px',
                  display: !transcriptVisible[section] ? 'none' : 'flex',
                  backgroundColor: tabSelectedForSection[section] === "transcript" ? 'grey.300' : 'grey.100',
                  color: 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'grey.200',
                    color: 'text.primary',
                  },
                  borderLeft: tabSelectedForSection[section] === "transcript" ? 1 : 0,
                  borderRight: tabSelectedForSection[section] === "transcript" ? 1 : 0,
                  borderTop: tabSelectedForSection[section] === "transcript" ? 1 : 0,
                  borderColor: tabSelectedForSection[section] === "transcript" ? 'grey.700' : 'transparent',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  padding: '4px 8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  transition: 'all 150ms ease-in-out',
                  '&:focus': {
                    outline: 'none',
                  },
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius:0
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTabSelectedForSection({ ...tabSelectedForSection, [section]: "transcript" });
                }}
              >
                Transcript
              </Button>


              {/* Right tabs "Transcript" and "Original */}
              {patientData?.original_note && (
                <>
                  <Button
                    endIcon={<ArrowDropDown />}
                    sx={{
                      minWidth: '144px',
                      backgroundColor: tabSelectedForSection[section] === "previous" ? 'grey.300' : 'grey.100',
                      color: 'text.secondary',
                      '&:hover': {
                        backgroundColor: 'grey.200',
                        color: 'text.primary',
                      },
                      borderLeft: tabSelectedForSection[section] === "previous" ? 1 : 0,
                      borderRight: tabSelectedForSection[section] === "previous" ? 1 : 0,
                      borderTop: tabSelectedForSection[section] === "previous" ? 1 : 0,
                      borderColor: tabSelectedForSection[section] === "previous" ? 'grey.700' : 'transparent',
                      textTransform: 'none',
                      fontSize: '0.875rem',
                      padding: '4px 8px',
                      justifyContent: 'center',
                      transition: 'all 150ms ease-in-out',
                      '&:focus': {
                        outline: 'none',
                      },
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius:0
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    History
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    {savedNotes.length > 0 ? (
                      <>
                      <Tooltip title="Click to view" placement="left" arrow>
                        <MenuItem
                          onClick={() => {
                            fetchSavedNoteContent(savedNotes[0].id);
                            setAnchorEl(null);
                          }}
                          sx={{ fontSize: '0.75rem' }}
                        >
                          <Description sx={{fontSize: "1rem"}} className='text-gray-500 mr-2' />
                          Original
                        </MenuItem>
                      </Tooltip>
                      <Divider />
                      {savedNotes.slice(1).map((note) => (
                        <Tooltip key={note.id} title="Click to view" placement="left" arrow>
                          <MenuItem
                            onClick={() => {
                              fetchSavedNoteContent(note.id);
                              setAnchorEl(null);
                            }}
                            sx={{ fontSize: '0.75rem' }}
                          >
                            <Description sx={{fontSize: "1rem"}} className='text-gray-500 mr-2' />
                            {new Date(note.created_at).toLocaleDateString("en-US", {
                              weekday: "short",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}
                          </MenuItem>
                        </Tooltip>
                      ))}
                    </>
                    ) : (
                      <MenuItem disabled>
                        No notes available.
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}


            </div>
          </div>

          {tabSelectedForSection[section] === "new" && (
            <TextField
              multiline
              rows={9}
              autoFocus
              placeholder='Speak or type your notes here...'
              value={editableFields[section]}
              onChange={(e) => handleTextareaChange(section, e.target.value)}
              onClick={(e) => e.stopPropagation()}
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'primary.main',
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: { xs: '8px', sm: '16px' },
                  paddingRight: { sm: '40px' },
                  transition: 'width 500ms ease-in-out',
                  width: '100%',
                  backgroundColor: isModified[section] 
                    ? 'transparent'
                    : 'background.paper',
                  color: 'text.primary',
                  background: isModified[section] 
                    ? 'linear-gradient(to bottom left, #FFFFFF, #F5F5F5)'
                    : 'none',
                },
                '& .MuiInputBase-root': {
                  padding: 0,
                },
              }}
            />
          )}

          {/* Original Text Field */}
          {tabSelectedForSection[section] === "previous" && (
            <TextField
              multiline
              rows={9}
              placeholder='Speak or type your notes here...'
              value={patientData?.original_note ? patientData?.original_note[section] : "No original note found."}
              disabled
              fullWidth
              variant="outlined"
              onClick={(e) => e.stopPropagation()}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'grey.700',
                    borderWidth: '1px',
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: { xs: '8px', sm: '16px' },
                  paddingRight: { sm: '40px' },
                  transition: 'width 500ms ease-in-out',
                  width: '100%',
                },
                '& .MuiInputBase-root': {
                  padding: 0,
                },
              }}
            />
          )}

          {/* Transcript Text Field */}
          {tabSelectedForSection[section] === "transcript" && (
            <TextField
              multiline
              rows={9}
              placeholder='Speak or type your notes here...'
              value={transcript}
              disabled
              fullWidth
              variant="outlined"
              onClick={(e) => e.stopPropagation()}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'grey.700',
                    borderWidth: '1px',
                    borderLeftWidth: '1px',
                    borderBottomWidth: '1px',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: { xs: '8px', sm: '16px' },
                  paddingRight: { sm: '40px' },
                  transition: 'width 500ms ease-in-out',
                  width: '100%',
                  lineHeight: '1.5',
                },
                '& .MuiInputBase-root': {
                  padding: 0,
                },
              }}
            />
          )}

        </div>

        {/* AI box */}
        <div onClick={(e) => e.stopPropagation()} className={`${isInteractiveToolsOpen[section] ? "w-[60%] h-full max-h-96 mt-7 flex flex-col items-center justify-end border overflow-y-hidden" : "hidden"} rounded transform transition-all duration-200 ease-in-out ${(isInteractiveToolsOpen[section])  ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'} cursor-default`}>
          
          {interactiveToolSelected[section] && (
            <Tools 
              interactiveToolSelected={interactiveToolSelected[section]} 
              toggleInteractiveToolSelected={toggleInteractiveToolSelected}
              interactiveToolOpen={section}
              clearSelectedTools={() => setInteractiveToolSelected({ ...interactiveToolSelected, [section]: null})}
              saveQuestionToAsk={(question) => setInteractiveToolsValues({ ...interactiveToolsValues, [section]: { ...interactiveToolsValues[section], ask: question }})}
              askQuestion={handleAskAi}
              saveInstructionsToRewrite={(instructions) => setInteractiveToolsValues({ ...interactiveToolsValues, [section]: { ...interactiveToolsValues[section], rewrite: instructions }})}
              currentNote={editableFields[section]}
              reWriteNote={handleRewriteNote}
              onAccept={handleTextareaChange}
              setReWritingNote={setReWritingNote}
              setReWrittenNote={setReWrittenNote}
              setReWriteTextBoxVisible={setReWriteTextBoxVisible}
              setTabSelectedForSection={(value) => setTabSelectedForSection({ ...tabSelectedForSection, [section]: value })}
              setIsModified={setIsModified}
              setPreviousFields={setPreviousFields}
              editableFields={editableFields}
            />
          )}

        </div>

      </div>

      <Box 
        sx={{ 
          width: '100%', 
          display: isOpen[section] ? 'flex' : 'none',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<ContentCopy sx={{ fontSize: 12 }} />}
            onClick={(e) => { e.stopPropagation(); copyToClipboard(editableFields[section], section); }}
            sx={{
              minWidth: '80px',
              marginTop: '8px',
              backgroundColor: 'background.paper',
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: 'action.hover',
                color: 'text.primary',
              },
              textTransform: 'none',
              fontSize: '0.75rem',
              fontWeight: 500,
              padding: '4px 8px',
              borderColor: 'grey.300',
            }}
          >
            {copyDisplayText[section] ? "Copied!" : "Copy"}
          </Button>
          <Button
            variant="outlined"
            size="small"
            startIcon={<SpeakerNotes sx={{ fontSize: 16 }} />}
            onClick={(e) => { 
              e.stopPropagation(); 
              setTranscriptVisible({ ...setTranscriptVisible, [section]: !transcriptVisible[section] }); 
              !transcriptVisible[section] 
                ? setTabSelectedForSection({ ...tabSelectedForSection, [section]: "transcript" }) 
                : setTabSelectedForSection({ ...tabSelectedForSection, [section]: "new" });
            }}
            sx={{
              minWidth: '80px',
              marginTop: '8px',
              marginLeft: '8px',
              backgroundColor: 'background.paper',
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: 'action.hover',
                color: 'text.primary',
              },
              textTransform: 'none',
              fontSize: '0.75rem',
              fontWeight: 500,
              padding: '4px 8px',
              borderColor: 'grey.300',
            }}
          >
            {transcriptVisible[section] ? "Hide" : "View"} Transcript
          </Button>
        </Box>
        <Tooltip title="Click to use AI" placement="top" arrow>
          <Button
            variant="outlined"
            size="small"
            startIcon={<AutoFixHigh />}
            onClick={(e) => {
              e.stopPropagation();
              setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, [section]: !isInteractiveToolsOpen[section] });
              setInteractiveToolSelected({ ...interactiveToolSelected, [section]: "ask" });
            }}
            sx={{
              minWidth: '80px',
              marginTop: '8px',
              marginRight: '8px',
              backgroundColor: isInteractiveToolsOpen[section] ? 'primary.main' : 'background.paper',
              color: isInteractiveToolsOpen[section] ? 'common.white' : 'primary.main',
              '&:hover': {
                backgroundColor: isInteractiveToolsOpen[section] ? 'primary.dark' : 'action.hover',
                color: isInteractiveToolsOpen[section] ? 'common.white' : 'primary.main',
              },
              textTransform: 'none',
              fontSize: '0.75rem',
              fontWeight: 500,
              padding: '4px 8px',
              borderColor: 'grey.100',
              transition: 'all 150ms ease-in-out',
            }}
          >
            AI
          </Button>
        </Tooltip>
      </Box>

    </div>
  );
}
