import React, { useEffect, useState, useRef } from 'react';
import { FaUserShield, FaChevronDown, FaChevronUp, FaRobot, FaMicrophoneAlt, FaSave, FaCopy, FaTrash, FaWrench, FaRegSquare, FaCheck, FaCheckCircle, FaCheckSquare, FaChevronLeft, FaChevronRight, FaFile, FaFileAlt, FaQuestionCircle, FaMagic, FaFilePdf, FaTrashAlt, FaCalendarAlt, FaEllipsisV, FaEllipsisH, FaUndo, FaEye, FaEyeSlash, FaPlus, FaMinus, FaMinusCircle, FaPlusCircle, FaArrowAltCircleUp, FaArrowAltCircleRight, FaArrowAltCircleDown, FaCalendarDay, FaPlusSquare, FaSearch } from 'react-icons/fa';

import { jsPDF } from 'jspdf';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './PatientDetails.css'
import PatientDelete from '../PatientDocumentModals/PatientDelete';
import Badge from '../Badge/Badge';
import { AiFillCopy, AiFillEdit, AiFillRobot, AiFillTool, AiOutlineArrowRight, AiOutlineArrowUp, AiOutlineClose, AiOutlineCopy, AiOutlineExpand, AiOutlineExpandAlt, AiOutlineNodeCollapse, AiOutlineShrink } from 'react-icons/ai';
import Tools from './Components/SubComponents/Tools';
import ChatModal from './Components/SubComponents/ChatModal';
import ReWriteModal from './Components/SubComponents/ReWriteModal';
import Section from './Components/Section';
import NoteDetailsModal from './Components/NoteDetailsModal';
import { Delete, FiveK, Flag, FlagCircle, MoreHoriz, MoreHorizOutlined, MoreHorizTwoTone, MoreVert, PictureAsPdf, ViewAgenda, WarningAmber } from '@mui/icons-material';
import { Avatar, Box, Chip, Divider, IconButton, ListItem, ListItemText, Menu, MenuItem, ThemeProvider, Tooltip, Typography } from '@mui/material';
import { Warning } from 'postcss';
import theme from '../../theme/theme';

const PatientDetails = ({ patientData, patientsVisible, deletePatient, finalizeNotes, displayingPatientNotes, flagPatientVisit }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [savedNoteModalOpen, setSavedNoteModalOpen] = useState(false);
  const [selectedSavedNote, setSelectedSavedNote] = useState(null);

  // Badge States
  const [badgeVisible, setBadgeVisible] = useState(false);
  const [badgeMessage, setBadgeMessage] = useState('');

  // Voice Recognition States
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null); 

  // Copy to Clipboard State
  const [copyDisplayText, setCopyDisplayText] = useState({
    subjective: false,
    objective: false,
    assessment_and_plan: false,
    encounter: false,
    medications: false,
    labs: false,
    referrals: false
  });

  // Patient Info State
  const [patientInfo, setPatientInfo] = useState({
    noteDates: {
      subjective: [],
      objective: [],
      assessment_and_plan: [],
      encounter: [],
      medications: [],
      labs: [],
      referrals: []
    }
  });
  
  // Interactive Tools State
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const toggleChatModal = () => {setChatModalOpen(!chatModalOpen)};

  const [rewritingModalOpen, setRewritingModalOpen] = useState(false);
  const toggleRewritingModal = () => {setRewritingModalOpen(!rewritingModalOpen)};

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {setIsDropdownOpen(!isDropdownOpen)};


  const [isInteractiveToolsOpen, setIsInteractiveToolsOpen] = useState({
    subjective: false,
    objective: false,
    assessment_and_plan: false,
    encounter: false,
    medications: false,
    labs: false,
    referrals: false
  });

  const [interactiveToolSelected, setInteractiveToolSelected] = useState({
    subjective: null,
    objective: null,
    assessment_and_plan: null,
    encounter: null,
    medications: null,
    labs: null,
    referrals: null
  });

  const [interactiveToolsValues, setInteractiveToolsValues] = useState({
    subjective: {
      rewrite: '',
      add: '',
      ask: ''
    },
    objective: {
      rewrite: '',
      add: '',
      ask: ''
    },
    assessment_and_plan: {
      rewrite: '',
      add: '',
      ask: ''
    },
    encounter: {
      rewrite: '',
      add: '',
      ask: ''
    },
    medications: {
      rewrite: '',
      add: '',
      ask: ''
    },
    labs: {
      rewrite: '',
      add: '',
      ask: ''
    },
    referrals: {
      rewrite: '',
      add: '',
      ask: ''
    }
  });


  // useEffect(() => {
  //   console.log("Interactive Tools Values: ", interactiveToolsValues)
  // }, [interactiveToolsValues]);

  const [initialMessageAskAI, setInitialMessageAskAI] = useState(null);
  const [subjectAskAI, setSubjectAskAI] = useState(null);
  const [noteAskAI, setNoteAskiAI] = useState(null);

  const [currentNoteToRewrite, setCurrentNoteToRewrite] = useState(null);
  const [reWriteNoteInstructions, setReWriteNoteInstructions] = useState(null);
  const [subjectRewriteNote, setSubjectRewriteNote] = useState(null);
  const clearInteractiveToolsReWrite = (subject) => {
    setInteractiveToolsValues({ ...interactiveToolsValues, [subject]: { rewrite: '' } });
  };

  const [editableFields, setEditableFields] = useState({
    subjective: patientInfo.subjective || '',
    objective: patientInfo.objective || '',
    assessment_and_plan: patientInfo.assessment_and_plan || '',
    encounter: patientInfo.encounter || '',
    medications: patientInfo.medications || '',
    labs: patientInfo.labs || '',
    referrals: patientInfo.referrals || '',
    subjective_finalized: patientInfo.subjective_finalized,
    objective_finalized: patientInfo.objective_finalized,
    assessment_and_plan_finalized: patientInfo.assessment_and_plan_finalized,
    encounter_finalized: patientInfo.encounter_finalized,
    medications_finalized: patientInfo.medications_finalized,
    labs_finalized: patientInfo.labs_finalized,
    referrals_finalized: patientInfo.referrals_finalized,
    imaging_finalized: patientInfo.imaging_finalized
  });

  const [previousFields, setPreviousFields] = useState({
    subjective: "",
    objective: "",
    action: "",
    assessment_and_plan: "",
    encounter: "",
    medications: "",
    labs: "",
    referrals: ""
  });

  const [transcriptVisible, setTranscriptVisible] = useState({
    subjective: false,
    objective: false,
    assessment_and_plan: false,
    encounter: false,
    medications: false,
    labs: false,
    referrals: false
  
  });

  const [tabSelectedForSection, setTabSelectedForSection] = useState({
    subjective: "new",
    objective: "new",
    action: "new",
    assessment_and_plan: "new",
    encounter: "new",
    medications: "new",
    labs: "new",
    referrals: "new"
  });

  const [visibleFields, setVisibleFields] = useState({
    subjective: true,
    objective: true,
    assessment_and_plan: true,
    encounter: true,
    medications: true,
    labs: true,
    referrals: true,
  });

  const [visibleFieldsPanelOpen, setVisibleFieldsPanelOpen] = useState(false);

  const toggleField = (field) => {
    setVisibleFields({
      ...visibleFields,
      [field]: !visibleFields[field],
    });
  };

  const [lastModified, setLastModified] = useState(null)
  const [isModified, setIsModified] = useState({
    subjective: false,
    objective: false,
    action: false,
    assessment_and_plan: false,
    encounter: false,
    medications: false,
    labs: false,
    referrals: false
  });

  useEffect(() => {
    if (lastModified) {
      setPreviousFields((prevFields) => ({
        ...prevFields,
        [lastModified]: editableFields[lastModified],
      }));
    }else{
      setPreviousFields({
        subjective: null,
        objective: null,
        action: null,
        assessment_and_plan: null,
        encounter: null,
        medications: null,
        labs: null,
        referrals: null
      });
    }
  }, [lastModified]);

  // useEffect(() => {
  //   const updatedIsModified = {};
  
  //   for (const field in editableFields) {
  //     if (editableFields.hasOwnProperty(field)) {
  //       updatedIsModified[field] = editableFields[field] !== previousFields[field];
  //     }
  //   }
  
  //   setIsModified(updatedIsModified);
  // }, [editableFields, previousFields]);

  const [isOpen, setIsOpen] = useState({
    subjective: false,
    objective: false,
    assessment_and_plan: false,
    encounter: false,
    medications: false,
    labs: false,
    referrals: false
  });

  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const toggleDeleteModal = () => {setDeleteModalOpen(!deleteModalOpen)};

  const basicPatientInfo = {
    first_name: patientData.first_name,
    last_name: patientData.last_name,
    recording_date: patientData.recording_date,
    patient_visit_id: patientData.id,
    note_finalized: patientData.note_finalized
  }

  // Big func
  useEffect(() => {
    setPatientInfo({});
    setTabSelectedForSection({
      subjective: "new",
      objective: "new",
      action: "new",
      assessment_and_plan: "new",
      encounter: "new",
      medications: "new",
      labs: "new",
      referrals: "new"
    });
    console.log("patient info reset");

    const token = sessionStorage.getItem("token");

    axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-visit-info", { visit_id: patientData.id }, { withCredentials: true })
        .then(response => {
            const patientInfo = response.data;
            setPatientInfo(patientInfo);
            console.log("patient info: ", patientInfo);
            console.log("patient info added");

            // Extract all note IDs into a single array
            const allNoteIds = [
                ...(patientInfo.saved_subjective_ids || []),
                ...(patientInfo.saved_objective_ids || []),
                ...(patientInfo.saved_assessment_and_plan_ids || []),
                ...(patientInfo.saved_encounter_ids || []),
                ...(patientInfo.saved_medications_ids || []),
                ...(patientInfo.saved_labs_ids || []),
                ...(patientInfo.saved_referrals_ids || [])
            ];

            // Prepare a mapping of ID to note type for categorizing later
            const idToNoteType = {};
            (patientInfo.saved_subjective_ids || []).forEach(id => idToNoteType[id] = 'subjective');
            (patientInfo.saved_objective_ids || []).forEach(id => idToNoteType[id] = 'objective');
            (patientInfo.saved_assessment_and_plan_ids || []).forEach(id => idToNoteType[id] = 'assessment_and_plan');
            (patientInfo.saved_encounter_ids || []).forEach(id => idToNoteType[id] = 'encounter');
            (patientInfo.saved_medications_ids || []).forEach(id => idToNoteType[id] = 'medications');
            (patientInfo.saved_labs_ids || []).forEach(id => idToNoteType[id] = 'labs');
            (patientInfo.saved_referrals_ids || []).forEach(id => idToNoteType[id] = 'referrals');

            // Fetch created_at dates for the notes
            return axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-saved-notes-dates", { note_ids: allNoteIds }, { withCredentials: true })
                .then(({ data }) => ({ idToNoteType, dates: data }));
        })
        .then(({ idToNoteType, dates }) => {
            // Categorize dates based on note type
            const categorizedDates = {
                subjective: [],
                objective: [],
                assessment_and_plan: [],
                encounter: [],
                medications: [],
                labs: [],
                referrals: []
            };

            dates.forEach(note => {
                const noteType = idToNoteType[note.id];
                if (categorizedDates[noteType]) {
                    categorizedDates[noteType].push(note);
                }
            });

            setPatientInfo(prevInfo => ({
                ...prevInfo,
                noteDates: categorizedDates
            }));
            console.log("note dates added", categorizedDates);
        })
        .catch(error => {
            console.error("Error fetching user info or note dates:", error);
        })
  }, [patientData.id]);


  useEffect(() => {
    setEditableFields({
      subjective: patientInfo.subjective || '',
      objective: patientInfo.objective || '',
      assessment_and_plan: patientInfo.assessment_and_plan || '',
      encounter: patientInfo.encounter || '',
      medications: patientInfo.medications || '',
      labs: patientInfo.labs || '',
      referrals: patientInfo.referrals || '',
      subjective_finalized: patientInfo.subjective_finalized,
      objective_finalized: patientInfo.objective_finalized,
      assessment_and_plan_finalized: patientInfo.assessment_and_plan_finalized,
      encounter_finalized: patientInfo.encounter_finalized,
      medications_finalized: patientInfo.medications_finalized,
      labs_finalized: patientInfo.labs_finalized,
      referrals_finalized: patientInfo.referrals_finalized,
      imaging_finalized: patientInfo.imaging_finalized
    });
    console.log(patientInfo)
  }, [patientInfo]);
 

  // Function to update local editable fields state
  const updateNotes = async (field, value) => {
    setEditableFields(prevFields => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleTextareaChange = (field, value) => {
    // alert("CONTENT AND FIELD: " + value + " " + field)
    updateNotes(field, value);
    if(isModified[field] === false){
      setIsModified(prevState => ({ ...prevState, [field]: true }));
      setLastModified(field);
    }
  };

  const handleTextareaChangeAI = async (field, value) => {
    if(isModified[field] === false){
      setIsModified(prevState => ({ ...prevState, [field]: true }));
      setLastModified(field);
    }
    await updateNotes(field, value);

  };

  const finalizeNote = async (noteType, visitID, value) => {
    try{
      console.log("Patient ID: ", visitID)
      await finalizeNotes(noteType, visitID, value);
      setEditableFields(prevFields => ({
        ...prevFields,
        [`${noteType}_finalized`]: !value
      }));
    }catch{
      console.log("Error finalizing note")
    }
  };

  // Function to save changes for any of the editable fields
  const savePatientInfo = async (field) => {
    const updateData = {
      patient_visit_id: patientInfo.id, // Ensure you're using the correct ID
      [field]: editableFields[field],
    };

    try {
      const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-patient-info", updateData,
      { withCredentials: true }
      );
      
      // Update the patientInfo state with the response data to ensure UI consistency
      setPatientInfo(prev => ({
        ...prev,
        ...response.data,
      }));

      // Show the badge with a custom message after saving
      console.log("Show badge")
      setBadgeMessage('Your changes have been saved!');
      setBadgeVisible(true);

      setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, [field]: false });
      setIsModified(prevState => ({ ...prevState, [field]: false }));
      setLastModified(null);

      console.log("Patient info updated successfully");
    } catch (error) {
      // Handle error response
      console.error("Error updating patient info:", error);
      // Implement your error handling logic here. For example, you might want to show an error message to the user.
    }
  };

  const updateSavedNoteDates = (field, noteContent) => {
    const newNote = {
      id: `${new Date().getTime()}-${field}`, // Generate a unique ID, could be anything as per your backend
      created_at: new Date(), // Current timestamp
      content: noteContent
    };

    console.log("Field:" , field)
    console.log("Note Content: ", noteContent)

    if((!field) || (!noteContent)){
      console.log("Tried to save note, but operation did not have enough info to proceed");
      return;
    }

    setPatientInfo(prevInfo => ({
      ...prevInfo,
      noteDates: {
        ...prevInfo.noteDates,
        [field]: [...(prevInfo.noteDates[field] || []), newNote]
      }
    }));

    console.log("Note added to patientInfo.noteDates", newNote);
  };

  const saveRewrittenNote = async (field, rewrittenNote) => {
      const updateData = {
        patient_visit_id: patientInfo.id, // Ensure you're using the correct ID
        [field]: rewrittenNote,
      };
  
      try {
        const response = await axios.post("https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/update-patient-info", updateData,
        { withCredentials: true }
        );
        
        // Update the patientInfo state with the response data to ensure UI consistency
        setPatientInfo(prev => ({
          ...prev,
          ...response.data,
        }));
  
        // Show the badge with a custom message after saving
        console.log("Show badge")
        setBadgeMessage('Your changes have been saved!');
        setBadgeVisible(true);
  
        console.log("Patient info updated successfully");
      } catch (error) {
        // Handle error response
        console.error("Error updating patient info:", error);
        // Implement your error handling logic here. For example, you might want to show an error message to the user.
      }
  };

  const deletePatientVisit = async (visitId) => {
      deletePatient(patientData.id);
      setBadgeMessage('Visit deleted successfully!');
      setBadgeVisible(true);
  };

  const flagPatient = async (visitID) => {
    patientInfo.flagged = !patientInfo.flagged
    patientData.flagged = !patientData.flagged
    flagPatientVisit(visitID)
  }

  const fetchSavedNoteContent = async (noteId) => {
    try {
      const response = await axios.post(
        "https://kevin-ramsey-app-5f0685d676cf.herokuapp.com/retrieve-note-details",
        { note_id: noteId },
        { withCredentials: true }
      );
      setSelectedSavedNote(response.data);
      setSavedNoteModalOpen(true);
      return response.data;
    } catch (error) {
      console.error("Error fetching saved note content:", error);
      return null;
    }
  };

  const handleSavedNoteAccept = () => {
    // Implement your accept logic here
    console.log("Saved note accepted:", selectedSavedNote);
    setSavedNoteModalOpen(false);
  };

  const handleSavedNoteDecline = () => {
    // Implement your decline logic here
    console.log("Saved note declined:", selectedSavedNote);
    setSavedNoteModalOpen(false);
  };


  // Function to toggle open/close state
  const toggleSection = (section) => {
    setIsOpen(prev => ({ ...prev, [section]: !prev[section] }));

    // Close the interactive tools when closing the section
    // if (!isOpen[section]) {
    //   setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, [section]: false });
    // }
  };

  const copyToClipboard = (text, field) => {
      navigator.clipboard.writeText(text).then(() => {
        setCopyDisplayText((prevState) => ({ ...prevState, [field]: true }));
        setTimeout(() => setCopyDisplayText((prevState) => ({ ...prevState, [field]: false })), 2000);
      }).catch((err) => {
        alert('Could not copy text: ', err);
      });
  };
  
  const handleStartListening = (noteType) => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognitionInstance = new SpeechRecognition();
    recognitionInstance.continuous = true;
    recognitionInstance.interimResults = true;
    recognitionInstance.lang = 'en-US';
  
    let prevFinalTranscript = editableFields[noteType];
    let prevInterimTranscript = '';
  
    recognitionInstance.onresult = (event) => {
      let interimTranscript = '';
      let finalTranscript = '';
  
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptPart = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcriptPart;
          if (/[.!?]$/.test(finalTranscript)) {
            finalTranscript += ' ';
          }
        } else {
          interimTranscript += transcriptPart;
        }
      }
  
      if (finalTranscript !== '') {
        // Update the notes with the final transcript
        const updatedNotes = prevFinalTranscript + finalTranscript;
        handleTextareaChange(noteType, updatedNotes);
        prevFinalTranscript = updatedNotes;
        prevInterimTranscript = '';
      } else {
        // Update the notes with the interim transcript
        const updatedNotes = prevFinalTranscript + interimTranscript;
        handleTextareaChange(noteType, updatedNotes);
        prevInterimTranscript = interimTranscript;
      }
    };
  
    recognitionInstance.onend = () => {
      setIsListening(false);
    };
  
    recognitionInstance.start();
    setIsListening(true);
    setRecognition(recognitionInstance);
  };

  const handleStopListening = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
      // Focus on the textarea when stopping listening
      // textareaRef.current.focus();
    }
  };

  const toggleInteractiveToolSelected = (section, tool) => {
    setInteractiveToolSelected({ ...interactiveToolSelected, [section]: tool });
  }

  const handleAskAi = async (section) => {
    // Access the property dynamically using the value of `section`
    const askMessage = interactiveToolsValues[section].ask;
    setInitialMessageAskAI(askMessage);
    setSubjectAskAI(section);
    setNoteAskiAI(editableFields[section]);
    setChatModalOpen(true);
  }

  const handleRewriteNote = async (section) => {
    const rewriteInstructions = interactiveToolsValues[section].rewrite;
    setCurrentNoteToRewrite(editableFields[section]);
    setSubjectRewriteNote(section);
    setReWriteNoteInstructions(rewriteInstructions);
    setRewritingModalOpen(true);
  }

  const acceptRewrittenNote = async (section, rewrittenNote) => {
    await updateNotes(section, rewrittenNote);
    await saveRewrittenNote(section, rewrittenNote);
    setRewritingModalOpen(false);
  }

  const insertTextFromToolBoxIntoNote = (section, text) => {
    const currentText = editableFields[section];
    const updatedText = `${currentText}\n\n${text}`; // Added a line break between the texts
    updateNotes(section, updatedText);
    setIsModified(prevState => ({ ...prevState, [section]: true }));
  }
  
  const revertChanges = (field) => {
    updateNotes(field, previousFields[field]);
    console.log("Previous Fields: ", previousFields)
    setIsModified(prevState => ({ ...prevState, [field]: false }));
  }

  const generatePDF = () => {
    const titleCase = (str) => {
      return str.replace(/\_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };
  
    const nonFinalizedFields = Object.keys(editableFields).filter(key => !key.includes('_finalized') && visibleFields[key]);
  
    const doc = new jsPDF();
  
    // Set document properties
    doc.setProperties({
      title: 'Patient Information',
      subject: 'Medical Report',
      author: 'Doctor',
      keywords: 'patient, medical, report',
      creator: 'PDF Generator'
    });
  
    // Create a linear gradient image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradientWidth = doc.internal.pageSize.width;
    const gradientHeight = 40;
    canvas.width = gradientWidth;
    canvas.height = gradientHeight;
  
    const gradient = ctx.createLinearGradient(0, 0, gradientWidth, 0);
    gradient.addColorStop(0, '#1d4ed8');
    gradient.addColorStop(1, '#1e3a8a');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, gradientWidth, gradientHeight);
  
    const gradientDataUrl = canvas.toDataURL();
  
    // Add the gradient image as the header background
    doc.addImage(gradientDataUrl, 'PNG', 0, 0, doc.internal.pageSize.width, 40, undefined, 'FAST');
  
    // Header Content
    doc.setFontSize(20);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor("#FFFFFF"); // Dark blue color
    doc.text(`${patientData.first_name} ${patientData.last_name}`, 15, 20);
  
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor("#FFFFFF"); // Dark gray color for subtext
    doc.text(`${patientData.conversation_title || "No Title Found"}`, 15, 30);
  
    const visitDate = new Date(patientData.recording_date).toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    doc.text(`${visitDate}`, doc.internal.pageSize.width - 15, 30, { align: 'right' });
  
    // Start the notes section after the header on the same page
    let yPosition = 55; // Adjusted to provide some spacing after the header
    const pageHeight = doc.internal.pageSize.height;
    const margin = 15;
  
    nonFinalizedFields.forEach((field, index) => {
      const title = titleCase(field);
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor("#1A237E"); // Dark blue color for titles
      doc.text(title, 15, yPosition);
      yPosition += 10;
  
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.setTextColor("#333333"); // Dark gray color for text
  
      const textLines = doc.splitTextToSize(editableFields[field], 170);
      textLines.forEach(line => {
        if (yPosition > pageHeight - margin) {
          doc.addPage();
          yPosition = margin;
        }
        doc.text(line, 15, yPosition);
        yPosition += 8;
      });
  
      // Divider
      if (yPosition > pageHeight - margin) {
        doc.addPage();
        yPosition = margin;
      } else {
        yPosition += 6;
      }
  
      doc.setLineWidth(0.5);
      doc.setDrawColor("#CCCCCC"); // Light gray color for divider
      doc.setLineDash([]);
      doc.line(15, yPosition, 195, yPosition);
      yPosition += 12;
    });
  
    // Add footer with page numbers
    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor("#666666");
      doc.text(`Page ${i} of ${totalPages}`, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 10);
    }
  
    doc.save(`${patientData.first_name}-${visitDate}.pdf`);
  };

  const allSectionsClosed = () => {
    return !Object.values(isOpen).some(isOpen => isOpen);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
    

  return (
    <ThemeProvider theme={theme} >
    <div className={`bg-background ${patientsVisible ? "hidden sm:flex sm:justify-start sm:items-start sm:w-4/5 p-5 sm:px-10 pb-10" : "w-full sm:w-full flex justify-start sm:flex sm:justify-start sm:items-center p-5 sm:px-10 pb-10"} flex-col h-[100%] overflow-auto  scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-100`}>

    {/* --Sections Start-- */}

    {patientsVisible 
      ?
        (<>
          <div className='w-full flex justify-between text-primary font-semibold text-xl'>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid',
                borderColor: 'primary.main',
                px: 2,
                py: 1,
                margin: 0,
                backgroundColor: 'background.paper',
                boxShadow: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h5" fontWeight="bold" color="primary.main">
                    {patientData.first_name}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                    <FaCalendarDay style={{ fontSize: '0.875rem', marginRight: '0.5rem', color: 'rgba(0, 0, 0, 0.6)' }} />
                    <Typography variant="body2" color="text.secondary">
                      {new Date(patientData.recording_date).toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              
              {patientData.flagged && (
                <Chip
                  icon={<WarningAmber />}
                  label="Flagged"
                  size="small"
                  sx={{
                    ml:"5rem",
                    p: ".5rem"
                  }}
                />
              )}
            </Box>

            <IconButton
              aria-controls="patient-menu"
              aria-haspopup="true"
              disableTouchRipple
              onClick={handleClick}
              size="large"
              sx={{
                border: "solid #eceff1 1px",
                height: "3rem",
                width: "3rem"
              }}
            >
              <MoreHorizOutlined color='primary' />
            </IconButton>
            <Menu
              id="patient-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => { generatePDF(); handleClose(); }}>
                <ViewAgenda className='m-3' fontSize="small" />
                <ListItemText primary="Show Fields" />
              </MenuItem>
              <MenuItem onClick={() => { generatePDF(); handleClose(); }}>
                <PictureAsPdf className='m-3' fontSize="small" />
                <ListItemText primary="Download PDF" />
              </MenuItem>
              <MenuItem onClick={() => { flagPatient(patientData.id); handleClose(); }}>
                <WarningAmber className='m-3' fontSize="small" />
                <ListItemText primary={patientData.flagged ? "Remove Flag" : "Flag Patient"} />
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => { toggleDeleteModal(); handleClose(); }} sx={{ color: 'error.main' }}>
                <Delete className='m-3' fontSize="small" color="error" />
                <ListItemText primary="Delete Patient" />
              </MenuItem>
            </Menu>


          </div> 
        </>)
      :
        (<>
          <div className={`w-full max-w-full flex justify-between items-center border-b border-primary`}>

            <div className='min-w-[25%]  h-28 flex flex-col items-start p-4 bg-darkerBackground border-primary text-textPrimary border border-r-2 border-b-[3px] transition duration-300 ease-in-out'>
              <h3 className='font-semibold text-lg'>{patientData.first_name}</h3>  
              <p className='text-xs font-semibold text-gray-500'>{patientData.conversation_title ? patientData.conversation_title : "No Title Found"}</p>
              <div className='w-full flex justify-between'>
                <p className='flex items-center text-xs font-semibold text-gray-500 mt-3'>
                  <FaCalendarDay className='text-gray-500 text-xs mr-2' />
                  <strong className='mr-2'>Visit Date:</strong>{" "}
                  {new Date(patientData.recording_date).toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </p>
                <div className="flex space-x-2 text-xs ml-10 mt-2">
                  <Tooltip title="Double-click to copy" arrow>
                    <div
                      onDoubleClick={() => copyToClipboard(editableFields["subjective"], "subjective")}
                      className={`w-5 h-5 flex items-center justify-center rounded-full transition duration-300 ease-in-out font-medium cursor-pointer ${
                        patientData.subjective_finalized ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500 drop-shadow-md'}`}
                    >
                      S
                    </div>
                  </Tooltip>
                  <Tooltip title="Double-click to copy" arrow>
                    <div
                      onDoubleClick={() => copyToClipboard(editableFields["objective"], "objective")}
                      className={`w-5 h-5 flex items-center justify-center rounded-full transition duration-300 ease-in-out font-medium cursor-pointer ${
                        patientData.objective_finalized ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500 drop-shadow-md'
                      }`}
                    >
                      O
                    </div>
                  </Tooltip>
                  <Tooltip title="Double-click to copy" arrow>
                    <div
                      onDoubleClick={() => copyToClipboard(editableFields["assessment_and_plan"], "assessment_and_plan")}
                      className={`w-5 h-5 flex items-center justify-center rounded-full transition duration-300 ease-in-out font-medium cursor-pointer ${
                        patientData.assessment_and_plan_finalized ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500 drop-shadow-md'
                      }`}
                    >
                      A
                    </div>
                  </Tooltip>
                  <Tooltip title="Double-click to copy" arrow>
                    <div
                      onDoubleClick={() => copyToClipboard(editableFields["assessment_and_plan"], "assessment_and_plan")}
                      className={`w-5 h-5 flex items-center justify-center rounded-full transition duration-300 ease-in-out font-medium cursor-pointer ${
                        patientData.assessment_and_plan_finalized ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500 drop-shadow-md'
                      }`}
                    >
                      P
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>

            <IconButton
              aria-controls="patient-menu"
              aria-haspopup="true"
              disableTouchRipple
              onClick={handleClick}
              size="large"
              sx={{
                border: "solid #eceff1 1px",
                height: "3rem",
                width: "3rem"
              }}
            >
              <MoreHorizOutlined color='primary' />
            </IconButton>
            <Menu
              id="patient-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => { generatePDF(); handleClose(); }}>
                <ViewAgenda className='m-3' fontSize="small" />
                <ListItemText primary="Show Fields" />
              </MenuItem>
              <MenuItem onClick={() => { generatePDF(); handleClose(); }}>
                <PictureAsPdf className='m-3' fontSize="small" />
                <ListItemText primary="Download PDF" />
              </MenuItem>
              <MenuItem onClick={() => { flagPatient(patientData.id); handleClose(); }}>
                <WarningAmber className='m-3' fontSize="small" />
                <ListItemText primary={patientData.flagged ? "Remove Flag" : "Flag Patient"} />
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => { toggleDeleteModal(); handleClose(); }} sx={{ color: 'error.main' }}>
                <Delete className='m-3' fontSize="small" color="error" />
                <ListItemText primary="Delete Patient" />
              </MenuItem>
            </Menu>

          </div> 
        </>)
      }


  <div className={`w-full h-fit ${allSectionsClosed() ? `border border-primary ${patientsVisible ? "border-t-[1px]"  : "border-t-0"}  border-b-[4px] border-r-[3px]` : ""}`}>
      <Section
        section="subjective"
        sectionTitle="Subjective"
        patientData={patientData}
        toggleSection={toggleSection}
        isOpen={isOpen}
        previousFields={previousFields}
        setPreviousFields={setPreviousFields}
        visibleFields={visibleFields}
        editableFields={editableFields}
        isModified={isModified}
        finalizeNote={finalizeNote}
        tabSelectedForSection={tabSelectedForSection}
        setTabSelectedForSection={setTabSelectedForSection}
        savePatientInfo={savePatientInfo}
        revertChanges={revertChanges}
        isInteractiveToolsOpen={isInteractiveToolsOpen}
        setIsInteractiveToolsOpen={setIsInteractiveToolsOpen}
        interactiveToolSelected={interactiveToolSelected}
        toggleInteractiveToolSelected={toggleInteractiveToolSelected}
        setInteractiveToolSelected={setInteractiveToolSelected}
        setInteractiveToolsValues={setInteractiveToolsValues}
        handleAskAi={handleAskAi}
        handleRewriteNote={handleRewriteNote}
        handleTextareaChange={handleTextareaChange}
        handleTextareaChangeAI={handleTextareaChangeAI}
        copyToClipboard={copyToClipboard}
        copyDisplayText={copyDisplayText}
        interactiveToolsValues={interactiveToolsValues}
        subjectRewriteNote={subjectRewriteNote}
        setIsModified={setIsModified}
        transcript={patientInfo.transcript ? patientInfo.transcript : ""}
        setTranscriptVisible={setTranscriptVisible}
        transcriptVisible={transcriptVisible}
        savedNotes={patientInfo.noteDates?.subjective || []}
        updateSavedNoteDates={updateSavedNoteDates}
        fetchSavedNoteContent={fetchSavedNoteContent}
      />

      <Section
        section="objective"
        sectionTitle="Objective"
        patientData={patientData}
        toggleSection={toggleSection}
        isOpen={isOpen}
        previousFields={previousFields}
        setPreviousFields={setPreviousFields}
        visibleFields={visibleFields}
        editableFields={editableFields}
        isModified={isModified}
        finalizeNote={finalizeNote}
        tabSelectedForSection={tabSelectedForSection}
        setTabSelectedForSection={setTabSelectedForSection}
        savePatientInfo={savePatientInfo}
        revertChanges={revertChanges}
        isInteractiveToolsOpen={isInteractiveToolsOpen}
        setIsInteractiveToolsOpen={setIsInteractiveToolsOpen}
        interactiveToolSelected={interactiveToolSelected}
        toggleInteractiveToolSelected={toggleInteractiveToolSelected}
        setInteractiveToolSelected={setInteractiveToolSelected}
        setInteractiveToolsValues={setInteractiveToolsValues}
        handleAskAi={handleAskAi}
        handleRewriteNote={handleRewriteNote}
        handleTextareaChange={handleTextareaChange}
        copyToClipboard={copyToClipboard}
        copyDisplayText={copyDisplayText}
        interactiveToolsValues={interactiveToolsValues}
        subjectRewriteNote={subjectRewriteNote}
        setIsModified={setIsModified}
        transcript={patientInfo.transcript ? patientInfo.transcript : ""}
        setTranscriptVisible={setTranscriptVisible}
        transcriptVisible={transcriptVisible}
        savedNotes={patientInfo.noteDates?.objective || []}
        updateSavedNoteDates={updateSavedNoteDates}
        fetchSavedNoteContent={fetchSavedNoteContent}
      />

      <Section
        section="assessment_and_plan"
        sectionTitle="Assessment & Plan"
        patientData={patientData}
        toggleSection={toggleSection}
        isOpen={isOpen}
        previousFields={previousFields}
        setPreviousFields={setPreviousFields}
        visibleFields={visibleFields}
        editableFields={editableFields}
        isModified={isModified}
        finalizeNote={finalizeNote}
        tabSelectedForSection={tabSelectedForSection}
        setTabSelectedForSection={setTabSelectedForSection}
        savePatientInfo={savePatientInfo}
        revertChanges={revertChanges}
        isInteractiveToolsOpen={isInteractiveToolsOpen}
        setIsInteractiveToolsOpen={setIsInteractiveToolsOpen}
        interactiveToolSelected={interactiveToolSelected}
        toggleInteractiveToolSelected={toggleInteractiveToolSelected}
        setInteractiveToolSelected={setInteractiveToolSelected}
        setInteractiveToolsValues={setInteractiveToolsValues}
        handleAskAi={handleAskAi}
        handleRewriteNote={handleRewriteNote}
        handleTextareaChange={handleTextareaChange}
        copyToClipboard={copyToClipboard}
        copyDisplayText={copyDisplayText}
        interactiveToolsValues={interactiveToolsValues}
        subjectRewriteNote={subjectRewriteNote}
        setIsModified={setIsModified}
        transcript={patientInfo.transcript ? patientInfo.transcript : ""}
        setTranscriptVisible={setTranscriptVisible}
        transcriptVisible={transcriptVisible}
        savedNotes={patientInfo.noteDates?.assessment_and_plan || []}
        updateSavedNoteDates={updateSavedNoteDates}
        fetchSavedNoteContent={fetchSavedNoteContent}
      />

     <Section
        section="encounter"
        sectionTitle="Encounter"
        patientData={patientData}
        toggleSection={toggleSection}
        isOpen={isOpen}
        previousFields={previousFields}
        setPreviousFields={setPreviousFields}
        visibleFields={visibleFields}
        editableFields={editableFields}
        isModified={isModified}
        finalizeNote={finalizeNote}
        tabSelectedForSection={tabSelectedForSection}
        setTabSelectedForSection={setTabSelectedForSection}
        savePatientInfo={savePatientInfo}
        revertChanges={revertChanges}
        isInteractiveToolsOpen={isInteractiveToolsOpen}
        setIsInteractiveToolsOpen={setIsInteractiveToolsOpen}
        interactiveToolSelected={interactiveToolSelected}
        toggleInteractiveToolSelected={toggleInteractiveToolSelected}
        setInteractiveToolSelected={setInteractiveToolSelected}
        setInteractiveToolsValues={setInteractiveToolsValues}
        handleAskAi={handleAskAi}
        handleRewriteNote={handleRewriteNote}
        handleTextareaChange={handleTextareaChange}
        copyToClipboard={copyToClipboard}
        copyDisplayText={copyDisplayText}
        interactiveToolsValues={interactiveToolsValues}
        subjectRewriteNote={subjectRewriteNote}
        setIsModified={setIsModified}
        // 
        transcript={patientInfo.transcript ? patientInfo.transcript : ""}
        setTranscriptVisible={setTranscriptVisible}
        transcriptVisible={transcriptVisible}
        savedNotes={patientInfo.noteDates?.encounter || []}
        updateSavedNoteDates={updateSavedNoteDates}
        fetchSavedNoteContent={fetchSavedNoteContent}
      />
    {/*
      <Section
        section="medications"
        sectionTitle="Medications"
        patientData={patientData}
        toggleSection={toggleSection}
        isOpen={isOpen}
        previousFields={previousFields}
        setPreviousFields={setPreviousFields}
        visibleFields={visibleFields}
        editableFields={editableFields}
        isModified={isModified}
        finalizeNote={finalizeNote}
        tabSelectedForSection={tabSelectedForSection}
        setTabSelectedForSection={setTabSelectedForSection}
        savePatientInfo={savePatientInfo}
        revertChanges={revertChanges}
        isInteractiveToolsOpen={isInteractiveToolsOpen}
        setIsInteractiveToolsOpen={setIsInteractiveToolsOpen}
        interactiveToolSelected={interactiveToolSelected}
        toggleInteractiveToolSelected={toggleInteractiveToolSelected}
        setInteractiveToolSelected={setInteractiveToolSelected}
        setInteractiveToolsValues={setInteractiveToolsValues}
        handleAskAi={handleAskAi}
        handleRewriteNote={handleRewriteNote}
        handleTextareaChange={handleTextareaChange}
        copyToClipboard={copyToClipboard}
        copyDisplayText={copyDisplayText}
        interactiveToolsValues={interactiveToolsValues}
        subjectRewriteNote={subjectRewriteNote}
        setIsModified={setIsModified}
      />

      <Section
        section="labs"
        sectionTitle="Labs"
        patientData={patientData}
        toggleSection={toggleSection}
        isOpen={isOpen}
        previousFields={previousFields}
        setPreviousFields={setPreviousFields}
        visibleFields={visibleFields}
        editableFields={editableFields}
        isModified={isModified}
        finalizeNote={finalizeNote}
        tabSelectedForSection={tabSelectedForSection}
        setTabSelectedForSection={setTabSelectedForSection}
        savePatientInfo={savePatientInfo}
        revertChanges={revertChanges}
        isInteractiveToolsOpen={isInteractiveToolsOpen}
        setIsInteractiveToolsOpen={setIsInteractiveToolsOpen}
        interactiveToolSelected={interactiveToolSelected}
        toggleInteractiveToolSelected={toggleInteractiveToolSelected}
        setInteractiveToolSelected={setInteractiveToolSelected}
        setInteractiveToolsValues={setInteractiveToolsValues}
        handleAskAi={handleAskAi}
        handleRewriteNote={handleRewriteNote}
        handleTextareaChange={handleTextareaChange}
        copyToClipboard={copyToClipboard}
        copyDisplayText={copyDisplayText}
        interactiveToolsValues={interactiveToolsValues}
        subjectRewriteNote={subjectRewriteNote}
        setIsModified={setIsModified}
      />

      <Section
        section="referrals"
        sectionTitle="Referrals"
        patientData={patientData}
        toggleSection={toggleSection}
        isOpen={isOpen}
        previousFields={previousFields}
        setPreviousFields={setPreviousFields}
        visibleFields={visibleFields}
        editableFields={editableFields}
        isModified={isModified}
        finalizeNote={finalizeNote}
        tabSelectedForSection={tabSelectedForSection}
        setTabSelectedForSection={setTabSelectedForSection}
        savePatientInfo={savePatientInfo}
        revertChanges={revertChanges}
        isInteractiveToolsOpen={isInteractiveToolsOpen}
        setIsInteractiveToolsOpen={setIsInteractiveToolsOpen}
        interactiveToolSelected={interactiveToolSelected}
        toggleInteractiveToolSelected={toggleInteractiveToolSelected}
        setInteractiveToolSelected={setInteractiveToolSelected}
        setInteractiveToolsValues={setInteractiveToolsValues}
        handleAskAi={handleAskAi}
        handleRewriteNote={handleRewriteNote}
        handleTextareaChange={handleTextareaChange}
        copyToClipboard={copyToClipboard}
        copyDisplayText={copyDisplayText}
        interactiveToolsValues={interactiveToolsValues}
        subjectRewriteNote={subjectRewriteNote}
        setIsModified={setIsModified}
      /> */}
    </div>

      <PatientDelete isOpen={deleteModalOpen} onClose={toggleDeleteModal} basicPatientInfo={basicPatientInfo} onDelete={deletePatientVisit}  />
      <Badge message={badgeMessage} isVisible={badgeVisible} onClose={() => setBadgeVisible(false)}/>
      <ChatModal isOpen={chatModalOpen} onClose={toggleChatModal} initialMessage={initialMessageAskAI} subject={subjectAskAI} note={noteAskAI} />
      <ReWriteModal 
        isOpen={rewritingModalOpen} 
        onClose={() => toggleRewritingModal()} 
        currentNote={currentNoteToRewrite} 
        subject={subjectRewriteNote} 
        rewWriteInstructions={reWriteNoteInstructions} 
        onAccept={(note) => acceptRewrittenNote(subjectRewriteNote, note)}
        onEdit={() => {}} 
        clearValues={() => clearInteractiveToolsReWrite()}  
      />

      <NoteDetailsModal
        open={savedNoteModalOpen}
        handleClose={() => setSavedNoteModalOpen(false)}
        noteDetails={selectedSavedNote}
        onAccept={handleSavedNoteAccept}
        onDecline={() => setSavedNoteModalOpen(false)}
        handleTextareaChange={handleTextareaChange}
      />
    </div>
    </ThemeProvider>
  );
};

export default PatientDetails;


    {/* Subjective Section */}
    // <div className={`w-full transform transition-all relative bg-white px-5 py-4 ${!visibleFields["subjective"] && "hidden"} ${isOpen.subjective ? 'shadow-xl my-3 border-2 border-gray-500 min-h-96' : 'border border-gray-300 border-t-gray-100 hover:bg-gray-100'} cursor-pointer fade-in-text`} onClick={isOpen.subjective ? null : () => toggleSection('subjective')}>
      
    //   <div onClick={(e) => {e.stopPropagation(); toggleSection('subjective')}} className={`flex justify-between items-center ${isOpen.subjective && "border-b border-gray-300 pb-2"}`}>
    //     <div className='flex items-center'>
    //         {editableFields.subjective_finalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNote("subjective", patientData.id, editableFields.subjective_finalized)}} className="text-blue-500 mr-2 text-lg" /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNote("subjective", patientData.id, editableFields.subjective_finalized)}} className="text-gray-600 hover:text-blue-500 mr-2 text-lg transition duration-150 ease-in-out" />}
    //         <p className={`text-lg ${isModified.subjective && "text-red-800"}  tracking-wide`}>Subjective {isModified.subjective && <span className='text-xs text-gray-300'>(editing)</span>}</p>
    //     </div>
    //     <div className='flex items-center'>
    //       <div className='flex items-center bg-gray-50 hover:bg-gray-300 text-gray-500 hover:text-gray-500 py-1 px-3 text-sm'>
    //         <p className='mr-2'>{isOpen.subjective ? "Collapse" : "Expand"}</p>
    //         {isOpen.subjective ? <AiOutlineShrink className='text-lg text-gray-500' /> : <AiOutlineExpandAlt className='text-lg text-gray-500' />  }
    //       </div>
    //     </div>
    //   </div>

    //   <div className={`flex items-start w-ful h-full relative accordion-content ${isOpen.subjective ? 'open mt-3' : ''}`}>
    //     <div onClick={(e) => e.stopPropagation()} className={`flex flex-col transition-all duration-500 ease-in-out ${isInteractiveToolsOpen.subjective ? "pr-2 w-9/12" : 'w-full'}`}>
    //       <div className="flex justify-between items-center">
    //         <div className='flex items-center'>
    //           <button
    //             className={`flex items-center justify-center min-w-36 ${tabSelectedForSection.subjective === "new" ? "bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600 border-l border-r border-t border-blue-600 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"}  p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm`}
    //             onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, subjective: "new" })}}
    //           >
    //             {isModified.subjective ? "With Changes" : "Updated"}
    //           </button>
    //           {tabSelectedForSection.subjective === "new"  &&
    //             (<>
    //               <button
    //                 className={`flex items-center justify-center min-w-20 bg-none ${isModified.subjective ? "text-green-500 hover:bg-green-50 hover:text-green-600" : "text-gray-300"}   bg-white rounded-sm p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                 onClick={(e) => {e.stopPropagation(); savePatientInfo('subjective')}}
    //                 disabled={!isModified.subjective}
    //               >
    //                 <FaSave className='mr-1' /> Save
    //               </button>
    //               <button
    //                 className={`flex items-center justify-center min-w-20 bg-none ${isModified.subjective ? "text-red-500  bg-white  hover:bg-red-50 hover:text-red-600" : "text-gray-300"} rounded-sm p-1 px-2 mr-5 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                 onClick={(e) => {e.stopPropagation(); revertChanges('subjective')}}
    //                 disabled={!isModified.subjective}
    //               >
    //                 <FaUndo className='mr-1' /> Undo
    //               </button>
    //           </>)}
    //         </div>
    //         <div className='flex items-center justify-end'>
    //           <button
    //             className={`${isModified.subjective ? "flex" : "hidden"} items-center justify-center min-w-36 ${tabSelectedForSection.subjective === "previous" ? "bg-gray-300 text-gray-500 hover:bg-gray-200 hover:text-gray-600 border-l border-r border-t border-gray-700 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"} p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm text-center fade-in-header`}
    //             onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, subjective: "previous" })}}
    //           >
    //             Original
    //           </button>
    //         </div>
    //       </div>
    //         {tabSelectedForSection.subjective === "new" ? 
    //           (<>
    //             <textarea
    //               rows={9}
    //               autoFocus
    //               placeholder='Speak or type your notes here...'
    //               value={editableFields.subjective}
    //               onChange={(e) => handleTextareaChange('subjective', e.target.value)}
    //               className={`p-2 sm:p-4 sm:pr-10 outline-none transition-width duration-500 ease-in-out w-full ${isModified.subjective ? "border border-blue-600 bg-gradient-to-bl from-white to-blue-50 text-gray-600" : "border border-blue-600 bg-white text-gray-600"} `}
    //               onClick={(e) => e.stopPropagation()}
    //             ></textarea>
    //           </>) 
    //             : 
    //           (<>
    //               <textarea
    //                 rows={9}
    //                 placeholder='Speak or type your notes here...'
    //                 value={previousFields.subjective}
    //                 disabled
    //                 className={`p-2 sm:p-4 sm:pr-10 outline-none transition-width duration-500 ease-in-out w-full border-l-[1px] border-b-[1px] border-r border-t border-gray-700 bg-gradient-to-bl from-white to-gray-300`}
    //                 onClick={(e) => e.stopPropagation()}
    //               ></textarea>
    //         </>)}
    //     </div>
    //     <div onClick={(e) => e.stopPropagation()} className={`${isInteractiveToolsOpen.subjective ? "w-1/4 h-full max-h-96 flex flex-col items-center justify-end border" : "hidden"} rounded transform transition-all duration-200 ease-in-out ${(isInteractiveToolsOpen.subjective)  ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'} cursor-default`}>
    //       {interactiveToolSelected.subjective && (
    //         <Tools 
    //           interactiveToolSelected={interactiveToolSelected.subjective} 
    //           toggleInteractiveToolSelected={toggleInteractiveToolSelected}
    //           interactiveToolOpen={"subjective"}
    //           clearSelectedTools={() => setInteractiveToolSelected({ ...interactiveToolSelected, subjective: null})}
    //           saveQuestionToAsk={(question) => setInteractiveToolsValues({ ...interactiveToolsValues, subjective: { ...interactiveToolsValues.subjective, ask: question }})}
    //           askQuestion={handleAskAi}
    //           saveInstructionsToRewrite={(instructions) => setInteractiveToolsValues({ ...interactiveToolsValues, subjective: { ...interactiveToolsValues.subjective, rewrite: instructions }})}
    //           currentNote={editableFields.subjective}
    //           reWriteNote={handleRewriteNote}
    //           onAccept={(note) => handleTextareaChange(subjectRewriteNote, note)}
    //           />
    //       )}
    //     </div>

    //   </div>

    //   <div className={`w-full flex ${!isOpen.subjective && "hidden"} justify-between items-center`}>
    //     <button
    //         className={`flex items-center justify-center min-w-20 mt-2 bg-none text-gray-500  bg-white  hover:bg-gray-50 hover:text-gray-600 rounded-sm p-1 px-2  transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-300 font-medium`}
    //         onClick={(e) => {e.stopPropagation(); copyToClipboard(editableFields.subjective, "subjective");}}
    //       >
    //       <FaCopy className="mr-1" /> {copyDisplayText.subjective ? "Copied!" : "Copy"}
    //     </button>
    //     <button
    //       className={`flex items-center justify-center mt-2 min-w-20 bg-none ${isInteractiveToolsOpen.subjective ? "text-white bg-blue-500 hover:bg-gray-700" : "text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500"} rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text`}
    //       onClick={isInteractiveToolsOpen.subjective ? (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, subjective: false }); setInteractiveToolSelected({ ...interactiveToolSelected, subjective: "ask" })}  : (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, subjective: true }); setInteractiveToolSelected({ ...interactiveToolSelected, subjective: "ask" })}}
    //       >
    //         <FaMagic className='mr-1' /> AI
    //     </button>
    //   </div>

    // </div>

    // {/* Objective Section */}
    // <div className={`w-full transform transition-all relative bg-white px-5 py-4 ${!visibleFields["objective"] && "hidden"} ${isOpen.objective ? 'shadow-xl my-3 border-2 border-gray-500' : 'border border-gray-300 border-t-gray-100 hover:bg-gray-100'} cursor-pointer fade-in-text`} onClick={isOpen.objective ? null : () => toggleSection('objective')}>
      
    //   <div onClick={(e) => {e.stopPropagation(); toggleSection('objective');}} className='flex justify-between items-center '>
    //     <div className='flex items-center'>
    //       {editableFields.objective_finalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNote("objective", patientData.id, editableFields.objective_finalized)}} className="text-blue-500 mr-2 text-lg" /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNote("objective", patientData.id, editableFields.objective_finalized)}} className="text-gray-600 hover:text-blue-500 mr-2 text-lg transition duration-150 ease-in-out" />}
    //       <p className={`text-lg tracking-wide ${isModified.objective && "text-red-800"}`}>Objective {isModified.objective && <span className='text-xs text-gray-300'>(editing)</span>}</p>
    //     </div>

    //     <div className='flex items-center'>
    //       <div className='flex items-center bg-gray-50 hover:bg-gray-300 text-gray-500 hover:text-gray-500 py-1 px-3 text-sm'>
    //         <p className='mr-2'>{isOpen.subjective ? "Collapse" : "Expand"}</p>
    //         {isOpen.subjective ? <AiOutlineShrink className='text-lg text-gray-500' /> : <AiOutlineExpandAlt className='text-lg text-gray-500' />  }
    //       </div>
    //     </div>
    //   </div>

    //   <div className={`flex items-end w-full relative accordion-content ${isOpen.objective ? 'open mt-3' : ''}`}>
    //     <div onClick={(e) => e.stopPropagation()} className={`flex flex-col transition-all duration-500 ease-in-out ${isInteractiveToolsOpen.objective ? "pr-2 w-9/12" : 'w-full'}`}>
    //       <div className="flex justify-between items-center">
    //           <div className='flex items-center'>
    //             <button
    //               className={`flex items-center justify-center min-w-36 ${tabSelectedForSection.objective === "new" ? "bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600 border-l border-r border-t border-blue-600 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"}  p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, objective: "new" })}}
    //             >
    //               {isModified.objective ? "With Changes" : "Updated"}
    //             </button>
    //             {tabSelectedForSection.objective === "new"  &&
    //               (<>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.objective ? "text-green-500 hover:bg-green-50 hover:text-green-600" : "text-gray-300"}   bg-white rounded-sm p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); savePatientInfo('objective')}}
    //                   disabled={!isModified.objective}
    //                 >
    //                   <FaSave className='mr-1' /> Save
    //                 </button>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.objective ? "text-red-500  bg-white  hover:bg-red-50 hover:text-red-600" : "text-gray-300"} rounded-sm p-1 px-2 mr-5 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); revertChanges('objective')}}
    //                   disabled={!isModified.objective}
    //                 >
    //                   <FaUndo className='mr-1' /> Undo
    //                 </button>
    //             </>)}
    //           </div>
    //           <div className='flex items-center justify-end'>
    //             <button
    //               className={`${isModified.objective ? "flex" : "hidden"} items-center justify-center min-w-36 ${tabSelectedForSection.objective === "previous" ? "bg-gray-300 text-gray-500 hover:bg-gray-200 hover:text-gray-600 border-l border-r border-t border-gray-700 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"} p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm text-center fade-in-header`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, objective: "previous" })}}
    //             >
    //               Original
    //             </button>
    //           </div>
    //         </div>
    //       {tabSelectedForSection.objective === "new" ? 
    //         (<>
    //           <textarea
    //             rows={9}
    //             autoFocus
    //             placeholder='Speak or type your notes here...'
    //             value={editableFields.objective}
    //             onChange={(e) => handleTextareaChange('objective', e.target.value)}
    //             className={`p-2 sm:p-4 sm:pr-10 outline-none transition-width duration-500 ease-in-out w-full ${isModified.objective ? "border border-blue-600 bg-gradient-to-bl from-white to-blue-50 text-gray-600" : "border border-blue-600 bg-white text-gray-600"} `}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>) 
    //           : 
    //         (<>
    //           <textarea
    //             rows={9}
    //             placeholder='Speak or type your notes here...'
    //             value={previousFields.objective}
    //             disabled
    //             className={`p-2 sm:p-4 sm:pr-20 outline-none transition-width duration-500 ease-in-out w-full border-l-[1px] border-b-[1px] border-cool-gray-200 bg-white}`}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>)}
    //     </div>
    //     <div onClick={(e) => e.stopPropagation()} className={`${isInteractiveToolsOpen.objective ? "w-1/4 flex flex-col items-center justify-center border" : "hidden"} rounded transform transition-all duration-200 ease-in-out ${(isInteractiveToolsOpen.objective)  ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'} cursor-default`}>
    //       {interactiveToolSelected.objective && (
    //         <Tools 
    //           interactiveToolSelected={interactiveToolSelected.objective} 
    //           toggleInteractiveToolSelected={toggleInteractiveToolSelected}
    //           interactiveToolOpen={"objective"}
    //           clearSelectedTools={() => setInteractiveToolSelected({ ...interactiveToolSelected, objective: null})}
    //           saveQuestionToAsk={(question) => setInteractiveToolsValues({ ...interactiveToolsValues, objective: { ...interactiveToolsValues.objective, ask: question }})}
    //           askQuestion={handleAskAi}
    //           saveInstructionsToRewrite={(instructions) => setInteractiveToolsValues({ ...interactiveToolsValues, objective: { ...interactiveToolsValues.objective, rewrite: instructions }})}
    //           currentNote={editableFields.objective}
    //           reWriteNote={handleRewriteNote}
    //           onAccept={(note) => handleTextareaChange(subjectRewriteNote, note)}
    //           />
    //       )}
    //     </div>
    //   </div>

    //   <div className={`w-full flex ${!isOpen.objective && "hidden"} justify-between items-center`}>
    //     <button
    //         className={`flex items-center justify-center min-w-20 mt-2 bg-none text-gray-500  bg-white  hover:bg-gray-50 hover:text-gray-600 rounded-sm p-1 px-2  transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-300 font-medium`}
    //         onClick={(e) => {e.stopPropagation(); copyToClipboard(editableFields.objective, "objective");}}
    //       >
    //       <FaCopy className="mr-1" /> {copyDisplayText.objective ? "Copied!" : "Copy"}
    //     </button>
    //     <button
    //       className={`flex items-center justify-center mt-2 min-w-20 bg-none ${isInteractiveToolsOpen.objective ? "text-white bg-blue-500 hover:bg-gray-700" : "text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500"} rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text`}
    //       onClick={isInteractiveToolsOpen.objective ? (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, objective: false }); setInteractiveToolSelected({ ...interactiveToolSelected, objective: "ask" })}  : (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, objective: true }); setInteractiveToolSelected({ ...interactiveToolSelected, objective: "ask" })}}
    //       >
    //         <FaMagic className='mr-1' /> AI
    //     </button>
    //   </div>



    // </div>

    // {/* Assessment & Plan */}
    // <div className={`w-full transform transition-all relative bg-white px-5 py-4 ${!visibleFields["assessment_and_plan"] && "hidden"} ${isOpen.assessment_and_plan ? 'shadow-xl my-3 border-2 border-gray-500' : 'border border-gray-300 border-t-gray-100 hover:bg-gray-100'} cursor-pointer fade-in-text`} onClick={isOpen.assessment_and_plan ? null : () => toggleSection('assessment_and_plan')}>
      
    //   <div onClick={(e)=> {e.stopPropagation(); toggleSection("assessment_and_plan")}} className='flex justify-between items-center'>
    //     <div className='flex items-center'>
    //       {editableFields.assessment_and_plan_finalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNote("assessment_and_plan", patientData.id, editableFields.assessment_and_plan_finalized)}} className="text-blue-500 mr-2 text-lg" /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNote("assessment_and_plan", patientData.id, editableFields.assessment_and_plan_finalized)}} className="text-gray-600 hover:text-blue-500 mr-2 text-lg transition duration-150 ease-in-out" />}
    //       <p className={`text-lg ${isModified.assessment_and_plan && "text-red-800"} tracking-wide`}>Assessment & Plan {isModified.assessment_and_plan && <span className='text-xs text-gray-300'>(editing)</span>}</p>
    //     </div>
    //     <div className='flex items-center'>
    //       <div className='flex items-center bg-gray-50 hover:bg-gray-300 text-gray-500 hover:text-gray-500 py-1 px-3 text-sm'>
    //         <p className='mr-2'>{isOpen.subjective ? "Collapse" : "Expand"}</p>
    //         {isOpen.subjective ? <AiOutlineShrink className='text-lg text-gray-500' /> : <AiOutlineExpandAlt className='text-lg text-gray-500' />  }
    //       </div>
    //     </div>
    //   </div>

    //   <div className={`flex items-end w-full relative accordion-content ${isOpen.assessment_and_plan ? 'open mt-3' : ''}`}>
    //     <div onClick={(e) => e.stopPropagation()} className={`flex flex-col transition-all duration-500 ease-in-out ${isInteractiveToolsOpen.assessment_and_plan ? "pr-2 w-9/12" : 'w-full'}`}>
    //       <div className="flex justify-between items-center">
    //           <div className='flex items-center'>
    //             <button
    //               className={`flex items-center justify-center min-w-36 ${tabSelectedForSection.assessment_and_plan === "new" ? "bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600 border-l border-r border-t border-blue-600 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"}  p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, assessment_and_plan: "new" })}}
    //             >
    //               {isModified.assessment_and_plan ? "With Changes" : "Updated"}
    //             </button>
    //             {tabSelectedForSection.assessment_and_plan === "new"  &&
    //               (<>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.assessment_and_plan ? "text-green-500 hover:bg-green-50 hover:text-green-600" : "text-gray-300"}   bg-white rounded-sm p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); savePatientInfo('assessment_and_plan')}}
    //                   disabled={!isModified.assessment_and_plan}
    //                 >
    //                   <FaSave className='mr-1' /> Save
    //                 </button>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.assessment_and_plan ? "text-red-500  bg-white  hover:bg-red-50 hover:text-red-600" : "text-gray-300"} rounded-sm p-1 px-2 mr-5 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); revertChanges('assessment_and_plan')}}
    //                   disabled={!isModified.assessment_and_plan}
    //                 >
    //                   <FaUndo className='mr-1' /> Undo
    //                 </button>
    //             </>)}
    //           </div>
    //           <div className='flex items-center justify-end'>
    //             <button
    //               className={`${isModified.assessment_and_plan ? "flex" : "hidden"} items-center justify-center min-w-36 ${tabSelectedForSection.assessment_and_plan === "previous" ? "bg-gray-300 text-gray-500 hover:bg-gray-200 hover:text-gray-600 border-l border-r border-t border-gray-700 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"} p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm text-center fade-in-header`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, assessment_and_plan: "previous" })}}
    //             >
    //               Original
    //             </button>
    //           </div>
    //         </div>
    //       {tabSelectedForSection.assessment_and_plan === "new" ? 
    //         (<>
    //           <textarea
    //             rows={9}
    //             autoFocus
    //             placeholder='Speak or type your notes here...'
    //             value={editableFields.assessment_and_plan}
    //             onChange={(e) => handleTextareaChange('assessment_and_plan', e.target.value)}
    //             className={`p-2 sm:p-4 sm:pr-10 outline-none transition-width duration-500 ease-in-out w-full ${isModified.assessment_and_plan ? "border border-blue-600 bg-gradient-to-bl from-white to-blue-50 text-gray-600" : "border border-blue-600 bg-white text-gray-600"} `}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>) 
    //           : 
    //         (<>
    //           <textarea
    //             rows={9}
    //             placeholder='Speak or type your notes here...'
    //             value={previousFields.assessment_and_plan}
    //             disabled
    //             className={`p-2 sm:p-4 sm:pr-20 outline-none transition-width duration-500 ease-in-out w-full border-l-[1px] border-b-[1px] border-cool-gray-200 bg-white}`}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>)}
    //     </div>
    //     <div onClick={(e) => e.stopPropagation()} className={`${isInteractiveToolsOpen.assessment_and_plan ? "w-1/4 flex flex-col items-center justify-center border" : "hidden"} rounded transform transition-all duration-200 ease-in-out ${(isInteractiveToolsOpen.assessment_and_plan)  ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'} cursor-default`}>
    //       {interactiveToolSelected.assessment_and_plan && (
    //         <Tools 
    //           interactiveToolSelected={interactiveToolSelected.assessment_and_plan} 
    //           toggleInteractiveToolSelected={toggleInteractiveToolSelected}
    //           interactiveToolOpen={"assessment_and_plan"}
    //           clearSelectedTools={() => setInteractiveToolSelected({ ...interactiveToolSelected, assessment_and_plan: null})}
    //           saveQuestionToAsk={(question) => setInteractiveToolsValues({ ...interactiveToolsValues, assessment_and_plan: { ...interactiveToolsValues.assessment_and_plan, ask: question }})}
    //           askQuestion={handleAskAi}
    //           saveInstructionsToRewrite={(instructions) => setInteractiveToolsValues({ ...interactiveToolsValues, assessment_and_plan: { ...interactiveToolsValues.assessment_and_plan, rewrite: instructions }})}
    //           currentNote={editableFields.assessment_and_plan}
    //           reWriteNote={handleRewriteNote}
    //           onAccept={(note) => handleTextareaChange(subjectRewriteNote, note)}
    //           />
    //       )}
    //     </div>
    //   </div>

    //   <div className={`w-full flex ${!isOpen.assessment_and_plan && "hidden"} justify-between items-center`}>
    //     <button
    //         className={`flex items-center justify-center min-w-20 mt-2 bg-none text-gray-500  bg-white  hover:bg-gray-50 hover:text-gray-600 rounded-sm p-1 px-2  transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-300 font-medium`}
    //         onClick={(e) => {e.stopPropagation(); copyToClipboard(editableFields.assessment_and_plan, "assessment_and_plan");}}
    //       >
    //       <FaCopy className="mr-1" /> {copyDisplayText.assessment_and_plan ? "Copied!" : "Copy"}
    //     </button>
    //     <button
    //       className={`flex items-center justify-center mt-2 min-w-20 bg-none ${isInteractiveToolsOpen.assessment_and_plan ? "text-white bg-blue-500 hover:bg-gray-700" : "text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500"} rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text`}
    //       onClick={isInteractiveToolsOpen.assessment_and_plan ? (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, assessment_and_plan: false }); setInteractiveToolSelected({ ...interactiveToolSelected, assessment_and_plan: "ask" })}  : (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, assessment_and_plan: true }); setInteractiveToolSelected({ ...interactiveToolSelected, assessment_and_plan: "ask" })}}
    //       >
    //         <FaMagic className='mr-1' /> AI
    //     </button>
    //   </div>

    // </div>

    // {/* Encounter Section */}
    // <div className={`w-full transform transition-all relative bg-white px-5 py-4 ${!visibleFields["encounter"] && "hidden"} ${isOpen.encounter ? 'shadow-xl my-3 border-2 border-gray-500' : 'border border-gray-300 border-t-gray-100 hover:bg-gray-100'} cursor-pointer fade-in-text`} onClick={isOpen.encounter ? null : () => toggleSection('encounter')}>
      
    //   <div onClick={(e)=> {e.stopPropagation(); toggleSection("encounter")}} className='flex justify-between items-center'>
    //     <div className='flex items-center'>
    //       {editableFields.encounter_finalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNote("encounter", patientData.id, editableFields.encounter_finalized)}} className="text-blue-500 mr-2 text-lg" /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNote("encounter", patientData.id, editableFields.encounter_finalized)}} className="text-gray-600 hover:text-blue-500 mr-2 text-lg transition duration-150 ease-in-out" />}
    //       <p className={`text-lg ${isModified.encounter && "text-red-800"} tracking-wide`}>Encounter {isModified.encounter && <span className='text-xs text-gray-300'>(editing)</span>}</p>
    //     </div>
    //     <div className='flex items-center'>
    //       <div className='flex items-center bg-gray-50 hover:bg-gray-300 text-gray-500 hover:text-gray-500 py-1 px-3 text-sm'>
    //         <p className='mr-2'>{isOpen.subjective ? "Collapse" : "Expand"}</p>
    //         {isOpen.subjective ? <AiOutlineShrink className='text-lg text-gray-500' /> : <AiOutlineExpandAlt className='text-lg text-gray-500' />  }
    //       </div>
    //     </div>     
    //   </div>

    //   <div className={`flex items-end w-full relative accordion-content ${isOpen.encounter ? 'open mt-3' : ''}`}>
    //     <div onClick={(e) => e.stopPropagation()} className={`flex flex-col transition-all duration-500 ease-in-out ${isInteractiveToolsOpen.encounter ? "pr-2 w-9/12" : 'w-full'}`}>
    //       <div className="flex justify-between items-center">
    //           <div className='flex items-center'>
    //             <button
    //               className={`flex items-center justify-center min-w-36 ${tabSelectedForSection.encounter === "new" ? "bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600 border-l border-r border-t border-blue-600 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"}  p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, encounter: "new" })}}
    //             >
    //               {isModified.encounter ? "With Changes" : "Updated"}
    //             </button>
    //             {tabSelectedForSection.encounter === "new"  &&
    //               (<>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.encounter ? "text-green-500 hover:bg-green-50 hover:text-green-600" : "text-gray-300"}   bg-white rounded-sm p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); savePatientInfo('encounter')}}
    //                   disabled={!isModified.encounter}
    //                 >
    //                   <FaSave className='mr-1' /> Save
    //                 </button>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.encounter ? "text-red-500  bg-white  hover:bg-red-50 hover:text-red-600" : "text-gray-300"} rounded-sm p-1 px-2 mr-5 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); revertChanges('encounter')}}
    //                   disabled={!isModified.encounter}
    //                 >
    //                   <FaUndo className='mr-1' /> Undo
    //                 </button>
    //             </>)}
    //           </div>
    //           <div className='flex items-center justify-end'>
    //             <button
    //               className={`${isModified.encounter ? "flex" : "hidden"} items-center justify-center min-w-36 ${tabSelectedForSection.encounter === "previous" ? "bg-gray-300 text-gray-500 hover:bg-gray-200 hover:text-gray-600 border-l border-r border-t border-gray-700 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"} p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm text-center fade-in-header`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, encounter: "previous" })}}
    //             >
    //               Original
    //             </button>
    //           </div>
    //       </div>
    //       {tabSelectedForSection.encounter === "new" ? 
    //         (<>
    //           <textarea
    //             rows={9}
    //             autoFocus
    //             placeholder='Speak or type your notes here...'
    //             value={editableFields.encounter}
    //             onChange={(e) => handleTextareaChange('encounter', e.target.value)}
    //             className={`p-2 sm:p-4 sm:pr-10 outline-none transition-width duration-500 ease-in-out w-full ${isModified.encounter ? "border border-blue-600 bg-gradient-to-bl from-white to-blue-50 text-gray-600" : "border border-blue-600 bg-white text-gray-600"} `}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>) 
    //           : 
    //         (<>
    //           <textarea
    //             rows={9}
    //             placeholder='Speak or type your notes here...'
    //             value={previousFields.encounter}
    //             disabled
    //             className={`p-2 sm:p-4 sm:pr-20 outline-none transition-width duration-500 ease-in-out w-full border-l-[1px] border-b-[1px] border-cool-gray-200 bg-white}`}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>)}
    //     </div>
    //     <div onClick={(e) => e.stopPropagation()} className={`${isInteractiveToolsOpen.encounter ? "w-1/4 flex flex-col items-center justify-center border" : "hidden"} rounded transform transition-all duration-200 ease-in-out ${(isInteractiveToolsOpen.encounter)  ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'} cursor-default`}>
    //       {interactiveToolSelected.encounter && (
    //         <Tools 
    //           interactiveToolSelected={interactiveToolSelected.encounter} 
    //           toggleInteractiveToolSelected={toggleInteractiveToolSelected}
    //           interactiveToolOpen={"encounter"}
    //           clearSelectedTools={() => setInteractiveToolSelected({ ...interactiveToolSelected, encounter: null})}
    //           saveQuestionToAsk={(question) => setInteractiveToolsValues({ ...interactiveToolsValues, encounter: { ...interactiveToolsValues.encounter, ask: question }})}
    //           askQuestion={handleAskAi}
    //           saveInstructionsToRewrite={(instructions) => setInteractiveToolsValues({ ...interactiveToolsValues, encounter: { ...interactiveToolsValues.encounter, rewrite: instructions }})}
    //           currentNote={editableFields.encounter}
    //           reWriteNote={handleRewriteNote}
    //           onAccept={(note) => handleTextareaChange(subjectRewriteNote, note)}
    //           />
    //       )}
    //     </div>
    //   </div>

    //   <div className={`w-full flex ${!isOpen.encounter && "hidden"} justify-between items-center`}>
    //     <button
    //         className={`flex items-center justify-center min-w-20 mt-2 bg-none text-gray-500  bg-white  hover:bg-gray-50 hover:text-gray-600 rounded-sm p-1 px-2  transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-300 font-medium`}
    //         onClick={(e) => {e.stopPropagation(); copyToClipboard(editableFields.encounter, "encounter");}}
    //       >
    //       <FaCopy className="mr-1" /> {copyDisplayText.encounter ? "Copied!" : "Copy"}
    //     </button>
    //     <button
    //       className={`flex items-center justify-center mt-2 min-w-20 bg-none ${isInteractiveToolsOpen.encounter ? "text-white bg-blue-500 hover:bg-gray-700" : "text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500"} rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text`}
    //       onClick={isInteractiveToolsOpen.encounter ? (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, encounter: false }); setInteractiveToolSelected({ ...interactiveToolSelected, encounter: "ask" })}  : (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, encounter: true }); setInteractiveToolSelected({ ...interactiveToolSelected, encounter: "ask" })}}
    //       >
    //         <FaMagic className='mr-1' /> AI
    //     </button>
    //   </div>

    // </div>

    // {/* Medications Section */}
    // <div className={`w-full transform transition-all relative bg-white px-5 py-4 ${!visibleFields["medications"] && "hidden"} ${isOpen.medications ? 'shadow-xl my-3 border-2 border-gray-500' : 'border border-gray-300 border-t-gray-100 hover:bg-gray-100'} cursor-pointer fade-in-text`} onClick={isOpen.medications ? null : () => toggleSection('medications')}>
      
    //   <div onClick={(e)=> {e.stopPropagation(); toggleSection("medications")}} className='flex justify-between items-center'>
    //     <div className='flex items-center'>
    //       {editableFields.medications_finalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNote("medications", patientData.id, editableFields.medications_finalized)}} className="text-blue-500 mr-2 text-lg" /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNote("medications", patientData.id, editableFields.medications_finalized)}} className="text-gray-600 hover:text-blue-500 mr-2 text-lg transition duration-150 ease-in-out" />}
    //       <p className={`text-lg ${isModified.medications && "text-red-800"} tracking-wide`}>Medications {isModified.medications && <span className='text-xs text-gray-300'>(editing)</span>}</p>
    //     </div>
    //     <div className='flex items-center'>
    //       {/* Add Save Button if isModified.subjective is true */}
    //       {isOpen.subjective && 
    //         <button
    //           className='flex items-center justify-center min-w-20 bg-none text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500 rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text'
    //           onClick={(e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, subjective: true }); setInteractiveToolSelected({ ...interactiveToolSelected, subjective: "ask" })}}
    //           >
    //             <FaMagic className='mr-1' /> AI
    //           </button>
    //       }
    //       <div className='flex items-center bg-gray-50 hover:bg-gray-300 text-gray-500 hover:text-gray-500 py-1 px-3 text-sm'>
    //         <p className='mr-2'>{isOpen.subjective ? "Collapse" : "Expand"}</p>
    //         {isOpen.subjective ? <AiOutlineShrink className='text-lg text-gray-500' /> : <AiOutlineExpandAlt className='text-lg text-gray-500' />  }
    //       </div>
    //     </div>

    //   </div>

    //   <div className={`flex items-end w-full relative accordion-content ${isOpen.medications ? 'open mt-3' : ''}`}>
    //     <div onClick={(e) => e.stopPropagation()} className={`flex flex-col transition-all duration-500 ease-in-out ${isInteractiveToolsOpen.medications ? "pr-2 w-9/12" : 'w-full'}`}>
    //       <div className="flex justify-between items-center">
    //           <div className='flex items-center'>
    //             <button
    //               className={`flex items-center justify-center min-w-36 ${tabSelectedForSection.medications === "new" ? "bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600 border-l border-r border-t border-blue-600 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"}  p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, medications: "new" })}}
    //             >
    //               {isModified.medications ? "With Changes" : "Updated"}
    //             </button>
    //             {tabSelectedForSection.medications === "new"  &&
    //               (<>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.medications ? "text-green-500 hover:bg-green-50 hover:text-green-600" : "text-gray-300"}   bg-white rounded-sm p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); savePatientInfo('medications')}}
    //                   disabled={!isModified.medications}
    //                 >
    //                   <FaSave className='mr-1' /> Save
    //                 </button>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.medications ? "text-red-500  bg-white  hover:bg-red-50 hover:text-red-600" : "text-gray-300"} rounded-sm p-1 px-2 mr-5 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); revertChanges('medications')}}
    //                   disabled={!isModified.medications}
    //                 >
    //                   <FaUndo className='mr-1' /> Undo
    //                 </button>
    //             </>)}
    //           </div>
    //           <div className='flex items-center justify-end'>
    //             <button
    //               className={`${isModified.medications ? "flex" : "hidden"} items-center justify-center min-w-36 ${tabSelectedForSection.medications === "previous" ? "bg-gray-300 text-gray-500 hover:bg-gray-200 hover:text-gray-600 border-l border-r border-t border-gray-700 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"} p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm text-center fade-in-header`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, medications: "previous" })}}
    //             >
    //               Original
    //             </button>
    //           </div>
    //       </div>
    //       {tabSelectedForSection.medications === "new" ? 
    //         (<>
    //           <textarea
    //             rows={9}
    //             autoFocus
    //             placeholder='Speak or type your notes here...'
    //             value={editableFields.medications}
    //             onChange={(e) => handleTextareaChange('medications', e.target.value)}
    //             className={`p-2 sm:p-4 sm:pr-10 outline-none transition-width duration-500 ease-in-out w-full ${isModified.medications ? "border border-blue-600 bg-gradient-to-bl from-white to-blue-50 text-gray-600" : "border border-blue-600 bg-white text-gray-600"} `}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>) 
    //           : 
    //         (<>
    //           <textarea
    //             rows={9}
    //             placeholder='Speak or type your notes here...'
    //             value={previousFields.medications}
    //             disabled
    //             className={`p-2 sm:p-4 sm:pr-20 outline-none transition-width duration-500 ease-in-out w-full border-l-[1px] border-b-[1px] border-cool-gray-200 bg-white}`}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>)}
    //     </div>
    //     <div onClick={(e) => e.stopPropagation()} className={`${isInteractiveToolsOpen.medications ? "w-1/4 flex flex-col items-center justify-center border" : "hidden"} rounded transform transition-all duration-200 ease-in-out ${(isInteractiveToolsOpen.medications)  ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'} cursor-default`}>
    //       {interactiveToolSelected.medications && (
    //         <Tools 
    //           interactiveToolSelected={interactiveToolSelected.medications} 
    //           toggleInteractiveToolSelected={toggleInteractiveToolSelected}
    //           interactiveToolOpen={"medications"}
    //           clearSelectedTools={() => setInteractiveToolSelected({ ...interactiveToolSelected, medications: null})}
    //           saveQuestionToAsk={(question) => setInteractiveToolsValues({ ...interactiveToolsValues, medications: { ...interactiveToolsValues.medications, ask: question }})}
    //           askQuestion={handleAskAi}
    //           saveInstructionsToRewrite={(instructions) => setInteractiveToolsValues({ ...interactiveToolsValues, medications: { ...interactiveToolsValues.medications, rewrite: instructions }})}
    //           currentNote={editableFields.medications}
    //           reWriteNote={handleRewriteNote}
    //           onAccept={(note) => handleTextareaChange(subjectRewriteNote, note)}
    //           />
    //       )}
    //     </div>
    //   </div>

    //   <div className={`w-full flex ${!isOpen.medications && "hidden"} justify-between items-center`}>
    //     <button
    //         className={`flex items-center justify-center min-w-20 mt-2 bg-none text-gray-500  bg-white  hover:bg-gray-50 hover:text-gray-600 rounded-sm p-1 px-2  transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-300 font-medium`}
    //         onClick={(e) => {e.stopPropagation(); copyToClipboard(editableFields.medications, "medications");}}
    //       >
    //       <FaCopy className="mr-1" /> {copyDisplayText.medications ? "Copied!" : "Copy"}
    //     </button>
    //     <button
    //       className={`flex items-center justify-center mt-2 min-w-20 bg-none ${isInteractiveToolsOpen.medications ? "text-white bg-blue-500 hover:bg-gray-700" : "text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500"} rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text`}
    //       onClick={isInteractiveToolsOpen.medications ? (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, medications: false }); setInteractiveToolSelected({ ...interactiveToolSelected, medications: "ask" })}  : (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, medications: true }); setInteractiveToolSelected({ ...interactiveToolSelected, medications: "ask" })}}
    //       >
    //         <FaMagic className='mr-1' /> AI
    //     </button>
    //   </div>

    // </div>

    // {/* Labs Section */}
    // <div className={`w-full transform transition-all relative bg-white px-5 py-4 ${!visibleFields["labs"] && "hidden"} ${isOpen.labs ? 'shadow-xl my-3 border-2 border-gray-500' : 'border border-gray-300 border-t-gray-100 hover:bg-gray-100'} cursor-pointer fade-in-text`} onClick={isOpen.labs ? null : () => toggleSection('labs')}>
      
    //   <div onClick={(e)=> {e.stopPropagation(); toggleSection("labs")}} className='flex justify-between items-center'>
    //     <div className='flex items-center'>
    //       {editableFields.labs_finalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNote("labs", patientData.id, editableFields.labs_finalized)}} className="text-blue-500 mr-2 text-lg" /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNote("labs", patientData.id, editableFields.labs_finalized)}} className="text-gray-600 hover:text-blue-500 mr-2 text-lg transition duration-150 ease-in-out" />}
    //       <p className={`text-lg ${isModified.labs && "text-red-800"} tracking-wide`}>Labs {isModified.labs && <span className='text-xs text-gray-300'>(editing)</span>}</p>
    //     </div>
    //     <div className='flex items-center'>
    //       {/* Add Save Button if isModified.subjective is true */}
    //       {isOpen.subjective && 
    //         <button
    //           className='flex items-center justify-center min-w-20 bg-none text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500 rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text'
    //           onClick={(e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, subjective: true }); setInteractiveToolSelected({ ...interactiveToolSelected, subjective: "ask" })}}
    //           >
    //             <FaMagic className='mr-1' /> AI
    //           </button>
    //       }
    //       <div className='flex items-center bg-gray-50 hover:bg-gray-300 text-gray-500 hover:text-gray-500 py-1 px-3 text-sm'>
    //         <p className='mr-2'>{isOpen.subjective ? "Collapse" : "Expand"}</p>
    //         {isOpen.subjective ? <AiOutlineShrink className='text-lg text-gray-500' /> : <AiOutlineExpandAlt className='text-lg text-gray-500' />  }
    //       </div>
    //     </div>
    //   </div>

    //   <div className={`flex items-end w-full relative accordion-content ${isOpen.labs ? 'open mt-3' : ''}`}>
    //     <div onClick={(e) => e.stopPropagation()} className={`flex flex-col transition-all duration-500 ease-in-out ${isInteractiveToolsOpen.labs ? "pr-2 w-9/12" : 'w-full'}`}>
    //       <div className="flex justify-between items-center">
    //           <div className='flex items-center'>
    //             <button
    //               className={`flex items-center justify-center min-w-36 ${tabSelectedForSection.labs === "new" ? "bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600 border-l border-r border-t border-blue-600 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"}  p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, labs: "new" })}}
    //             >
    //               {isModified.labs ? "With Changes" : "Updated"}
    //             </button>
    //             {tabSelectedForSection.labs === "new"  &&
    //               (<>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.labs ? "text-green-500 hover:bg-green-50 hover:text-green-600" : "text-gray-300"}   bg-white rounded-sm p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); savePatientInfo('labs')}}
    //                   disabled={!isModified.labs}
    //                 >
    //                   <FaSave className='mr-1' /> Save
    //                 </button>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.labs ? "text-red-500  bg-white  hover:bg-red-50 hover:text-red-600" : "text-gray-300"} rounded-sm p-1 px-2 mr-5 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); revertChanges('labs')}}
    //                   disabled={!isModified.labs}
    //                 >
    //                   <FaUndo className='mr-1' /> Undo
    //                 </button>
    //             </>)}
    //           </div>
    //           <div className='flex items-center justify-end'>
    //             <button
    //               className={`${isModified.labs ? "flex" : "hidden"} items-center justify-center min-w-36 ${tabSelectedForSection.labs === "previous" ? "bg-gray-300 text-gray-500 hover:bg-gray-200 hover:text-gray-600 border-l border-r border-t border-gray-700 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"} p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm text-center fade-in-header`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, labs: "previous" })}}
    //             >
    //               Original
    //             </button>
    //           </div>
    //       </div>
    //       {tabSelectedForSection.labs === "new" ? 
    //         (<>
    //           <textarea
    //             rows={9}
    //             autoFocus
    //             placeholder='Speak or type your notes here...'
    //             value={editableFields.labs}
    //             onChange={(e) => handleTextareaChange('labs', e.target.value)}
    //             className={`p-2 sm:p-4 sm:pr-10 outline-none transition-width duration-500 ease-in-out w-full ${isModified.labs ? "border border-blue-600 bg-gradient-to-bl from-white to-blue-50 text-gray-600" : "border border-blue-600 bg-white text-gray-600"} `}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>) 
    //           : 
    //         (<>
    //           <textarea
    //             rows={9}
    //             placeholder='Speak or type your notes here...'
    //             value={previousFields.labs}
    //             disabled
    //             className={`p-2 sm:p-4 sm:pr-20 outline-none transition-width duration-500 ease-in-out w-full border-l-[1px] border-b-[1px] border-cool-gray-200 bg-white}`}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>)}
    //     </div>
    //     <div onClick={(e) => e.stopPropagation()} className={`${isInteractiveToolsOpen.labs ? "w-1/4 flex flex-col items-center justify-center border" : "hidden"} rounded transform transition-all duration-200 ease-in-out ${(isInteractiveToolsOpen.labs)  ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'} cursor-default`}>
    //       {interactiveToolSelected.labs && (
    //         <Tools 
    //           interactiveToolSelected={interactiveToolSelected.labs} 
    //           toggleInteractiveToolSelected={toggleInteractiveToolSelected}
    //           interactiveToolOpen={"labs"}
    //           clearSelectedTools={() => setInteractiveToolSelected({ ...interactiveToolSelected, labs: null})}
    //           saveQuestionToAsk={(question) => setInteractiveToolsValues({ ...interactiveToolsValues, labs: { ...interactiveToolsValues.labs, ask: question }})}
    //           askQuestion={handleAskAi}
    //           saveInstructionsToRewrite={(instructions) => setInteractiveToolsValues({ ...interactiveToolsValues, labs: { ...interactiveToolsValues.labs, rewrite: instructions }})}
    //           currentNote={editableFields.labs}
    //           reWriteNote={handleRewriteNote}
    //           onAccept={(note) => handleTextareaChange(subjectRewriteNote, note)}
    //           />
    //       )}
    //     </div>
    //   </div>

    //   <div className={`w-full flex ${!isOpen.labs && "hidden"} justify-between items-center`}>
    //     <button
    //         className={`flex items-center justify-center min-w-20 mt-2 bg-none text-gray-500  bg-white  hover:bg-gray-50 hover:text-gray-600 rounded-sm p-1 px-2  transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-300 font-medium`}
    //         onClick={(e) => {e.stopPropagation(); copyToClipboard(editableFields.labs, "labs");}}
    //       >
    //       <FaCopy className="mr-1" /> {copyDisplayText.labs ? "Copied!" : "Copy"}
    //     </button>
    //     <button
    //       className={`flex items-center justify-center mt-2 min-w-20 bg-none ${isInteractiveToolsOpen.labs ? "text-white bg-blue-500 hover:bg-gray-700" : "text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500"} rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text`}
    //       onClick={isInteractiveToolsOpen.labs ? (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, labs: false }); setInteractiveToolSelected({ ...interactiveToolSelected, labs: "ask" })}  : (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, labs: true }); setInteractiveToolSelected({ ...interactiveToolSelected, labs: "ask" })}}
    //       >
    //         <FaMagic className='mr-1' /> AI
    //     </button>
    //   </div>

    // </div>

    // {/* Referrals Section */}
    // <div className={`w-full transform transition-all relative bg-white px-5 py-4 ${!visibleFields["referrals"] && "hidden"} ${isOpen.referrals ? 'shadow-xl my-3 border-2 border-gray-500' : 'border border-gray-300 border-t-gray-100 hover:bg-gray-100'} cursor-pointer fade-in-text`} onClick={isOpen.referrals ? null : () => toggleSection('referrals')}>
      
    //   <div onClick={(e)=> {e.stopPropagation(); toggleSection("referrals")}} className='flex justify-between items-center'>
    //     <div className='flex items-center'>
    //       {editableFields.referrals_finalized ? <FaCheckSquare onClick={(e) => {e.stopPropagation(); finalizeNote("referrals", patientData.id, editableFields.referrals_finalized)}} className="text-blue-500 mr-2 text-lg" /> : <FaRegSquare onClick={(e) => {e.stopPropagation(); finalizeNote("referrals", patientData.id, editableFields.referrals_finalized)}} className="text-gray-600 hover:text-blue-500 mr-2 text-lg transition duration-150 ease-in-out" />}
    //       <p className={`text-lg ${isModified.referrals && "text-red-800"} tracking-wide`}>Referrals {isModified.referrals && <span className='text-xs text-gray-300'>(editing)</span>}</p>
    //     </div>
    //     <div className='flex items-center'>
    //       {/* Add Save Button if isModified.subjective is true */}
    //       {isOpen.subjective && 
    //         <button
    //           className='flex items-center justify-center min-w-20 bg-none text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500 rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text'
    //           onClick={(e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, subjective: true }); setInteractiveToolSelected({ ...interactiveToolSelected, subjective: "ask" })}}
    //           >
    //             <FaMagic className='mr-1' /> AI
    //           </button>
    //       }
    //       <div className='flex items-center bg-gray-50 hover:bg-gray-300 text-gray-500 hover:text-gray-500 py-1 px-3 text-sm'>
    //         <p className='mr-2'>{isOpen.subjective ? "Collapse" : "Expand"}</p>
    //         {isOpen.subjective ? <AiOutlineShrink className='text-lg text-gray-500' /> : <AiOutlineExpandAlt className='text-lg text-gray-500' />  }
    //       </div>
    //     </div>
    //   </div>

    //   <div className={`flex items-end w-full relative accordion-content ${isOpen.referrals ? 'open mt-3' : ''}`}>
    //     <div onClick={(e) => e.stopPropagation()} className={`flex flex-col transition-all duration-500 ease-in-out ${isInteractiveToolsOpen.referrals ? "pr-2 w-9/12" : 'w-full'}`}>
    //       <div className="flex justify-between items-center">
    //           <div className='flex items-center'>
    //             <button
    //               className={`flex items-center justify-center min-w-36 ${tabSelectedForSection.referrals === "new" ? "bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600 border-l border-r border-t border-blue-600 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"}  p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, referrals: "new" })}}
    //             >
    //               {isModified.referrals ? "With Changes" : "Updated"}
    //             </button>
    //             {tabSelectedForSection.referrals === "new"  &&
    //               (<>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.referrals ? "text-green-500 hover:bg-green-50 hover:text-green-600" : "text-gray-300"}   bg-white rounded-sm p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); savePatientInfo('referrals')}}
    //                   disabled={!isModified.referrals}
    //                 >
    //                   <FaSave className='mr-1' /> Save
    //                 </button>
    //                 <button
    //                   className={`flex items-center justify-center min-w-20 bg-none ${isModified.referrals ? "text-red-500  bg-white  hover:bg-red-50 hover:text-red-600" : "text-gray-300"} rounded-sm p-1 px-2 mr-5 transition duration-150 ease-in-out focus:outline-none text-sm border border-gray-100 font-medium fade-in-text`}
    //                   onClick={(e) => {e.stopPropagation(); revertChanges('referrals')}}
    //                   disabled={!isModified.referrals}
    //                 >
    //                   <FaUndo className='mr-1' /> Undo
    //                 </button>
    //             </>)}
    //           </div>
    //           <div className='flex items-center justify-end'>
    //             <button
    //               className={`${isModified.referrals ? "flex" : "hidden"} items-center justify-center min-w-36 ${tabSelectedForSection.referrals === "previous" ? "bg-gray-300 text-gray-500 hover:bg-gray-200 hover:text-gray-600 border-l border-r border-t border-gray-700 " : "bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-600"} p-1 px-2 transition duration-150 ease-in-out focus:outline-none text-sm text-center fade-in-header`}
    //               onClick={(e) => {e.stopPropagation(); setTabSelectedForSection({ ...tabSelectedForSection, referrals: "previous" })}}
    //             >
    //               Original
    //             </button>
    //           </div>
    //       </div>
    //       {tabSelectedForSection.referrals === "new" ? 
    //         (<>
    //           <textarea
    //             rows={9}
    //             autoFocus
    //             placeholder='Speak or type your notes here...'
    //             value={editableFields.referrals}
    //             onChange={(e) => handleTextareaChange('referrals', e.target.value)}
    //             className={`p-2 sm:p-4 sm:pr-10 outline-none transition-width duration-500 ease-in-out w-full ${isModified.referrals ? "border border-blue-600 bg-gradient-to-bl from-white to-blue-50 text-gray-600" : "border border-blue-600 bg-white text-gray-600"} `}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>) 
    //           : 
    //         (<>
    //           <textarea
    //             rows={9}
    //             placeholder='Speak or type your notes here...'
    //             value={previousFields.referrals}
    //             disabled
    //             className={`p-2 sm:p-4 sm:pr-20 outline-none transition-width duration-500 ease-in-out w-full border-l-[1px] border-b-[1px] border-cool-gray-200 bg-white}`}
    //             onClick={(e) => e.stopPropagation()}
    //           ></textarea>
    //         </>)}
    //     </div>
    //     <div onClick={(e) => e.stopPropagation()} className={`${isInteractiveToolsOpen.referrals ? "w-1/4 flex flex-col items-center justify-center border" : "hidden"} rounded transform transition-all duration-200 ease-in-out ${(isInteractiveToolsOpen.referrals)  ? 'translate-x-0 opacity-100' : 'translate-x-[200%] opacity-0'} cursor-default`}>
    //       {interactiveToolSelected.referrals && (
    //         <Tools 
    //           interactiveToolSelected={interactiveToolSelected.referrals} 
    //           toggleInteractiveToolSelected={toggleInteractiveToolSelected}
    //           interactiveToolOpen={"referrals"}
    //           clearSelectedTools={() => setInteractiveToolSelected({ ...interactiveToolSelected, referrals: null})}
    //           saveQuestionToAsk={(question) => setInteractiveToolsValues({ ...interactiveToolsValues, referrals: { ...interactiveToolsValues.referrals, ask: question }})}
    //           askQuestion={handleAskAi}
    //           saveInstructionsToRewrite={(instructions) => setInteractiveToolsValues({ ...interactiveToolsValues, referrals: { ...interactiveToolsValues.referrals, rewrite: instructions }})}
    //           currentNote={editableFields.referrals}
    //           reWriteNote={handleRewriteNote}
    //           onAccept={(note) => handleTextareaChange(subjectRewriteNote, note)}
    //           />
    //       )}
    //     </div>
    //   </div>

    //   <div className={`w-full flex ${!isOpen.referrals && "hidden"} justify-between items-center`}>
    //     <button
    //         className={`flex items-center justify-center min-w-20 mt-2 bg-none text-gray-500  bg-white  hover:bg-gray-50 hover:text-gray-600 rounded-sm p-1 px-2  transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-300 font-medium`}
    //         onClick={(e) => {e.stopPropagation(); copyToClipboard(editableFields.referrals, "referrals");}}
    //       >
    //       <FaCopy className="mr-1" /> {copyDisplayText.referrals ? "Copied!" : "Copy"}
    //     </button>
    //     <button
    //       className={`flex items-center justify-center mt-2 min-w-20 bg-none ${isInteractiveToolsOpen.referrals ? "text-white bg-blue-500 hover:bg-gray-700" : "text-blue-500 bg-white hover:bg-blue-50 hover:text-blue-500"} rounded-sm p-1 px-2 mr-2 transition duration-150 ease-in-out focus:outline-none text-xs border border-gray-100 font-medium fade-in-text`}
    //       onClick={isInteractiveToolsOpen.referrals ? (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, referrals: false }); setInteractiveToolSelected({ ...interactiveToolSelected, referrals: "ask" })}  : (e) => {e.stopPropagation(); setIsInteractiveToolsOpen({ ...isInteractiveToolsOpen, referrals: true }); setInteractiveToolSelected({ ...interactiveToolSelected, referrals: "ask" })}}
    //       >
    //         <FaMagic className='mr-1' /> AI
    //     </button>
    //   </div>

    // </div>

        {/* Header */}
    {/* <div className={`flex flex-col md:flex-row justify-between items-center w-full transition duration-300 ease-in-out z-10 border border-blue-500 ${patientData.note_finalized ? "bg-white" : "bg-white"} p-6 mb-6 fade-in-text`}>
      <div className="flex items-center mb-4 md:mb-0">
        <div className="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-full mr-4">
          <FaUserShield size={24} />
        </div>
        <div>
          <h2 className="text-xl md:text-2xl font-bold text-blue-900 mb-1 flex items-center">
            {patientData.first_name} {patientData.last_name}
            {patientData.note_finalized && <span><FaCheckCircle className="text-green-500 text-lg ml-2" /></span>}
          </h2>
          <div className="flex items-center text-sm md:text-base text-gray-600">
            <p className="mr-4">
              {patientData.conversation_title ? patientData.conversation_title : "No Title Found"}
            </p>
            <span className="mx-2">|</span>
            <div className="flex items-center">
              <FaCalendarAlt className="text-blue-600 mr-2" />
              <p>
                <strong>Visit Date:</strong>{" "}
                {new Date(patientData.recording_date).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="flex items-center justify-center w-10 h-10 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-300"
        >
          <FaEllipsisH size={18} />
        </button>
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
            <a
              href="#"
              onClick={() => {generatePDF(); toggleDropdown();}}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              Download PDF
            </a>
            <a
              href="#"
              onClick={() => {toggleDeleteModal(); toggleDropdown();}}
              className="block px-4 py-2 text-sm text-red-600 hover:bg-red-100 hover:text-red-800"
            >
              Delete Patient
            </a>
          </div>
        )}
      </div>
    </div> */}

    {/* Section Visibility */}
    {/* <div className="w-full flex justify-end items-center mb-5 relative">
      {visibleFieldsPanelOpen ? (
        <div className="bg-white p-2 flex flex-wrap justify-end items-center border border-gray-200 fade-in-text">
          {Object.keys(visibleFields).map((field) => (
            <div key={field} className="flex items-center mb-2 md:mb-0 md:mr-6 cursor-pointer" onClick={() => toggleField(field)}>
              <input
                type="radio"
                className="cursor-pointer form-radio h-3 w-3 text-blue-600 transition duration-150 ease-in-out"
                checked={visibleFields[field]}
                onClick={() => toggleField(field)}
              />
              <label
                className={`ml-1 text-xs font-medium capitalize cursor-pointer ${
                  visibleFields[field] ? 'text-blue-600' : 'text-gray-700'
                } transition duration-150 ease-in-out`}
                onClick={() => toggleField(field)}
              >
                {field.replace('assessment_and_plan', 'Assessment & Plan')}
              </label>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
      <button
        onClick={() => setVisibleFieldsPanelOpen(!visibleFieldsPanelOpen)}
        className={`flex items-center bg-blue-500 hover:bg-blue-600 text-white text-xs font-semibold py-2 px-4 shadow-md transition duration-150 ease-in-out ${
          visibleFieldsPanelOpen ? 'bg-blue-600' : 'bg-blue-500'
        }`}
      >
        {visibleFieldsPanelOpen ? (
          <>
            <FaEyeSlash className="mr-2" />
            Fields
          </>
        ) : (
          <>
            <FaEye className="mr-2" />
            Fields
          </>
        )}
      </button>
    </div> */}
