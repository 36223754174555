import React from 'react';
import { Box, Typography, Button, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit as EditIcon } from '@mui/icons-material';

const ProfileBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
}));

const ProfileInfo = ({ userInfo }) => {
  return (
    <ProfileBox>
      <LargeAvatar alt={userInfo.first_name} src="/static/images/avatar/1.jpg" />
      <Typography variant="h5" gutterBottom>{`${userInfo.first_name} ${userInfo.last_name}`}</Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>{userInfo.phone_number}</Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<EditIcon />}
        sx={{ mt: 2 }}
      >
        Edit Profile
      </Button>
    </ProfileBox>
  );
};

export default ProfileInfo;
