import { useState } from 'react';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css'; // Ensure this imports the Tailwind CSS file

import Header from './Header/Header';
import HomePage from './HomePage/HomePage';
import EntryPage from './EntryPage/EntryPage';
import ScribePage from './ScribePage/ScribePage';

function App() {

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<EntryPage />} />
        <Route path="/scribe" element={<ScribePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
