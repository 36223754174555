import React, { useEffect } from 'react';

function ConversationModal({ isOpen, onClose, text }) {
  // Function to parse the conversation text and return elements
  const parseConversation = (textData) => {
    // Assuming textData is an array with a single string element
    if (!Array.isArray(textData) || textData.length === 0 || typeof textData[0] !== 'string') {
      console.error('Expected an array with a single string for textData but received:', textData);
      return []; // Return an empty array to prevent further errors
    }
  
    const text = textData[0]; // Access the first element of the array
    const dialogues = text.split('[').slice(1); // Proceed as before
    return dialogues.map((dialogue, index) => {
      const [speaker, message] = dialogue.split('] : ');
      const isProvider = speaker.trim().toUpperCase() === 'DOCTOR';
      const bgColor = isProvider ? 'bg-blue-100' : 'bg-green-100';
      const alignmentClass = isProvider ? 'justify-end' : 'justify-start';
  
      return (
        <div key={index} className={`flex ${alignmentClass} w-full mb-2`}>
          <div className={`flex flex-col ${bgColor} p-2 rounded-lg shadow`}>
            <span className="font-bold">{isProvider ? 'Doctor' : 'Patient'}:</span>
            <p className="text-sm">{message.trim()}</p>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    console.log('ConversationModal mounted');
    console.log('Conversation text:', text);
    parseConversation(text);
  }, [text]);

  if (!isOpen) return null;
  

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
        <h2 className="text-xl font-semibold mb-2">Conversation Transcript</h2>
        <div className="overflow-y-auto max-h-96 space-y-4 pr-3">
          {parseConversation(text)}
        </div>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default ConversationModal;


